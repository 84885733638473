/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

div.text-area {
  position: relative;
  font-weight: 500;
  border-radius: 2px;
  padding: 0;

  label {
    position: absolute;
    top: -0.8em;
    left: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0.5rem;

    font-size: 0.75rem;
    line-height: 1rem;
    color: black;
    background: $input-bg-light;
  }

  textarea {
    font-family: $font-family !important;
    overflow-y: auto;
    resize: none;
    padding: 1rem 0.625rem 0.5rem 0.625rem;
    height: 100%;
    width: 100%;
    text-align: start;
    border: 1px solid black;
    border-radius: 2px;
    outline: none;
    background-color: $input-bg-light;

    font-size: 1rem;
    line-height: 1.25rem;

    &::placeholder {
      color: $colour-grey-3;
    }
    &:hover {
      border-color: $colour-grey-3;
    }
    &:active,
    &:focus {
      border-color: #636464;
      box-shadow: 0px 0px 8px rgba(111, 87, 229, 0.5);
    }
    &.touched:invalid {
      border-color: $colour-negative-status-a;

      & + label {
        color: $colour-negative-status-a;
      }
    }
    &:disabled {
      background-color: $colour-grey-5;
      border-color: $colour-grey-5;
      color: $colour-grey-3;

      & + label {
        color: $colour-grey-3;
        background-color: transparent;
      }
    }
  }

  &.dark {
    border: 1px solid $colour-grey-5;
    background: $input-bg-dark;

    label {
      color: $colour-white;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    textarea {
      background: $input-bg-dark;
      color: $colour-white;
    }
  }
}
