/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules';

.mission-integrations {
  &.shadow {
    .content {
      padding: 0;
    }
  }

  .blocks {
    & > * + * {
      margin-top: 0;
      margin-left: 0;
    }
  }

  .blocks.gap {
    display: flex;
    flex-direction: column;
    & > * + * {
      margin-top: 10px;
    }
    padding: 20px 0;

    &.pad {
      margin-left: -20px;
      margin-right: -20px;
    }
  }

  .item-container {
    margin-left: -12px;
    padding: 12px;
    & > * + * {
      margin-top: 20px !important;
    }
  }

  .item-container.shadow {
    margin: 0 20px;
    padding: 0 10px;
    border-radius: 8px;
    background-color: $colour-white;
    height: 60px;
    filter: drop-shadow(0px 4px 12px rgba(0, 84, 101, 0.24));

    .check {
      width: 32px;
    }
  }

  .heading {
    //margin-bottom: 23px;
  }

  .blocks.integration-list {
    display: flex;
    flex-direction: column;
    & > * + * {
      margin-top: 0px;
    }
    &.pad {
      margin-left: -24px;
      margin-right: -24px;
      padding: 0px;
    }

    .item-container {
      margin: 0px;
      padding: 0 20px;
      border-radius: 0px;
      background-color: $colour-white;
      border: 1px solid #d0d4e7;
      height: 60px;

      .check {
        width: 32px;
      }
    }

    .item-container {
      & > * + * {
        margin-top: 0px !important;
      }
    }
  }

  .integration-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 16px;
    line-height: 36px;
    background: $colour-grey-5;
    color: $colour-grey-1;
    border: 1px solid #d0d4e7;
    svg {
      margin-right: 10px;
    }
  }
  .integration-name {
    font-weight: 500;
    font-size: 28px;
    line-height: 32px;
    letter-spacing: -0.04em;
  }
  .integration-options {
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    letter-spacing: -0.04em;
    margin-top: 52px;
  }

  .margin-r10 {
    margin-right: 10px;
  }

  .menu-button button {
    color: $colour-grey-1;
  }
  .heading-container {
    position: relative;
    margin-top: 30px;
    .img {
      position: absolute;
      top: 0px;
    }
    .v-center {
      justify-content: right;
      .text {
        font-size: 12px;
        font-weight: 600;
        margin-right: 10px;
      }
    }
  }
}

.dark-mode {
  .item-container {
    background-color: transparent !important;
    border: none !important;
    border-bottom: 1px solid #d0d4e7 !important;
    &:last-child {
      border: none !important;
    }
  }
  .integration-title {
    background: rgba(11, 23, 26, 0.8);
    color: $colour-white;
    border: none;
  }
}
