/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

.battery {
  width: 96px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  & > * + * {
    margin-left: 10px;
  }

  align-items: center;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1.25rem;
  }
}
