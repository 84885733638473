/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty 
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any 
 * licence may have from the copyright owner to use the Software, you must not copy, 
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to 
 * derive the source code of or modify the Software, nor create any derivative works of 
 * the Software.
 */

@import '../../modules';

$eq-height: 31px;

.audio-player {
  background: $colour-white;
  border-radius: 6px;

  margin-top: 15px;

  border: solid 1px $colour-grey-4;

  &.floating {
    transition: right 0.5s ease-in-out;

    position: fixed;
    right: $map-padding;
    bottom: $map-padding;
    margin-top: 0;
    filter: drop-shadow(0px 4px 12px rgba(0, 84, 101, 0.24));
    border: none;
  }

  padding: 11px 15px 11px 0;

  .toolbar {
    padding: 0 0 0 15px;

    font-style: normal;
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: $colour-grey-2;

    display: flex;
    & > * + * {
      margin-left: 10px;
    }

    justify-content: space-between;

    .left {
      display: flex;
      & > * + * {
        margin-left: 15px;
      }
    }

    .right {
      .dot-button {
        background-color: transparent;
        border: none;
      }
    }
  }

  .controls {
    display: flex;

    .dot-button {
      border: none;
      background-color: transparent;
    }

    .equalizer {
      position: relative;
      width: 200px;
      height: $eq-height * 2;
      display: flex;
      justify-content: center;
      align-items: center;

      .horizontal {
        width: 100%;
        border: 1px solid $colour-interactions-b;
        background: $colour-interactions-b;
      }

      .vertical-lines {
        position: absolute;
        width: 100%;
        height: $eq-height * 2;
        display: flex;
        align-items: center;
      }

      .vertical {
        width: 5px;
        height: 0;
        border-radius: 5px;
        background-color: $colour-interactions-a;

        animation-direction: alternate-reverse;
        margin: 1px;
      }

      @keyframes equalizer {
        from {
          height: $eq-height;
        }

        to {
          height: 5px;
        }
      }
    }
  }
}

.is-map {
  .audio-player {
    &.floating {
      right: 120px;
    }
  }
}
.sidebar-open {
  .audio-player {
    &.floating {
      right: calc(var(--sidebar-width) + 30px);
    }
  }
  .is-map {
    .audio-player {
      &.floating {
        right: calc(var(--sidebar-width) + 120px);
      }
    }
  }
}
