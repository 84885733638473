/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

div.search {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 3.5rem;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid $colour-grey-2;
  position: relative;

  input {
    font-family: $font-family !important;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    padding: 0.5rem 0.825rem;
    outline: none;
    color: $colour-white;
    font-weight: 550;

    &::placeholder {
      color: $colour-grey-3;
      font-weight: 550;
    }
  }

  svg {
    position: absolute;
    path {
      fill: $colour-grey-3 !important;
    }
    margin: 0 10px;
    right: 0;
  }

  &.light {
    border: solid 1px $colour-grey-1;

    input {
      font-family: $font-family !important;
      width: calc(100% - 40px);
      height: 80%;
      background-color: transparent;
      border: none;
      padding: 0.5rem 0.825rem;
      outline: none;
      color: $colour-grey-1;
      font-weight: 550;

      &::placeholder {
        color: $colour-grey-3;
      }
    }

    svg {
      path {
        fill: $colour-grey-3 !important;
      }
    }
  }
}
