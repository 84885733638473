/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules';

$mission-container-width: 18.75rem;

:root {
  --team-card-width: 25rem;
  --team-card-height: 25rem;
}

.assigned-teams {
  height: 100%;
  width: 100%;
  background-color: $colour-grey-1;

  .drag-empty {
    text-align: center;
    opacity: 0.8;

    span {
      width: 100%;
      display: block;

      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 24px;

      text-align: center;
      letter-spacing: -0.02em;

      color: $colour-grey-3;
      margin-top: 10px;
    }
  }

  .canvas {
    height: 100%;
    width: calc(100% - #{$mission-container-width});
    margin-left: $mission-container-width;
    cursor: grab;
    overflow: hidden;

    background-image: repeating-linear-gradient(
        rgba($colour-grey-8, 1/10) 0px 1px,
        transparent 1px 100%
      ),
      repeating-linear-gradient(
        90deg,
        rgba(#85898c, 1/10) 0px 1px,
        transparent 1px 100%
      );

    &.grabbing {
      cursor: grabbing;
    }

    .container {
      position: relative;
      -webkit-user-select: none;
      user-select: none;

      height: 100%;
      width: 100%;

      display: flex;
      justify-content: center;
      align-content: flex-start;
      flex-wrap: wrap;

      margin: 6rem;

      margin-top: 4rem;
      margin-left: -2rem;

      & > * {
        margin-left: 2rem;
        margin-top: 2rem;
      }
    }

    .team {
      width: var(--team-card-width);
      min-height: var(--team-card-height);
      color: white;
      flex-shrink: 0;
      flex-grow: 0;

      background: rgba(0, 0, 0, 0.3);
      border-radius: 24px;
      padding: 24px;

      & > * + * {
        margin-left: 20px;
      }
      display: flex;
      flex-direction: column;

      &.new {
        cursor: pointer;

        &:hover {
          background: rgba(255, 255, 255, 0.1);
        }
      }

      &.unassigned {
        background: transparent;
        border: dashed rgba(255, 255, 255, 0.3) 3px;
      }

      .heading {
        display: flex;
        & > * + * {
          margin-left: 24px;
        }
        justify-content: space-between;
        align-items: center;

        & > div {
          display: flex;
          align-items: center;
          & > * + * {
            margin-left: 24px;
          }
        }

        .dot-button {
          background-color: transparent;
          color: $colour-white;
          border: none;
          font-size: 1.5rem;
        }
      }

      & > .body {
        display: flex;
        flex-direction: row;

        margin-left: -1rem;

        & > * {
          margin-top: 1rem;
          margin-left: 1rem;
        }

        flex-wrap: wrap;

        &.new {
          height: 100%;
          font-size: 1.5em;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          & > * + * {
            margin-top: 24px;
          }
        }

        & > .assigned-user,
        & > .unit-panel-container,
        & > .clone {
          width: calc(50% - 5px);
        }
      }
    }

    & > .info {
      position: absolute;
      bottom: 30px;
      right: 30px;
      color: $colour-white;
      padding: 6px;
      text-align: left;
      width: 40px;
      font-weight: 600;
      cursor: pointer;
    }
  }

  $top-bar-height: 5rem;

  .mission {
    border: dashed rgba($colour-grey-3, 0.8) 3px;
    border-left: none;
    border-top: none;
    border-bottom: none;
    position: absolute;
    top: 0;
    left: 0;
    width: $mission-container-width;
    color: $colour-white;
    background-color: #191e1f;
    display: flex;
    flex-direction: column;
    & > * + * {
      margin-top: 20px;
    }

    $param-bar-width: 18.75rem;
    left: #{$param-bar-width + $nav-width};
    top: #{$top-bar-height};
    height: vh(100, -#{$top-bar-height});

    .heading {
      display: flex;
      flex-direction: column;
      & > * + * {
        margin-top: 1rem;
      }

      .search-bar {
        display: flex;
        flex-direction: column;
        padding: 20px 20px 0 20px;

        & > * + * {
          margin-top: 1rem;
        }
      }

      .menu-button {
        button {
          font-size: 1.125rem;
          text-decoration: none !important;
          width: 18.75rem;
          display: flex;
          justify-content: space-between;
        }
      }

      .search {
        height: 42px;
      }
    }

    .ScrollbarsCustom {
      margin: 20px 0;

      .ScrollbarsCustom-Scroller {
        padding: 0 20px;

        & > .ScrollbarsCustom-Content {
          & > .body {
            display: flex;
            flex-direction: column;
            & > * + * {
              margin-top: 10px;
            }
          }
        }
      }
    }

    & > .remove {
      -webkit-user-select: none;
      user-select: none;
      border: dashed rgba($colour-grey-3, 0.8) 3px;
      border-radius: 24px;
      padding: 24px;
      color: rgba($colour-grey-3, 0.8);
      margin: 0 20px 20px 20px;

      display: flex;
      & > * + * {
        margin-left: 10px;
      }
      align-items: center;

      &.highlight {
        border: dashed rgba($colour-white, 1) 3px;
        color: rgba($colour-white, 1);
      }
      &.dim {
        border: dashed rgba($colour-grey-3, 0.1) 3px;
        color: rgba($colour-grey-3, 0.1);
      }
    }
  }
}

.has-notification {
  .assigned-units {
    &-middle-row {
      height: vh(100, -12.5625rem-$banner-height) !important;
      .ScrollbarsCustom-Content {
        height: vh(100, -12.5625rem-$banner-height);
      }
    }
  }
}
