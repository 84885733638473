/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

.admin-bar {
  height: $admin-bar-height;
  border-bottom: solid 1px $colour-grey-2;
  width: 100%;

  grid-area: topbar;

  display: flex;
  justify-content: space-between;

  /* White */
  color: $colour-white;

  & > .title {
    padding: 24px 24px;

    font-style: normal;
    font-weight: 600;
    font-size: 2rem;
    line-height: 40px;
    letter-spacing: -0.03em;

    display: flex;
    align-items: center;
    & > * + * {
      margin-left: 1.5rem;
    }

    .dot-button {
      background-color: transparent;
      border: none;
      color: $colour-white;
    }
  }

  & > .profile {
    padding: 1.5rem;

    border-left: solid 1px $colour-grey-2;
    height: $admin-bar-height;
    width: 428px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    & > * + * {
      margin-left: 1rem;
    }

    & > .user {
      display: flex;
      & > * + * {
        margin-left: 1rem;
      }

      & > .details {
        & > .name {
          height: 24px;
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 1.25rem;
          letter-spacing: -0.02em;
          display: flex;
          align-items: center;
        }
        & > .role {
          display: block;
          height: 24px;
          max-width: calc(428px - 10.5rem);

          font-style: normal;
          font-weight: normal;
          font-size: 1rem;
          line-height: 1.25rem;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    & > .dot-button {
      font-size: 1.5rem;
      color: inherit;
    }
    .dropdown {
      position: absolute;
      right: 15px;
      top: 55px;
      display: flex;
      flex-direction: column;
      z-index: 5;
      border-radius: 5px;
      cursor: pointer;
      .item-container {
        padding: 15px;
        display: flex;
        flex-direction: row;
        background: #191e1f;
        color: white;
        border: none;
        .item-icon {
          padding-right: 10px;
        }
      }
    }
  }
  .confirm-modal {
    color: black;
  }
}

.mission-nav {
  .admin-bar {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-right: -20px;
  }
}
