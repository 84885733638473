/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules';

/// USER GRID DEFINITIONS //////////////////////////////////////////////////////////
.users {
  overflow-x: hidden;
  &-container {
    overflow-x: hidden;
    width: 100%;
    height: vh(100);

    display: grid;
    grid-template-columns: $nav-width 1fr;
    grid-template-areas: 'sidebar users-middle';

    min-width: 780px;
  }

  &-middle {
    grid-area: users-middle;
    display: grid;
    grid-template-rows: 6rem vh(100, -6rem);
    grid-template-areas: 'topbar' 'users-content';
    overflow-x: hidden;
  }
}

.dot-button.remove {
  width: 24px;
  height: 24px;
  font-size: 0.75rem;
  color: black;
  background-color: white;
  border-radius: 50%;
}

.has-notification {
  .users {
    &-container {
      grid-template-rows: vh(100, -#{$banner-height});
    }
    &-middle {
      overflow: hidden;
      grid-template-rows: 6rem calc(
          (var(--vh, 1vh) * 100) - #{$banner-height} - 6rem
        );
    }
  }
}

/// USER SIDEBAR CONTAINER //////////////////////////////////////////////////////////
.users-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/// USER CONTENT CONTAINER //////////////////////////////////////////////////////////

.users-content {
  overflow-y: hidden;
  grid-area: users-content;
  display: flex;

  .breadcrumb {
    font-size: 1rem;
    white-space: nowrap;

    ol,
    ul {
      align-items: flex-start;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      margin: 0;
      padding: 0;

      li {
        align-items: center;
        display: flex;
      }

      li + li::before {
        color: #b5b5b5;
        content: '\0002f';
      }
    }
    button {
      text-decoration: underline;
    }
    li:last-of-type {
      button {
        text-decoration: none;
      }
    }
  }
}

.users-content-col1 {
  width: 18.75rem;
  border-right: $dark-border 1px solid;

  .search {
    margin: 1.25rem 1.25rem 0.875rem 1.25rem;
  }

  &-title-add {
    margin-top: 1.25rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    &-title {
      color: $colour-white;
      font-size: 1.0625rem;
      margin-left: 1.25rem;
      font-weight: 500;
    }

    &-add {
      @extend .button-default;
      &.dot-button {
        color: $global-blue;
      }
      height: 1.5rem;
      width: 1.5rem;
      background-color: transparent;
      margin-left: auto;
      margin-right: 1.25rem;
    }
  }

  &-division-container {
    // display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 1.25rem;
    height: 80%;
    // Hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;

    :hover {
      .users-content-col1-division-container-div-arrow {
        color: $global-blue;
      }
    }
    &-separator {
      width: 100%;
      border-bottom: $dark-border 1px solid;
      margin-bottom: 10px;
    }

    &-load {
      width: 40px;
      height: 40px;
      margin: 100px auto;
      color: $global-blue;
    }

    &-subgroups {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;
      p {
        color: $colour-white;
        font-size: 0.875rem;
        margin-left: 13px;
        font-weight: 550;
      }
      button {
        @extend .button-default;
        height: 25px;
        width: 25px;
        background-color: transparent;
        color: $global-blue;
        margin-left: auto;
        margin-right: 10px;
      }
    }
  }
}

.users-content-col2 {
  width: 100%;

  &-row1 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 2rem;
    margin-bottom: 1.25rem;

    &-company-bar {
      &-org {
        text-decoration: underline;
      }
      button {
        @extend .button-default;
        background-color: transparent;
        font-size: 1.0625rem;
        font-weight: 500;
        color: $colour-white;
        letter-spacing: 1px;
        margin-left: 10px;
      }
    }
  }

  &-row2 {
    height: 3rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    margin-bottom: 1rem;
    // Hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    scrollbar-width: none;

    &-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      background-color: rgba($sidebar-bg, 0.89);
      border-radius: 16px;
      height: 100%;
      color: $colour-white;
      font-size: 0.75rem;

      &.selected {
        background-color: rgba($colour-white, 0.2);

        .link {
          display: inline-block;
          font-size: 0.75rem;
        }
      }

      &-left-item {
        margin-right: auto;
        margin-left: 10px;
      }

      &-right-item {
        &-text1 {
          display: flex;
          flex-direction: row;

          & > * + * {
            margin-left: 10px;
          }
        }
      }
    }
  }

  .ScrollbarsCustom {
    height: vh(100, -17rem) !important;
  }

  &-row3 {
    margin-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
  }

  &-count-title {
    font-size: 1.25rem;
    font-weight: 500;
    color: $colour-white;
    letter-spacing: 1px;
    margin-left: 10px;
  }

  .level {
    .level-right {
      display: flex;
      align-items: center;
      .search-bar {
        margin-right: 1em;
      }
    }
  }
}
