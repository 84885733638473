/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

.dot-avatar {
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;

  .avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border-radius: 50%;
    font-size: 0.5em;
    line-height: 0.5em;
  }

  .avatar-img {
    border-radius: 50%;
  }

  & > .dot-avatar {
    position: absolute;

    height: 1em !important;
    width: 1em !important;
    bottom: 0px;
    right: 0px;

    filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.5));

    .avatar,
    .avatar-img {
      height: 1em !important;
      width: 1em !important;
      background-color: white;
    }
  }
}
