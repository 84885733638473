/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

.context-menu {
  -webkit-user-select: none;
  user-select: none;

  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 4px;

  min-width: 240px;

  z-index: 15;
  background-color: $colour-grey-1 !important;

  button {
    padding: 15px;

    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & > * + * {
      margin-left: 10px;
    }

    span {
      flex-grow: 1;
      text-align: left;
    }

    .icon {
      height: 20px;
      width: 20px;
    }
    svg {
      height: 16px;
      width: 16px;
    }
    img {
      height: 20px;
      width: 20px;
      &.light {
        filter: invert(100%) sepia(0%) saturate(1854%) hue-rotate(312deg)
          brightness(108%) contrast(104%);
      }
    }

    text-decoration: none;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    letter-spacing: -0.02em;

    /* White */
    color: $colour-white;

    background-color: transparent;
    border: none;

    &:hover {
      background-color: $colour-grey-2;
    }
  }
}
