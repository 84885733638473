/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

.mission-nav {
  height: vh(100);
  width: vw(100, -#{$nav-width});
  display: flex;
  flex-direction: row;

  .blank-card {
    position: relative;
    height: 20rem;
    width: 17.5rem;

    img {
      height: 20rem;
      width: 17.5rem;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 100%;
    min-width: vw(100, -#{$nav-width});
    max-width: vw(100, -#{$nav-width});

    & > * + * {
      margin-top: 10px;
    }

    .columns {
      margin: 0;
    }
  }
}

/* Row containing welcome and profile button */
.nav-row1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 200px;

  &-col1 {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-welcome {
      color: $colour-white;

      /* Heading - 48px */
      font-style: normal;
      font-weight: 600;
      font-size: 3rem;
      line-height: 3.25rem;
      /* identical to box height, or 125% */
      display: flex;
      align-items: center;
      letter-spacing: -0.03em;

      justify-content: space-between;

      color: $colour-white;

      margin-bottom: 8px;

      .buttons {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        & > * + * {
          margin-left: 1rem;
        }

        .search {
          background: rgba(25, 30, 31, 0.5);
          border: 1px solid $colour-grey-2;

          @media screen and (min-width: $ipad-port-max-width + 1) {
            width: 328px;
          }
          svg {
            font-size: 1rem;
            height: 1rem;
            width: 1rem;
          }
        }

        &-button {
          &-border {
            height: 3.125rem;
            width: 3.125rem;
            background-color: rgba($colour-black, 0.2);
            border-radius: 100%;
            margin-right: 1rem;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
          }

          &-center {
            @extend .button-default;
            background-color: transparent;
            color: $global-blue;
          }
        }
      }
    }

    &-active-missions {
      /* Heading - 22px */
      font-style: normal;
      font-weight: 500;
      font-size: 1.375rem;
      line-height: 1.625rem;
      /* identical to box height, or 127% */
      display: flex;
      align-items: center;
      letter-spacing: -0.04em;

      /* White */
      color: $colour-white;

      span {
        text-decoration: underline;
        color: $global-blue;
        margin-left: 5px;
        font-weight: 500;
      }

      .tabs {
        width: 40rem;

        button {
          font-size: 1.375rem;
          line-height: 28px;
        }
      }
    }
  }
}

.nav-row2 {
  height: 47%;
  overflow: visible;
  display: flex;
  flex-direction: row;
  align-items: center;

  position: relative;
  left: -1rem;
  width: calc(100% + 2rem);

  // this makes the element blent with gray
  // mix-blend-mode: hard-light;

  // &::after {
  //   position: absolute;
  //   content: "";
  //   left: -10px;
  //   top: 0px;
  //   // oversize it a tad
  //   height: 450px;
  //   width: calc(100% + 20px);
  //   // gray shadow causes the transparency effect
  //   box-shadow: inset -50px 0px 15px -15px grey, inset 50px 0px 15px -15px grey;
  //   // box-shadow: inset -20px 0px 20px 50px gray, inset 20px 0px 20px 50px gray;
  //   pointer-events: none;
  // }

  .card {
    &.disabled {
      opacity: 0.5;
    }
  }

  .mission-seperator {
    width: 100%;
    border-bottom: solid 1px $colour-grey-2;
  }

  h3 {
    width: 100%;
    color: white;
  }

  .spacer {
    display: flex;
    flex-direction: column;
    width: 100px;
    min-width: 100px;
  }

  &.full {
    height: vh(100, -316px);
  }

  &-col1 {
    @extend .button-default;
    @extend .scale-animation;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(23, 25, 26);
    border: 1px solid rgba(89, 94, 96, 0.577);
    border-radius: 5px;
    box-shadow: -2px 1px 5px rgb(0, 0, 0);
    width: 60px;
    height: 90%;
    max-width: 60px;
    z-index: 1;

    // Plus button
    font-size: 2.5rem;
    font-weight: 550;
    color: rgba(0, 181, 197, 0.858);

    &:hover {
      background-color: rgb(29, 32, 33);
      color: rgba(0, 181, 197, 0.808);
    }
  }

  &-panel-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    scroll-behavior: smooth;
    height: 100%;

    & > * + * {
      margin-left: 24px;
    }
  }

  &-panel-container-grid {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .card {
      flex-grow: 0;
      min-height: 18.5rem;
      max-height: 18.5rem;
    }

    flex-wrap: wrap;
    scroll-behavior: smooth;
    height: 100%;
    padding-left: 1.5rem;

    margin-left: -1.5rem;
    margin-top: -1.5rem;

    & > * {
      margin-left: 1.5rem;
      margin-top: 1.5rem;
    }
  }
}

.has-fade {
  .nav-row1 {
  }

  .nav-row2 {
    $gradient-shadow: 100px;
    $gradient-spread: 140px;

    .ScrollbarsCustom-Wrapper {
      margin-left: -$gradient-shadow;
      margin-right: -$gradient-shadow;

      .nav-row2-panel-container {
        margin-left: $gradient-shadow;
        padding: 0.75em;
      }

      -webkit-mask: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) #{$gradient-shadow},
        rgba(0, 0, 0, 1) #{$gradient-spread},
        rgba(0, 0, 0, 1) calc(100% - #{$gradient-spread}),
        rgba(0, 0, 0, 0) calc(100% - #{$gradient-shadow}),
        rgba(0, 0, 0, 0)
      );
      mask: linear-gradient(
        to right,
        rgba(0, 0, 0, 0),
        rgba(0, 0, 0, 0) #{$gradient-shadow},
        rgba(0, 0, 0, 1) #{$gradient-spread},
        rgba(0, 0, 0, 1) calc(100% - #{$gradient-spread}),
        rgba(0, 0, 0, 0) calc(100% - #{$gradient-shadow}),
        rgba(0, 0, 0, 0)
      );
    }
  }
}

/* Row 3 containing scroll and archived missions buttons */

.nav-row3 {
  display: flex;
  width: 100%;
  justify-content: flex-end;

  &-scroll-button {
    @extend .button-default;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: rgba($colour-black, 0.3);
    font-size: 1.25rem;
    font-weight: 700;
    border-radius: 100%;

    &.dot-button {
      color: rgb(0, 181, 197);
    }

    &:hover {
      background-color: rgb(29, 32, 33);
      color: rgba(0, 181, 197, 0.808);
    }
  }
  &-view-archived {
    @extend .button-default;
    margin-left: auto;
    margin-right: 40px;
    background-color: transparent;
    color: rgb(0, 181, 197);
    font-size: 1.25rem;
  }
}

.mission-nav-profile-container {
  height: 100%;
  width: 0px;
  position: fixed;
  z-index: 5;
  top: 0;
  right: 0;
  overflow-x: hidden;
  transition: all 0.5s ease-in-out;

  background-color: $colour-white;
  box-shadow: -2px 0px 9px 5px #00000046;
}

.has-notification {
  .mission-nav {
    height: vh(100, -$banner-height);
  }
}

.card {
  &.create {
    .content {
      flex-grow: 1;
      border-radius: 8px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      color: $colour-interactions-a;

      & > * + * {
        margin-top: 1em;
      }
      font-size: 1.5em;
    }
  }
}
