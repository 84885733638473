/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules.scss';

.tasks {
  padding: 1rem;

  & > * + * {
    margin-top: 1rem;
  }

  .task {
    border: solid 1px $colour-grey-4;
    background-color: $colour-grey-5;
    border-radius: 12px;
    color: $colour-grey-1;
    padding: 1rem;

    h4 {
      color: $colour-grey-1;
    }
    h5 {
      color: $colour-grey-1;
    }
    & > * + * {
      margin-top: 1rem;
    }

    .section-foot {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between !important;
      border-top: none !important;
      padding-top: 0 !important;

      & > * + * {
        margin-left: 15px !important;
      }

      .units {
        flex-grow: 0;

        display: flex;
        flex-direction: row;
        align-items: center;

        & > * + * {
          margin-left: -4px !important;
        }

        .avatar {
          box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1) !important;

          border: 2px solid $colour-white !important;
          color: $colour-white !important;

          border-radius: 50% !important;

          font-style: normal;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: -0.01em !important;
        }
      }

      .assigned {
        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;
      }

      .time {
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 20px;
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;

        & > * + * {
          margin-left: 10px;
        }

        text-transform: uppercase;
      }
    }
  }
}

.dark-mode {
  .task {
    background-color: $colour-grey-1;

    h4 {
      color: $colour-white;
    }
    h5 {
      color: $colour-white;
    }

    p {
      color: $colour-white;
    }

    .section-foot {
      color: $colour-white;
    }
  }
}
