/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules';

button.dot-button {
  -webkit-user-select: none;
  user-select: none;

  &.drag {
    cursor: grab !important;
  }

  &.badge {
    position: relative;
  }
  // position: relative;

  // .loading {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   position: absolute;
  // }
}
input.dot-button-file {
  visibility: hidden;
  width: 0;
  height: 0;
}

div.badge {
  border-radius: 50%;
  background-color: $colour-negative-status-a;
  width: 20px;
  height: 20px;
  color: $colour-white;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.8);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.01em;
  text-align: center;

  position: absolute;
  top: -5px;
  right: -5px;
}
