/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules.scss';

.weatherTable {
  overflow-x: scroll;
  overflow-y: hidden;
  font-size: 14px;
  max-height: 300px;
}

::-webkit-scrollbar {
  background: transparent;
  height: 4px;
  bottom: 4px;
}

::-webkit-scrollbar-thumb:horizontal {
  background: rgba(80, 83, 102, 0.5);
  border-radius: 5px;
}

.weatherHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.w400 {
  font-weight: 400;
}
.w600 {
  font-weight: 600;
}
.weatherHeader {
  max-height: 60px;
  img,
  a {
    transition: all 0.3s ease-in-out;
  }
}
.weatherHeader .weatherHeaderTitle {
  font-size: 16px;
  min-width: 220px;
  padding: 8px;
  display: flex;
}
.logo {
  max-width: 40px;
  max-height: 40px;
  margin-right: 10px;
}
.logo img {
  max-width: 40px;
  max-height: 40px;
  border-radius: 25px;
}
.light {
  color: rgba(80, 83, 102, 1);
}
.weatherHeader .weatherHeaderSummary {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  line-height: 16px;
  max-height: 50px;
}
.weatherHeaderSpacer {
  flex-grow: 1;
}
.weatherHeaderSummary .w600 {
  font-size: 14px;
}
.weatherHeaderSummary .location {
  background-color: rgba(237, 243, 245, 1);
  padding: 6px;
  border-radius: 4px;
  min-width: 200px;
  display: flex;
  transition: background 0.3s ease-in-out;
}
.weatherHeaderSummary .location .setting-location {
  color: #3b9fc2;
  font-size: 16px;
  font-weight: bold;
  align-self: center;
}
.weatherHeaderSummary .summary {
  display: flex;
  align-items: center;
  min-width: 220px;
}
.weatherHeaderSummary .summary div {
  padding-right: 4px;
  padding-left: 4px;
}
.summary .now {
  font-size: 10px;
}
.summary .nowTemp {
  font-size: 28px;
}
.weatherHeader .weatherHeaderControls {
  display: flex;
}
.weatherIconHeading {
  max-width: 40px;
}
.weatherIconSmall {
  max-width: 16px;
}
.weatherIconMedium {
  max-width: 24px;
}
.weatherIconGrid {
  max-width: 28px;
}
/* weather cell titles */
table {
  border-collapse: collapse;
  border-spacing: 0px;
  margin-bottom: 20px;
}
table tbody {
  border-top: 1px solid rgba(184, 189, 212, 1);
}
td {
  white-space: nowrap;
  border-bottom: 1px solid rgba(184, 189, 212, 1);
  padding: 8px;
}
td div {
}
.day {
  font-size: 12px;
}
.daySummary {
  text-align: right;
  padding: 0px 5px 0px 0px;
}
.start {
  min-width: 200px;
  font-weight: 400;
  font-size: 12px;
  color: rgba(80, 83, 102, 1);
}
/* weather cell */
.wc {
  text-align: center;
  width: 40px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.layerButton {
  padding: 16px;
  border-radius: 4px;
  color: rgba(25, 30, 31, 1);
}
.layerButton.active {
  background-color: rgba(237, 243, 245, 1);
  color: rgba(25, 30, 31, 1);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.dark-mode {
  ::-webkit-scrollbar-thumb:horizontal {
    background: rgba(255, 255, 255, 1);
    border-radius: 5px;
  }
  .layerButton {
    img {
      filter: invert(100%) sepia(93%) saturate(0%) hue-rotate(201deg)
        brightness(106%) contrast(106%);
    }
  }
}
