/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules.scss';

.history-sidebar {
  .content {
    .list {
      display: flex;
      flex-direction: column;
      & > * + * {
        margin-top: 10px;
      }

      margin: 0;
      padding: 0;

      .list-item {
        padding: 0 20px;
        margin-left: -20px;
        margin-right: -20px;
        border-radius: 0;
        display: block;

        .inner {
          padding: 15px;
          margin: 0;

          background: $colour-white;
          /* Shadow Medium */
          box-shadow: 0px 4px 12px rgba(0, 84, 101, 0.24);
          border-radius: 12px;
          .details {
            width: 100%;

            & > * + * {
              margin-top: 8px;
            }

            cursor: pointer;

            .author {
              display: flex;
              justify-content: space-between;
              align-items: center;
              width: 100%;
            }

            .date {
              font-style: normal;
              font-weight: 600;
              font-size: 1rem;
              line-height: 24px;
              /* identical to box height, or 150% */
              letter-spacing: -0.02em;

              color: $colour-black;
              text-transform: uppercase;
            }

            .tag {
              position: relative;

              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              justify-content: space-between;
              align-items: center;
              height: 24px;

              margin: 0;
              padding: 0;

              padding-right: 10px;

              font-style: normal;
              font-weight: normal;
              font-size: 1rem;
              line-height: 1rem;

              & > * + * {
                margin-left: 10px;
              }

              span {
                display: inline-block;
                height: 20px !important;
              }

              .avatar {
                position: absolute;
                top: -2px;

                box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1) !important;

                border: 2px solid $colour-white !important;
                color: $colour-white !important;

                border-radius: 50% !important;

                font-style: normal;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: -0.01em !important;
              }

              height: 24px;
              border-radius: 12px;
              background-color: $colour-white;
              box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
            }
          }
        }

        border-left: solid 4px transparent;
        &.selected {
          border-left: solid 4px $colour-interactions-a;
          .inner {
            border: 2px solid $colour-interactions-a;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}
