/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

.welcome {
  background: rgb(2, 0, 36);
  background: -moz-linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 6%,
    rgba(122, 122, 122, 1) 75%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 6%,
    rgba(122, 122, 122, 1) 75%
  );
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 1) 6%,
    rgba(122, 122, 122, 1) 75%
  );
  width: vw(100);
  height: vh(100);
  overflow-x: hidden;

  .columns {
    height: 100%;
    margin-top: 0 !important;
  }

  .col-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .text {
      // color: rgb(0, 181, 197);
      font-weight: 600;
      color: $colour-white;
      width: 40%;
      line-height: 24px;

      p {
        font-weight: 400;
        margin-top: 20px;
      }
    }
  }

  .col-2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $colour-white;
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;

    @media (max-width: 700px) {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }

    p {
      a {
        color: #56b8db;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    form {
      width: 100%;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .login-title {
      width: 50%;
      display: flex;
      justify-content: flex-start;
      font-size: 1.5rem;
      color: black;

      font-style: normal;
      font-weight: 600;
      font-size: 2.25rem;
      line-height: 40px;
      /* or 111% */
      display: flex;
      align-items: center;
      letter-spacing: -0.03em;

      /* Grey 1 */
      color: #191e1f;

      margin-bottom: 36px;
    }

    div {
      width: 50%;
    }

    button {
      width: 50%;
      margin-bottom: 24px !important;
    }

    .text {
      width: 50%;
      line-height: 24px;
      margin-top: -36px;
      margin-bottom: 33px;

      p {
        font-weight: 400;
        margin-top: 21px;
      }
    }

    .forgot-signup {
      width: 50%;
      margin-top: 5px;

      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 21px;

      text-decoration-line: underline;

      /* Interactions A */
      color: $colour-interactions-a;

      button {
        color: $colour-interactions-c;
        text-decoration: underline;
        border: none;
        outline: none;
        cursor: pointer;
        padding: 0;
        text-align: left;
        margin: 0;
      }
    }
  }
}

// Custom media query

// @media (max-width: 768px) {
//   .login-columns {
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//   }
//   .login-col-2 {
//     display: flex;
//     justify-content: flex-start;
//   }
// }
