/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty 
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any 
 * licence may have from the copyright owner to use the Software, you must not copy, 
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to 
 * derive the source code of or modify the Software, nor create any derivative works of 
 * the Software.
 */

@import '../../../modules.scss';

.org-data-sharing {
  .settings-row {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    & > * + * {
      margin-top: 0;
    }

    p {
      margin: 20px 0;
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: -0.32px;
      text-align: left;
      color: $colour-grey-1;
    }

    .default {
      padding: 24px 18px 21px 25px;
      background-color: $colour-grey-5;
      color: $colour-grey-1;
      border-radius: 8px;

      .heading {
        margin: 0 0 12px;
        font-size: 1rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.32px;
        text-align: left;
      }
      ul {
        padding-left: 32px;
      }
    }
  }

  .blocks {
    & > * + * {
      margin-top: 0 !important;
    }
    margin-left: -20px;
    margin-right: -20px;

    & > .title {
      margin: 9px 20px 0px 20px;
      font-size: 0.75rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: -0.12px;
      text-align: left;
      text-transform: uppercase;
      padding: 0;
    }
  }

  .expandable {
    margin: 12px 23px 12px;
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 rgba(0, 84, 101, 0.24);
    background-color: $colour-white;
    overflow: hidden;

    .collapsed-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-top: 12px;
      padding-bottom: 12px;
      padding-right: 12px;

      .icon {
        cursor: pointer;
        flex-shrink: 0;
        margin: 12px;
        width: 24px;
        height: 24px;
        border: solid 2px $colour-black;
        border-radius: 50%;
        padding: 0;
        svg {
          display: none;
          width: 24px !important;
          height: 24px !important;
        }
        &.selected {
          svg {
            display: block;
          }
          border: none;
        }
      }

      .info {
        flex-grow: 1;
        padding: 0 12px;

        color: $colour-grey-1;
        font-size: 1rem;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: -0.32px;
        text-align: left;

        .title {
          font-weight: 600;
          padding-top: 0;
          margin-bottom: 0;
        }
        .description {
          font-weight: normal;
        }
      }

      .dot-button {
        padding: 12px;
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }

    .expanded-content {
      display: none;

      .foot {
        flex-grow: 0;
        margin: 51px 0 0;
        padding: 15px 23px 29px;
        background-color: $colour-grey-5;
        box-shadow: none;
        & > * + * {
          margin-left: 0;
        }

        span {
          width: 269px;
          height: 24px;
          flex-grow: 0;
          margin: 0 59px 12px 0;
          font-size: 1rem;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: -0.32px;
          text-align: left;
        }
      }
    }

    &.expanded {
      .collapsed-content {
        border-bottom: solid 1px #c7cce5;
      }
      .expanded-content {
        display: block;
      }
    }
  }
}
