/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

div.rich-text {
  position: relative;
  font-weight: 500;
  border-radius: 2px;
  min-height: 10rem;
  padding: 0;
  min-width: 0;

  & > label {
    position: absolute;
    top: -0.8em;
    left: 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: 0.5rem;

    font-size: 0.75rem;
    line-height: 1rem;
    color: black;
    background: $input-bg-light;
  }

  [data-slate-editor] {
    font-family: $font-family !important;
    padding: 1rem 0.625rem 0.5rem 0.625rem;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 10rem !important;
    width: 100%;

    border: 1px solid black;
    border-radius: 2px;

    font-size: 1rem;
    text-align: start;

    &::placeholder {
      color: $colour-grey-3;
    }

    &:hover {
      border-color: $colour-grey-3;
    }
    &:active,
    &:focus {
      border-color: #3990b6;
      box-shadow: 0px 0px 8px rgba(111, 87, 229, 0.5);
    }

    &:disabled {
      background-color: $colour-grey-5;
      border-color: $colour-grey-5;
      color: $colour-grey-3;

      & + label {
        color: $colour-grey-3;
      }
    }
  }

  &.touched:invalid {
    input {
      border-color: $colour-negative-status-a;
    }
    label {
      color: $colour-negative-status-a;
    }
  }

  &.dark {
    border: 1px solid $colour-grey-5;
    background-color: $input-bg-dark;

    label {
      color: $colour-white;
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 1) 0%,
        rgba(0, 0, 0, 1) 50%,
        rgba(0, 0, 0, 0) 50%,
        rgba(0, 0, 0, 0) 100%
      );
    }

    textarea {
      background-color: $input-bg-dark;
      color: $colour-white;
    }
  }

  .toolbar {
    height: 3rem;
    display: flex;
    flex-direction: row;

    justify-content: flex-start;
    align-items: center;
    border-top: 1px solid black;

    .dot-button {
      background-color: transparent;
      border: none;
      width: 3rem;
      height: 3rem;
      margin: 0;
      padding: 0;

      &.is-active {
        background-color: $colour-grey-5;
      }
    }

    .seperator {
      height: 2.9rem;
      width: 1px;
      border-right: solid 1px $colour-grey-3;
    }
  }
}

button.dropdown-item {
  background: transparent;
  border: none;
}

.color-picker {
  cursor: pointer;
  height: 1.5rem;
  .icon.main {
    width: 1.5rem;
    height: 3rem;
  }
  input {
    visibility: hidden;
    width: 0;
  }
}
