/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules';

.org-info {
  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: $colour-white;
  border-radius: 18px;
  padding: 15px;

  img {
    height: 50px;
    width: 50px;
    border-radius: 15px;
  }

  &-name {
    color: $global-blue;
    margin-left: 20px;
    font-size: 1.25rem;
    font-weight: 550;
    flex-basis: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-location {
    // margin-left: 50px;
    font-size: 0.875rem;
    flex-basis: 5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-active-users {
    margin-left: 50px;
    margin-right: auto;
    font-size: 0.875rem;
    flex-basis: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-delete-org {
    margin-left: auto;
    height: 40px;
    margin-right: 20px;
    @extend .button-default;
    text-decoration: underline;
    background-color: transparent;
    &.dot-button {
      color: $colour-negative-status-a;
      &:hover {
        background-color: $colour-negative-status-c;
      }
    }
    font-size: 0.875rem;
  }

  &-edit-org {
    margin-right: 20px;
    height: 40px;
    @extend .button-default;
    text-decoration: underline;
    background-color: transparent;
    &.dot-button {
      color: $global-blue;
      &:hover {
        color: $colour-white;
        background-color: $colour-interactions-c;
      }
    }
    font-size: 0.875rem;
  }
}
