/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules';

$top-bar-height: 5rem;

.create-mission-units {
  display: flex;
  flex-direction: column;

  border-right: 1px solid $dark-border;
  & > * + * {
    margin-top: 1rem;
  }

  & > .remove {
    -webkit-user-select: none;
    user-select: none;
    border: dashed rgba($colour-grey-3, 0.8) 3px;
    border-radius: 24px;
    padding: 24px;
    color: rgba($colour-grey-3, 0.8);
    margin: 0 20px 20px 20px;

    display: flex;
    & > * + * {
      margin-left: 10px;
    }
    align-items: center;

    &.highlight {
      border: dashed rgba($colour-white, 1) 3px;
      color: rgba($colour-white, 1);
    }
    &.dim {
      border: dashed rgba($colour-grey-3, 0.1) 3px;
      color: rgba($colour-grey-3, 0.1);
    }
  }

  .search-bar {
    display: flex;
    flex-direction: column;
    & > * + * {
      margin-top: 1rem;
    }
    padding: 20px 20px 0 20px;
  }

  &-title {
    font-size: 1.375rem;
    color: $colour-white;
    font-weight: 500;

    .menu-button {
      button {
        font-size: 1.375rem;
        text-decoration: none !important;

        width: 18.75rem;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  &-searchbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #283537;
    width: 100%;
    height: 45px;
    margin-top: 20px;
    border: 1px solid $dark-border;
    border-radius: 6px;
    input {
      margin-left: 10px;

      background-color: transparent;
      border: none;
      &::placeholder {
        color: $colour-grey-3;
      }
    }
  }
  &-subtitle {
    color: $colour-white;
    font-size: 0.875rem;

    .breadcrumbs {
      display: flex;
      flex-direction: row;

      justify-content: flex-start;
      align-items: center;

      & > * + * {
        margin-left: 10px;
      }
    }
  }

  .ScrollbarsCustom {
    margin: 20px 0;

    .ScrollbarsCustom-Scroller {
      padding: 0 20px;

      & > .ScrollbarsCustom-Content {
        display: flex;
        flex-direction: column;
        & > * + * {
          margin-top: 1rem;
        }
      }
    }
  }

  &-panel-container {
    display: flex;
    flex-direction: column;
    & > * + * {
      margin-top: 10px;
    }
  }
}

.dragging {
  opacity: 0.7;
  .unit-panel-container {
    transform: rotate(-1deg);

    .breadcrumb {
      display: flex;
    }
  }
}

.team,
.mission {
  .unit-panel-container {
  }
}

.clone {
  opacity: 0.4;
}

.unit-panel {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 0;

    border-radius: 6px;

    max-width: calc(#{$param-bar-width} - 40px);

    .body {
      flex-direction: column;
      justify-content: space-evenly;
      align-items: flex-start;
      width: 100%;

      .breadcrumb {
        height: 20px;
        width: 100%;
        margin-bottom: 0;
        padding: 3px;

        border-radius: 6px 6px 0 0;

        display: none;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        background-color: $unit-breadcrumb;
        color: $colour-grey-3;
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 20px;
        letter-spacing: -0.01em;

        padding-left: 5px;

        &.assigned {
          display: flex;
        }
      }

      .unit {
        flex-grow: 1;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      border-radius: 6px;
      background-color: $colour-white;

      .img {
        width: $team-size;
        height: $team-size;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: 5px;
      }

      .text {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        justify-content: center;

        /* Heading - 16px */
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 18px;
        /* identical to box height, or 150% */
        letter-spacing: -0.02em;

        /* Grey 1 */
        color: $colour-grey-1;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        span {
          /* Body - 12px */
          font-style: normal;
          font-weight: normal;
          font-size: 0.75rem;
          line-height: 14px;
          /* identical to box height, or 167% */
          letter-spacing: -0.01em;
        }
      }
      .more {
        padding: 10px;
        margin-left: auto;
        color: $colour-grey-3;
        svg {
          color: $colour-grey-3;
        }
      }
      .count {
        padding: 10px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        & > * + * {
          margin-left: 2px;
        }
        align-items: center;
        color: $colour-grey-1;
        svg {
          color: $colour-grey-3;
        }

        background-color: $colour-grey-5;
        height: 100%;

        border-radius: 6px;

        /* 1100 */

        /* Body - 12px */
        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 20px;
        /* identical to box height, or 167% */
        text-align: center;
        letter-spacing: -0.01em;
      }
    }

    &.loading {
      opacity: 0.1;
    }

    &.active {
      position: relative;

      .body {
        background-color: $colour-white;
        opacity: 0.6;
      }

      &::after {
        content: 'assigned';
        display: block;
        background-color: $colour-grey-1;
        position: absolute;
        top: 0;
        left: 0;

        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;
        /* or 133% */
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;

        /* White */
        color: $colour-white;

        text-transform: uppercase;

        opacity: 1;

        padding: 3px;
        border-radius: 4px 0 0 0;
      }
    }

    &.guest {
      position: relative;

      &::after {
        content: 'guest';
        display: block;
        background-color: $colour-grey-1;
        position: absolute;
        top: 0;
        left: 0;

        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;
        /* or 133% */
        display: flex;
        align-items: center;
        letter-spacing: -0.01em;

        /* White */
        color: $colour-white;

        text-transform: uppercase;

        opacity: 1;

        padding: 3px;
        border-radius: 4px 0 0 0;
      }
    }
  }
}
