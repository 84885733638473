/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../../modules';

// Login invitation

.invitation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .invitation-form {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;

    div {
      width: 100%;
    }
    button {
      width: 100%;
    }
  }

  &-email {
    border: 2px solid rgba(233, 233, 233, 0.646);
    background-color: rgba(233, 233, 233, 0.646);
    display: flex;
    align-items: center;
    height: 50px;

    &-text {
      margin-left: 14px;
      font-size: 0.875rem;
    }
  }
  &-text {
    margin-bottom: 10px;
    &-1 {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: baseline;
      justify-content: flex-start;

      font-style: normal;
      font-weight: 600;
      font-size: 2.25rem;
      line-height: 40px;
      /* or 111% */
      letter-spacing: -0.03em;

      /* Grey 1 */
      color: $colour-grey-1;
    }

    &-2 {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: 1.5rem;
      color: black;
      font-weight: 500;

      p {
        text-decoration: underline;
      }
    }

    &-3 {
      width: 90%;
      display: flex;
      justify-content: flex-start;
      font-size: 0.8125rem;
      color: black;
      font-weight: 500;
      margin-top: 20px;

      p {
        font-weight: 650;
      }
    }
  }

  &-login {
    @extend .button-default;
    height: 45px;
    margin-top: 20px;
    background-color: $colour-interactions-c;
    width: 100%;
    border-radius: 4px;
    border: none;
    outline: none;
    color: $colour-white;
    font-size: 1.0625rem;
    font-weight: 500;
    letter-spacing: 0px;
  }
}
