/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';
.dot-tag-select {
  min-height: 3.5rem;

  div.dot-tag-select {
    position: relative;
    font-weight: 500;
    padding: 0;
    height: 3.5rem;

    label {
      position: absolute;
      top: -0.8em;
      left: 0;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      margin-left: 0.5rem;

      font-size: 0.75rem;
      line-height: 1rem;
      color: black;
      background: $input-bg-light;
    }

    input {
      font-family: $font-family !important;
      overflow-y: auto;
      resize: none;
      height: 100%;
      width: 100%;
      padding: 0.5rem 0.625rem;
      text-align: start;
      border: 1px solid black;
      border-radius: 2px;
      border-radius: 2px;
      outline: none;

      font-size: 1rem;

      &::placeholder {
        color: $colour-grey-3;
      }

      &:hover {
        border-color: $colour-grey-3;
      }
      &:active {
        border-color: #3990b6;
        box-shadow: 0px 0px 8px rgba(111, 87, 229, 0.5);
      }
      &.touched:invalid {
        border-color: $colour-negative-status-a;

        & + label {
          color: $colour-negative-status-a;
        }
      }
      &:disabled {
        background-color: $colour-grey-5;
        border-color: $colour-grey-5;
        color: $colour-grey-3;

        & + label {
          color: $colour-grey-3;
        }
      }
    }

    &.dark {
      border: 1px solid $colour-grey-5;
      background-color: $input-bg-dark;

      label {
        color: $colour-white;
        background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 1) 0%,
          rgba(0, 0, 0, 1) 50%,
          rgba(0, 0, 0, 0) 50%,
          rgba(0, 0, 0, 0) 100%
        );
      }

      input {
        background-color: $input-bg-dark;
        color: $colour-white;
      }
    }
  }
  .tags {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: center;

    & > * + * {
      margin-left: 10px;
    }

    margin-top: 10px;

    .tag {
      position: relative;

      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      height: 24px;

      padding: 0;

      padding-right: 10px;

      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 1rem;

      & > * + * {
        margin-left: 10px;
      }

      .unit-icon.svg-inline--fa {
        height: 10px;
      }
      span {
        display: inline-block;
      }

      .button {
        padding: 0;
        height: 8px;
      }

      .avatar {
        position: absolute;
        top: -2px;

        box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1) !important;

        border: 2px solid $colour-white !important;
        color: $colour-white !important;

        border-radius: 50% !important;

        font-style: normal;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: -0.01em !important;
      }

      height: 24px;
      border-radius: 12px;
      background-color: $colour-white;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
    }
  }
}

.dot-tag-select.units {
  position: absolute;
  background-color: $colour-white;
  z-index: 45;

  display: flex;
  flex-direction: column;

  border-radius: 0 0 12px 12px;
  box-shadow: 0px 4px 12px rgba(0, 84, 101, 0.24);
  width: calc(100% - 94px);

  .button {
    justify-content: flex-start;
    padding: 24px;
    height: 72px;
    &:hover {
      background-color: $colour-grey-6;
    }

    &.selected {
      background-color: $colour-grey-6;
    }
  }

  .unit {
    display: flex;
    justify-content: flex-start;
    width: 100%;

    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 24px;
    /* identical to box height, or 150% */
    letter-spacing: -0.02em;
    color: $colour-grey-1;

    & > * + * {
      margin-left: 10px;
    }

    div.text {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 24px;
        color: $colour-black;
      }
    }
  }
}
