/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules';

.control-1 {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  .left {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 50px;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    .label {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 600;
      padding-bottom: 5px;
    }
    .required {
      color: #d90000;
      font-weight: 400;
      font-size: 7px;
      line-height: 8px;
      font-style: italic;
      margin-left: 5px;
    }
    > textarea {
      width: 100%;
      height: 60px;
      background: #edf3f5;
      border: 1px solid #d6d9e8;
      border-radius: 7px;
      font-size: 10px;
      padding: 5px;
      resize: none;
    }
    > textarea:disabled {
      background: #dee2ed;
      border: 1px solid #d6d9e8;
    }
    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 10px;
      .tip {
        font-style: italic;
        font-size: 10px;
        display: flex;
        justify-content: flex-start;
        color: #6a6a6a;
      }
      .last-saved {
        font-style: italic;
        font-size: 10px;
        display: flex;
        justify-content: flex-end;
        min-width: 30%;
        height: 100%;
      }
    }
  }
  .action-buttons {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-left: 20px;
    .row {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      > button {
        height: 30px;
        width: 30px;
        background: #ffffff;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        > img {
          display: flex;
          width: 100%;
        }
      }
      > button:nth-of-type(1) {
        margin-right: 5px;
      }
      > button:nth-of-type(2) {
        margin-right: 5px;
      }
      > button:hover {
        background-color: rgba(214, 217, 232, 1) !important;
      }
    }
  }
}

.control-2 {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  .left {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 50px;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    .label {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 600;
      padding-bottom: 5px;
    }
    .required {
      color: #d90000;
      font-weight: 400;
      font-size: 7px;
      line-height: 8px;
      font-style: italic;
      margin-left: 5px;
    }
    .body {
      display: flex;
      flex-direction: row;
      width: 100%;
      .unit-name {
        width: 100%;
        height: 40px;
        background: #edf3f5;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        font-size: 10px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        > input {
          background: #edf3f5;
          border: none;
          width: 135px;
        }
        > input:disabled {
          background: #dee2ed;
        }
        > input:focus {
          outline: none;
        }
        > button {
          height: 30px;
          width: 30px;
          background: #edf3f5;
          border: none;
          transition: none !important;
          > svg,
          img {
            display: flex;
            width: 100%;
          }
        }
        > button:disabled {
          background: #dee2ed;
        }
      }
      .at {
        width: 40px;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        line-height: 16px;
      }
      .unit-location {
        width: 100%;
        height: 40px;
        background: #edf3f5;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        font-size: 10px;
        padding: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        > input {
          background: #edf3f5;
          border: none;
          width: 135px;
        }
        > input:disabled {
          background: #dee2ed;
        }
        > input:focus {
          outline: none;
        }
        > button {
          height: 30px;
          width: 30px;
          background: #edf3f5;
          border: none;
          transition: none !important;
          > svg,
          img {
            display: flex;
            width: 100%;
          }
        }
        > button:disabled {
          background: #dee2ed;
        }
      }
      .remove-button {
        padding-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        > button {
          height: 30px;
          width: 30px;
          background: #edf3f5;
          border: 1px solid #d6d9e8;
          border-radius: 7px;
          transition: none !important;
          > img {
            display: flex;
            width: 100%;
          }
        }
      }
    }
    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 10px;
      .tip {
        font-style: italic;
        font-size: 10px;
        display: flex;
        justify-content: flex-start;
        color: #6a6a6a;
      }
      .last-saved {
        font-style: italic;
        font-size: 10px;
        display: flex;
        justify-content: flex-end;
        min-width: 30%;
        height: 100%;
      }
    }
    .add-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      > button {
        height: 30px;
        width: 30px;
        background: #edf3f5;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        transition: none !important;
        > img {
          display: flex;
          width: 100%;
        }
      }
      .tip {
        font-style: italic;
        font-size: 12px;
        color: #6a6a6a;
      }
    }
  }
  .action-buttons {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-left: 20px;
    .row {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      > button {
        height: 30px;
        width: 30px;
        background: #ffffff;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        > img {
          display: flex;
          width: 100%;
        }
      }
      > button:nth-of-type(1) {
        margin-right: 5px;
      }
      > button:nth-of-type(2) {
        margin-right: 5px;
      }
      > button:hover {
        background-color: rgba(214, 217, 232, 1) !important;
      }
    }
  }
}

.control-3 {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  .left {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 50px;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .react-datepicker-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 40px;
        > input {
          background: #edf3f5;
          border: 1px solid #d6d9e8;
          border-radius: 7px;
          width: 120px;
          height: 40px;
          padding: 10px;
        }
        > input:disabled {
          background: #dee2ed;
        }
        > input:focus {
          outline: none;
        }
        > svg {
          position: absolute;
          right: 15px;
        }
      }
      .timepicker-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 80px;
        margin-left: 20px;
        > input {
          background: #edf3f5;
          border: 1px solid #d6d9e8;
          border-radius: 7px;
          width: 120px;
          height: 40px;
          padding: 10px;
        }
        > input:disabled {
          background: #dee2ed;
        }
        > input:focus {
          outline: none;
        }
        > svg {
          position: absolute;
          right: 15px;
        }
      }
      > input {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: #edf3f5;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        width: 100%;
        height: 40px;
        padding: 10px;
        margin: 10px;
      }
      > input:disabled {
        background: #dee2ed;
      }
      .to {
        width: 40px;
        font-weight: 500;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        line-height: 16px;
      }
      .input {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        > input {
          background: #dee2ed;
          border: 1px solid #d6d9e8;
          border-radius: 7px;
          width: 160px;
          height: 40px;
          text-align: center;
        }
        > input:focus {
          outline: none;
        }
      }
    }
  }
  .action-buttons {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-left: 20px;
    .row {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      > button {
        height: 30px;
        width: 30px;
        background: #ffffff;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        > img {
          display: flex;
          width: 100%;
        }
      }
      > button:nth-of-type(1) {
        margin-right: 5px;
      }
      > button:nth-of-type(2) {
        margin-right: 5px;
      }
      > button:hover {
        background-color: rgba(214, 217, 232, 1) !important;
      }
    }
  }
}

.control-4 {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  .left {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 50px;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    .label {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 600;
      padding-bottom: 5px;
    }
    .required {
      color: #d90000;
      font-weight: 400;
      font-size: 7px;
      line-height: 8px;
      font-style: italic;
      margin-left: 5px;
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      > input {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: #edf3f5;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        width: 100%;
        height: 40px;
        padding: 10px;
        margin: 10px;
      }
      > input:disabled {
        background: #dee2ed;
      }
      .from {
        width: 150px;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        background: #dee2ed;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        margin-left: 10px;
        .avatar-container {
          margin-left: 5px;
        }
        .user-name {
          font-size: 10px;
          font-weight: 600;
          width: 150px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .people-picker {
        width: 460px;
        height: 40px;
        border: 1px solid #d6d9e8;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        font-size: 10px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > input {
          border: none;
          width: 100%;
          height: 35px;
        }
        > input:disabled {
          background: #dee2ed;
        }
        > input:focus {
          outline: none;
        }
        > button {
          height: 30px;
          width: 30px;
          border: none;
          background: #ffffff;
          transition: none;
          > svg,
          img {
            display: flex;
            width: 100%;
          }
        }
      }
      .search-results {
        position: absolute;
        width: 460px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        border: 1px solid #d6d9e8;
        border-top: none;
        z-index: 50;
        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          background: #ffffff;
          height: 40px;
          border-bottom: 1px solid #d6d9e8;
          padding: 5px;
        }
        > div:hover {
          background: #d6d9e8;
        }
        > div:last-of-type {
          border-bottom: 1px transparent;
          border-bottom-left-radius: 7px;
          border-bottom-right-radius: 7px;
        }
      }
      .selected-personnel {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        border: 1px solid #d6d9e8;
        border-top: none;
        z-index: 50;
        min-height: 40px;
        display: flex;
        flex-wrap: wrap;
        .personnel-tag {
          display: flex;
          flex-direction: row;
          align-items: center;
          background: #edf3f5;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
          border-radius: 12px;
          width: auto;
          height: 24px;
          padding: 5px;
          margin: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 10px;
          .remove-button {
            background: #edf3f5;
            border: none;
            color: #191e1f;
          }
        }
        // > div:hover {
        //   background: #d6d9e8;
        // }
        // > div:last-of-type {
        //   border-bottom: 1px transparent;
        //   border-bottom-left-radius: 7px;
        //   border-bottom-right-radius: 7px;
        // }
      }
    }
  }
  .action-buttons {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-left: 20px;
    .row {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      > button {
        height: 30px;
        width: 30px;
        background: #ffffff;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        > img {
          display: flex;
          width: 100%;
        }
      }
      > button:nth-of-type(1) {
        margin-right: 5px;
      }
      > button:nth-of-type(2) {
        margin-right: 5px;
      }
      > button:hover {
        background-color: rgba(214, 217, 232, 1) !important;
      }
    }
  }
}

.control-5 {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  .left {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 50px;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    .label {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 600;
      padding-bottom: 5px;
    }
    .required {
      color: #d90000;
      font-weight: 400;
      font-size: 7px;
      line-height: 8px;
      font-style: italic;
      margin-left: 5px;
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .people-picker {
        width: 460px;
        height: 40px;
        border: 1px solid #d6d9e8;
        border-top-left-radius: 7px;
        border-top-right-radius: 7px;
        font-size: 10px;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > input {
          border: none;
          width: 100%;
          height: 35px;
        }
        > input:disabled {
          background: #dee2ed;
        }
        > input:focus {
          outline: none;
        }
        > button {
          height: 30px;
          width: 30px;
          border: none;
          background: #ffffff;
          transition: none;
          > svg,
          img {
            display: flex;
            width: 100%;
          }
        }
      }
      .search-results {
        position: absolute;
        width: 460px;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        border: 1px solid #d6d9e8;
        border-top: none;
        z-index: 50;
        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          background: #ffffff;
          height: 40px;
          border-bottom: 1px solid #d6d9e8;
          padding: 5px;
        }
        > div:hover {
          background: #d6d9e8;
        }
        > div:last-of-type {
          border-bottom: 1px transparent;
          border-bottom-left-radius: 7px;
          border-bottom-right-radius: 7px;
        }
      }
      .selected-personnel {
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        border: 1px solid #d6d9e8;
        border-top: none;
        z-index: 50;
        min-height: 40px;
        display: flex;
        flex-wrap: wrap;
        .personnel-tag {
          display: flex;
          flex-direction: row;
          align-items: center;
          background: #edf3f5;
          box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
          border-radius: 12px;
          width: auto;
          height: 24px;
          padding: 5px;
          margin: 5px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 10px;
          .remove-button {
            background: #edf3f5;
            border: none;
            color: #191e1f;
          }
        }
        // > div:hover {
        //   background: #d6d9e8;
        // }
        // > div:last-of-type {
        //   border-bottom: 1px transparent;
        //   border-bottom-left-radius: 7px;
        //   border-bottom-right-radius: 7px;
        // }
      }
    }
  }
  .action-buttons {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-left: 20px;
    .row {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      > button {
        height: 30px;
        width: 30px;
        background: #ffffff;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        > img {
          display: flex;
          width: 100%;
        }
      }
      > button:nth-of-type(1) {
        margin-right: 5px;
      }
      > button:nth-of-type(2) {
        margin-right: 5px;
      }
      > button:hover {
        background-color: rgba(214, 217, 232, 1) !important;
      }
    }
  }
}

.control-6 {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  .left {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 50px;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    .label {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 600;
      padding-bottom: 5px;
    }
    .required {
      color: #d90000;
      font-weight: 400;
      font-size: 7px;
      line-height: 8px;
      font-style: italic;
      margin-left: 5px;
    }
    .body {
      display: flex;
      flex-direction: row;
      width: 100%;
      .number-picker {
        padding: 10px;
        white-space: nowrap;
        > .top-section {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: baseline;
          > .ref {
            font-weight: 600;
            font-size: 24px;
            padding: 10px;
          }
          > .label {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: 600;
          }
        }
        > .middle {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          > button {
            height: 28px;
            width: 28px;
            background-color: #edf3f5;
            border: 1px solid #d6d9e8;
            border-radius: 2px;
            margin: 5px;
          }
          > input {
            height: 35px;
            width: 50px;
            background: #ffffff;
            border: 1px solid #d6d9e8;
            border-radius: 2px;
            text-align: center;
          }
          > input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          > input[type='number'] {
            -moz-appearance: textfield;
          }
        }
        > .bottom {
          font-style: italic;
          font-size: 10px;
          display: flex;
          justify-content: center;
          color: #6a6a6a;
        }
      }
      .dropdown-select {
        padding: 10px;
        white-space: nowrap;
        > .top-section {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: baseline;
          > .ref {
            font-weight: 600;
            font-size: 24px;
            padding: 10px;
          }
          > .label {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: 600;
          }
        }
        > .middle {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          > .dot-select {
            > select {
              width: 100%;
              height: 37px;
              background: #ffffff;
              border: 1px solid #d6d9e8;
              border-radius: 2px;
            }
          }
        }
        > .bottom {
          font-style: italic;
          font-size: 10px;
          display: flex;
          justify-content: center;
          color: #6a6a6a;
          margin-top: -10px;
        }
      }
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .action-buttons {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-left: 20px;
    .row {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      > button {
        height: 30px;
        width: 30px;
        background: #ffffff;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        > img {
          display: flex;
          width: 100%;
        }
      }
      > button:nth-of-type(1) {
        margin-right: 5px;
      }
      > button:nth-of-type(2) {
        margin-right: 5px;
      }
      > button:hover {
        background-color: rgba(214, 217, 232, 1) !important;
      }
    }
  }
}

.control-7 {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  .left {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 50px;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    .label {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 600;
      padding-bottom: 5px;
    }
    .required {
      color: #d90000;
      font-weight: 400;
      font-size: 7px;
      line-height: 8px;
      font-style: italic;
      margin-left: 5px;
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      .react-datepicker-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 40px;
        > input {
          background: #edf3f5;
          border: 1px solid #d6d9e8;
          border-radius: 7px;
          width: 120px;
          height: 40px;
          padding: 10px;
        }
        > input:disabled {
          background: #dee2ed;
        }
        > input:focus {
          outline: none;
        }
        > svg {
          position: absolute;
          right: 15px;
        }
      }
      .timepicker-wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 80px;
        margin-left: 20px;
        > input {
          background: #edf3f5;
          border: 1px solid #d6d9e8;
          border-radius: 7px;
          width: 120px;
          height: 40px;
          padding: 10px;
        }
        > input:disabled {
          background: #dee2ed;
        }
        > input:focus {
          outline: none;
        }
        > svg {
          position: absolute;
          right: 15px;
        }
      }
      .input {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        > input {
          background: #dee2ed;
          border: 1px solid #d6d9e8;
          border-radius: 7px;
          width: 160px;
          height: 40px;
          text-align: center;
        }
        > input:focus {
          outline: none;
        }
      }
    }
    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 10px;
      .tip {
        font-style: italic;
        font-size: 10px;
        display: flex;
        justify-content: flex-start;
        color: #6a6a6a;
      }
      .last-saved {
        font-style: italic;
        font-size: 10px;
        display: flex;
        justify-content: flex-end;
        min-width: 30%;
        height: 100%;
      }
    }
  }
  .action-buttons {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-left: 20px;
    .row {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      > button {
        height: 30px;
        width: 30px;
        background: #ffffff;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        > img {
          display: flex;
          width: 100%;
        }
      }
      > button:nth-of-type(1) {
        margin-right: 5px;
      }
      > button:nth-of-type(2) {
        margin-right: 5px;
      }
      > button:hover {
        background-color: rgba(214, 217, 232, 1) !important;
      }
    }
  }
}

.control-8 {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    .body {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;
      .dropdown-select {
        padding: 10px;
        white-space: nowrap;
        > .top-section {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: baseline;
          > .ref {
            font-weight: 600;
            font-size: 24px;
            padding: 10px;
          }
          > .label {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: 600;
          }
        }
        > .middle {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          > .dot-select {
            > select {
              width: 100%;
              height: 37px;
              background: #ffffff;
              border: 1px solid #d6d9e8;
              border-radius: 2px;
            }
            > select:disabled {
              background: #dee2ed;
            }
          }
        }
      }
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
  }
  .action-buttons {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-left: 20px;
    .row {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      > button {
        height: 30px;
        width: 30px;
        background: #ffffff;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        > img {
          display: flex;
          width: 100%;
        }
      }
      > button:nth-of-type(1) {
        margin-right: 5px;
      }
      > button:nth-of-type(2) {
        margin-right: 5px;
      }
      > button:hover {
        background-color: rgba(214, 217, 232, 1) !important;
      }
    }
  }
}

.control-9 {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  .left {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 50px;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    .label {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 600;
      padding-bottom: 5px;
    }
    .required {
      color: #d90000;
      font-weight: 400;
      font-size: 7px;
      line-height: 8px;
      font-style: italic;
      margin-left: 5px;
    }
    .body {
      display: flex;
      flex-direction: row;
      width: 100%;
      .remove-button {
        padding-left: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        > button {
          height: 30px;
          width: 30px;
          background: #edf3f5;
          border: 1px solid #d6d9e8;
          border-radius: 7px;
          transition: none !important;
          > img {
            display: flex;
            width: 100%;
          }
        }
      }
      .number-picker {
        padding: 10px;
        white-space: nowrap;
        > .top-section {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: baseline;
          > .ref {
            font-weight: 600;
            font-size: 24px;
            padding: 10px;
          }
          > .label {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: 600;
          }
        }
        > .middle {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          width: 100%;
          > button {
            height: 28px;
            width: 28px;
            background-color: #edf3f5;
            border: 1px solid #d6d9e8;
            border-radius: 2px;
            margin: 5px;
          }
          > input {
            height: 35px;
            width: 50px;
            background: #ffffff;
            border: 1px solid #d6d9e8;
            border-radius: 2px;
            text-align: center;
          }
          > input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          > input[type='number'] {
            -moz-appearance: textfield;
          }
        }
        > .bottom {
          font-style: italic;
          font-size: 10px;
          display: flex;
          justify-content: center;
          color: #6a6a6a;
        }
      }
    }
    .row {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      > input {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: #edf3f5;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        width: 100%;
        height: 40px;
        padding: 10px;
        margin: 10px;
      }
      > input:disabled {
        background: #dee2ed;
      }
      .input {
        width: 100%;
        height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        > input {
          background: #dee2ed;
          border: 1px solid #d6d9e8;
          border-radius: 7px;
          width: 160px;
          height: 40px;
          text-align: center;
        }
        > input:focus {
          outline: none;
        }
      }
    }
    > textarea {
      width: 100%;
      height: 60px;
      background: #edf3f5;
      border: 1px solid #d6d9e8;
      border-radius: 7px;
      font-size: 10px;
      padding: 5px;
      resize: none;
    }
    > textarea:disabled {
      background: #dee2ed;
      border: 1px solid #d6d9e8;
    }
    .info {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      padding-bottom: 10px;
      .tip {
        font-style: italic;
        font-size: 10px;
        display: flex;
        justify-content: flex-start;
        color: #6a6a6a;
      }
      .last-saved {
        font-style: italic;
        font-size: 10px;
        display: flex;
        justify-content: flex-end;
        min-width: 30%;
        height: 100%;
      }
    }
    .add-button {
      display: flex;
      flex-direction: row;
      align-items: center;
      > button {
        height: 30px;
        width: 30px;
        background: #edf3f5;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        transition: none !important;
        > img {
          display: flex;
          width: 100%;
        }
      }
      .tip {
        font-style: italic;
        font-size: 12px;
        color: #6a6a6a;
      }
    }
  }
  .action-buttons {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    padding-left: 20px;
    .row {
      width: 100%;
      height: 40px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      > button {
        height: 30px;
        width: 30px;
        background: #ffffff;
        border: 1px solid #d6d9e8;
        border-radius: 7px;
        > img {
          display: flex;
          width: 100%;
        }
      }
      > button:nth-of-type(1) {
        margin-right: 5px;
      }
      > button:nth-of-type(2) {
        margin-right: 5px;
      }
      > button:hover {
        background-color: rgba(214, 217, 232, 1) !important;
      }
    }
  }
}

.control-10 {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  .left {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 50px;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
}

.control-11 {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  .left {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 50px;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
}

.control-12 {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  .left {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 50px;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
}

.control-13 {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  .left {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 50px;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
}

.control-14 {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  .left {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 50px;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
}

.control-15 {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  .left {
    font-weight: 600;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    min-width: 50px;
  }
  .details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
  }
}
