/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules';

$self-colour: #3b9fc2;

.mission-map-container {
  position: relative;
  &.hidden {
    visibility: hidden;
  }
  &.dark-mode {
    .suggestions {
      a {
        color: white;
        background-color: rgba(11, 23, 26, 0.8);
      }
      a:hover {
        color: white;
        background-color: black;
      }
    }
  }

  .mapboxgl-canvas-container {
    position: relative;
    .mapboxgl-canvas {
      position: absolute;
    }
  }

  &-edit {
    @extend .button-default;
    background-color: transparent;
    position: absolute;
    top: 15px;
    right: 10px;
    z-index: 5;

    &:hover {
      color: rgb(67, 67, 67);
    }
  }

  &-edit-coords {
    position: absolute;
    bottom: 10px;
    left: 10px;

    display: flex;
    flex-direction: column;
    justify-content: center;

    &-row1 {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 40%;
      background-color: rgba($colour-white, 0.749);
      padding: 5px;

      input {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 90%;
        height: 100%;
        border: none;
        margin-left: 5px;
        background-color: transparent;
      }
    }
  }

  --max-buttons: 6;

  .category-toolbar,
  .category-panel {
    @extend .sidebar-animation;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap-reverse;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: white;
    border: none;
    max-height: calc(var(--toolbar-size) * var(--max-buttons));

    & > * {
      &:not(.__react_component_tooltip) {
        @extend .button-animation;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        cursor: pointer !important;

        height: var(--toolbar-size);
        width: var(--toolbar-size);
        margin: 0;
        padding: 0 !important;

        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        background-color: transparent;

        button {
          height: 2.5rem;
          width: 2.5rem;
          border-radius: 50%;
          padding: 0 !important;

          .dot-avatar {
            height: calc(2.5rem - 10px) !important;
            width: calc(2.5rem - 10px) !important;
            .avatar {
              height: calc(2.5rem - 10px) !important;
              width: calc(2.5rem - 10px) !important;
            }
          }
        }
      }

      &.active {
        background: linear-gradient(
          to top,
          $colour-self,
          $colour-interactions-a
        );
        color: $colour-white;
        &:hover {
          opacity: 0.6;
        }
      }
    }
    border-bottom: solid 1px $colour-grey-4;

    &:first-of-type {
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;

      & > * {
        &.active {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
      }
    }

    &:last-of-type {
      border-bottom: none;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;

      & > * {
        &.active {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }
      }
    }
  }

  .category-panel {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  }

  .allies {
    border-radius: 8px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: -0.01em;

    border: none;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);

    .ally {
      @extend .button-animation;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      height: var(--toolbar-size);
      width: var(--toolbar-size);

      cursor: pointer !important;

      text-transform: uppercase;

      .ally-icon {
        height: 2rem;
        width: 2rem;
        margin: 0;

        padding: 0 !important;

        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        .dot-button {
          padding: 0;
          height: 1.5rem;
          width: 1.5rem;
        }
      }

      &:not(:disabled):hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      &:not(:disabled):active {
        background-color: rgba(0, 0, 0, 0.05);
      }

      button {
        background-color: transparent;
        border: none;

        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;

        text-transform: uppercase;

        font-weight: 600;
        font-size: 0.9375rem;
        line-height: 1rem;
        letter-spacing: -0.01em;

        & > * + * {
          margin-top: 2px;
        }
      }

      .ally-icon {
        button {
          font-size: 0.75rem;
          line-height: 1rem;
        }
      }

      &:first-of-type {
        border-bottom: solid 1px $colour-grey-4;
        border-radius: 8px 8px 0 0;
      }

      &:last-of-type {
        border-radius: 0 0 8px 8px;
      }

      &.active {
        background: linear-gradient(
          to top,
          $colour-self,
          $colour-interactions-a
        );
        button {
          color: $colour-white;
        }
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}

.dark-mode {
  .category-toolbar,
  .category-panel {
    background: rgba(11, 23, 26, 0.8);
    color: $colour-white;
    button {
      color: $colour-white;
    }

    border-bottom: solid 1px rgba(255, 255, 255, 0.2);
  }
  .mission-info {
    button:not(:first-of-type) {
      border-left: solid 1px rgba(255, 255, 255, 0.2);
    }
  }
  .menu-button {
    border-left: solid 1px rgba(255, 255, 255, 0.2);
  }
}

.mapboxgl-popup {
  padding: 0;
  min-width: unset;
  max-width: unset !important;
  z-index: 6;

  .poi-tooltip {
    -webkit-user-select: none;
    user-select: none;
    display: flex;
    flex-direction: column;
    & > * + * {
      margin-top: 5px;
    }
    overflow: hidden;

    width: 200px;

    border-radius: 6px;

    position: relative;

    & > img {
      width: 100%;
    }

    .desc {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      & > * + * {
        margin-left: 5px;
      }

      & > div.desc-content {
        display: flex;
        flex-direction: column;
        padding: 10px;

        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1rem;
        letter-spacing: -0.01em;
        text-align: left;

        color: $colour-white;

        span {
          opacity: 0.7;
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: -0.01em;
        }
      }

      .dot-avatar {
        margin: 0 10px 10px 0;
      }
    }
  }

  .symbol-tooltip {
    -webkit-user-select: none;
    user-select: none;
    padding: 5px;
  }

  .adsb-tooltip {
    -webkit-user-select: none;
    user-select: none;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 6px;

    width: 100px;
    & > img {
      width: 100%;
      background-color: $colour-white;
      padding: 5px;
    }
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 5px;
    }

    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 14px;
    text-align: right;
    letter-spacing: -0.0719123px;

    color: $colour-white;
  }
  .ais-tooltip {
    -webkit-user-select: none;
    user-select: none;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 6px;

    width: 200px;
    & > img {
      width: 100%;
      background-color: $colour-white;
      padding: 5px;
    }
    & > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding: 5px;
    }

    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 14px;
    text-align: right;
    letter-spacing: -0.0719123px;

    color: $colour-white;
  }

  .covid-tooltip,
  .flight-tooltip,
  .iot-tooltip,
  .fire-tooltip {
    -webkit-user-select: none;
    user-select: none;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    & > * + * {
      margin-top: 5px;
    }
    padding: 5px;

    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 14px;
    text-align: right;
    letter-spacing: -0.0719123px;

    color: $colour-white;
  }

  .fire-tooltip {
    font-weight: 400;
    .fire-html {
      text-align: left;
    }
  }

  .iot-tooltip {
    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      & > * + * {
        margin-left: 5px;
      }
    }
  }

  .weather-tooltip {
    -webkit-user-select: none;
    user-select: none;

    font-style: normal;
    font-size: 1rem;
    letter-spacing: -0.0719123px;
    //vertical-align: bottom;
    color: $colour-white;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 5px 10px 5px 10px;
      white-space: nowrap;
      flex-wrap: nowrap;
      align-items: center;
      vertical-align: bottom;
    }

    .header {
      background: rgba(80, 83, 102, 0.6);
      text-align: center;
    }

    .icon {
      height: 20px;
      width: 20px;
    }

    .summary {
      justify-content: flex-start;
      align-items: center;
      text-align: center;
    }

    .summary-icon {
      height: 20px;
      width: 20px;
      color: rgba(255, 255, 255, 0.4);
    }
  }

  .search-tooltip {
    -webkit-user-select: none;
    user-select: none;

    font-style: normal;
    font-size: 1rem;
    letter-spacing: -0.0719123px;
    //vertical-align: bottom;
    color: $colour-white;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 5px 10px 5px 10px;
      white-space: nowrap;
      flex-wrap: nowrap;
      align-items: center;
      vertical-align: bottom;
    }
  }

  .user-tooltip {
    -webkit-user-select: none;
    user-select: none;

    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    letter-spacing: -0.0719123px;
    vertical-align: bottom;
    color: $colour-white;

    .row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: 5px;
      white-space: nowrap;
      flex-wrap: nowrap;
    }

    .unit-disconnected {
      background: rgba(80, 83, 102, 0.6);
    }

    .disconnected-icon {
      height: 20px;
      width: 20px;
      img {
        height: '20px';
        width: '20px';
      }
    }

    .disconnected-text {
      color: 'white';
    }

    .user-icon {
      height: 20px;
      width: 20px;
      padding: 2px;
      margin-bottom: 2px;
    }

    .role-icon {
      height: 20px;
      width: 20px;
    }

    .role-name {
      font-weight: normal;
      color: lightgray;
      font-size: 0.75rem !important;
    }
  }

  .cluster-tooltip {
    .row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      padding: 5px;
      white-space: nowrap;
      flex-wrap: nowrap;
    }

    .user-icon {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .user-name {
      font-weight: bold;
    }

    .image-icon {
      border: solid white 2px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
      display: flex;
      img {
        border-radius: 50%;
        width: 20px;
        height: 20px;
      }
    }

    .image-name {
      font-weight: bold;
    }

    .waypoint-icon {
      background: linear-gradient(
        43.41deg,
        rgb(59, 159, 194) 0%,
        rgb(44, 119, 146) 50%
      );
      border: solid white 2px;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
      display: flex;
    }

    .waypoint-name {
      font-weight: bold;
    }

    .more-icon {
      border-radius: 50%;
      width: 20px;
      height: 20px;
      align-items: center;
      justify-content: center;
      display: flex;
    }
  }

  .mission-tooltip {
    -webkit-user-select: none;
    user-select: none;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    & > * + * {
      margin-top: 5px;
    }

    font-style: normal;
    font-size: 0.75rem;
    line-height: 14px;
    text-align: right;
    letter-spacing: -0.0719123px;

    overflow: hidden;

    color: $colour-white;

    img {
      border-radius: 3px 3px 0 0;
    }

    & > div {
      padding: 5px;
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      width: 100%;

      & > div {
        display: flex;
        flex-direction: column;
        font-weight: 600;

        justify-content: flex-start;
        align-items: flex-start;

        text-transform: uppercase;

        & > * + * {
          margin-top: 4px;
        }

        span {
          text-align: left;
          font-weight: 400;
          text-transform: none;
        }
      }
    }
  }
}

@keyframes pulse {
  from {
    transform: scale(0.5, 0.5);
    opacity: 0.3;
  }
  to {
    transform: scale(2.5, 2.5);
    opacity: 0;
  }
}

$grow: 10px;
$halo: 24px;
$border: 3px;

$mission-marker: 48px;
$unit-marker: 30px;
$unit-cluster-marker: 36px;
$poi-marker: 36px;
$cluster-marker: 60px;

.map-is-being-edited {
  .html-marker {
    pointer-events: none;
  }
}

.html-marker {
  transition: width 0.2s ease-in-out, height 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  & * {
    transition: width 0.2s ease-in-out, height 0.2s ease-in-out,
      opacity 0.2s ease-in-out, transform 0.5s ease-in-out;
  }
  visibility: hidden;

  .heading {
    position: absolute;
    width: $unit-marker + $halo + $grow;
    height: $unit-marker + $halo + $grow;
    border-radius: 50%;
    transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
  }

  .halo {
    border-radius: 50%;

    width: $unit-marker + $halo;
    height: $unit-marker + $halo;
    position: absolute;
    opacity: 0.2;

    &.accuracy {
      border: solid 4px $colour-interactions-a;
      transition: width 0.2s ease-in-out, height 0.2s ease-in-out;
    }

    &:not(.first) {
      opacity: 0;
    }
  }

  &.animated {
    .halo {
      opacity: 0;
      width: 24px;
      height: 24px;
      animation: pulse 3s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
    }
  }
}

.mission-marker {
  display: flex;
  border: none;
  border-radius: 50%;
  padding: 0;

  -webkit-user-select: none;
  user-select: none;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: $mission-marker + $halo;
  height: $mission-marker + $halo;

  div {
    background: rgba(44, 118, 145, 0.1);

    width: $mission-marker + $halo;
    height: $mission-marker + $halo;

    display: flex;
    border-radius: 50%;
    padding: 0;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    div {
      cursor: pointer;

      width: $mission-marker;
      height: $mission-marker;
      border: $border solid #ffffff;

      font-style: normal;
      font-weight: 600;
      font-size: 1.375rem;
      line-height: 28px;
      /* or 127% */
      text-align: center;
      letter-spacing: -0.04em;

      /* White */

      /* Shadow - Node */

      box-shadow: 0px 4px 8px rgba(0, 21, 26, 0.5);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      color: $colour-white;
      background-blend-mode: soft-light, normal;
    }
  }

  &:hover {
    div {
      div {
        width: $mission-marker + $grow;
        height: $mission-marker + $grow;
      }
    }
  }
}

.unit-marker {
  display: flex;
  border: none;
  border-radius: 50%;
  padding: 0;

  -webkit-user-select: none;
  user-select: none;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: $unit-marker + $halo;
  height: $unit-marker + $halo;

  .halo {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(16, 16, 16, 0.2) 0%,
      rgba(16, 16, 16, 0.2) 100%
    );
  }

  div.location-ring {
    background: rgba(86, 184, 219, 0.2);
    border-radius: 50%;
    position: absolute;
    border: solid 1px rgba(86, 184, 219, 0.6);
  }

  div.disconnected-overlay {
    background: rgba(0, 0, 0, 0.1) !important;
    border-radius: 50%;
    position: absolute;
    height: 100%;
    width: 100%;
  }

  div.inner {
    cursor: pointer;
    position: absolute;

    width: $unit-marker;
    height: $unit-marker;

    border-radius: 50%;
    border: solid $border white;

    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 28px;
    /* or 127% */
    text-align: center;
    letter-spacing: -0.04em;

    /* White */
    color: #ffffff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 21, 26, 0.5);

    color: $colour-white;
    background-blend-mode: soft-light, normal;

    &:hover {
      width: $unit-marker + $grow;
      height: $unit-marker + $grow;
      div.overlay {
        background: rgba(255, 255, 255, 0.2) !important;
        border-radius: 50%;
        position: absolute;
      }
      .role-diamond {
        left: 15px;
        bottom: 15px;
      }
      .disconnected-icon {
        right: 15px;
        bottom: 15px;
      }
    }

    .role-diamond {
      transition: left 0.2s ease-in-out, bottom 0.2s ease-in-out;
      z-index: 10;
      position: absolute;
      left: 5px;
      bottom: 5px;
      min-height: $unit-marker;
      min-width: $unit-marker;
    }

    .disconnected-icon {
      transition: right 0.2s ease-in-out, bottom 0.2s ease-in-out;
      z-index: 10;
      position: absolute;
      right: 5px;
      bottom: 5px;
      min-height: $unit-marker;
      min-width: $unit-marker;
    }
  }

  &.selected {
    div.outer-ring {
      position: absolute;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='100' ry='100' stroke='%2356B8DBFF' stroke-width='6' stroke-dasharray='39%25%2c 39%25' stroke-dashoffset='9' stroke-linecap='square'/%3e%3c/svg%3e");
      border-radius: 100px;
      -webkit-animation: spin 10s linear infinite;
      -moz-animation: spin 10s linear infinite;
      animation: spin 10s linear infinite;
      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }
    div.location-ring {
      background: rgba(86, 184, 219, 0.5);
      border-radius: 50%;
      position: absolute;
    }
    div.inner {
      border: solid 4px white;
      width: $unit-marker;
      height: $unit-marker;
      box-shadow: none;
      .role-diamond {
        left: 5px;
        bottom: 5px;
      }
      .disconnected-icon {
        right: 5px;
        bottom: 5px;
      }
    }
  }

  &.cluster {
    width: $unit-cluster-marker + $halo;
    height: $unit-cluster-marker + $halo;

    div.inner {
      width: $unit-cluster-marker;
      height: $unit-cluster-marker;

      &:hover {
        width: $unit-cluster-marker + $grow;
        height: $unit-cluster-marker + $grow;
      }
    }
  }

  &.self {
    div.inner {
      background: $gradient-interactions !important;
      background-blend-mode: unset;
    }
  }

  .user-icon {
    width: 105%;
    height: 105%;
    border-radius: 50%;
  }

  .user-callsign {
    width: $unit-marker;
    height: $unit-marker;
    color: #ffffff;
    font-size: 10pt;
    position: absolute;
  }

  .critical-icon {
    display: none;
    width: 100%;
    height: 100%;
  }

  &.critical {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(194, 68, 90, 0.5) 0%,
      rgba($colour-negative-status-a, 0.5) 100%
    );
    div.inner {
      background: $colour-negative-status-a !important;
      background-blend-mode: unset;
    }
    .critical-icon {
      display: block;
    }
  }

  &.hidden {
    opacity: 0.3 !important;
  }
}

.self-marker {
  display: flex;
  border: none;
  border-radius: 50%;
  padding: 0;

  -webkit-user-select: none;
  user-select: none;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: $unit-marker + $halo;
  height: $unit-marker + $halo;

  .halo {
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(16, 16, 16, 0.2) 0%,
      rgba(16, 16, 16, 0.2) 100%
    );
  }

  div.inner {
    cursor: pointer;
    position: absolute;
    background: linear-gradient(135deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%),
      var(--colour-interactions-a);

    width: $unit-marker;
    height: $unit-marker;

    border-radius: 50%;
    border: solid $border white;

    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 28px;
    /* or 127% */
    text-align: center;
    letter-spacing: -0.04em;

    /* White */
    color: #ffffff;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    svg {
      width: 50%;
      height: 55%;
    }

    box-shadow: 0px 4px 8px rgba(0, 21, 26, 0.5);

    color: $colour-white;
    background-blend-mode: soft-light, normal;
  }

  &.selected {
    div.inner {
      border: solid 3px white;
      svg {
        z-index: 5;
        path {
          fill: $self-colour;
        }
      }
      &:before {
        content: '';
        position: absolute;
        height: $unit-marker - 8px;
        width: $unit-marker - 8px;
        border-radius: 50%;
        background-color: $colour-white;
        z-index: 4;
      }
    }
  }

  &:hover {
    div.inner {
      width: $unit-marker + $grow;
      height: $unit-marker + $grow;
    }
    &.selected {
      div.inner {
        &:before {
          height: $unit-marker + $grow - 8px;
          width: $unit-marker + $grow - 8px;
        }
      }
    }
  }
}

.image-marker {
  height: $poi-marker;
  width: $poi-marker;

  border-radius: 50%;

  box-shadow: 0px 4px 8px rgba(0, 21, 26, 0.5);

  & > div.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: $border solid rgba(255, 255, 255, 1);
    border-radius: 0px 50% 50% 50%;
    height: $poi-marker;
    width: $poi-marker;
    transform: rotate(225deg);

    & > .inner {
      height: $poi-marker - $grow;
      width: $poi-marker - $grow;
      transform: rotate(-225deg);

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;
      border: solid 1px transparent;

      & > svg {
        color: var(--colour-white) !important;
      }

      & > img {
        height: $poi-marker / 2;
      }
    }
  }

  &:hover {
    width: $poi-marker + $grow;
    height: $poi-marker + $grow;
    & > div.icon {
      height: $poi-marker + $grow;
      width: $poi-marker + $grow;

      & > .inner {
        height: $poi-marker;
        width: $poi-marker;
      }
    }
  }

  -webkit-user-select: none;
  user-select: none;

  vertical-align: middle;
  text-align: center;

  &.selected {
    & > div.icon {
      background: var(--colour-white) !important;
      & > .inner {
        border: solid 1px var(--colour-interactions-a);

        & > svg {
          color: var(--colour-interactions-a) !important;
        }
      }
    }
  }

  .count {
    position: absolute;
    top: -$poi-marker * 0.35;
    right: -$poi-marker * 0.35;
    background-color: $colour-grey-1;
    color: $colour-white;
    width: $poi-marker * 0.7;
    height: $poi-marker * 0.7;
    border-radius: 50%;
    border: solid 3px $colour-white;

    font-size: 0.75rem;
    line-height: 1rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.01em;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.waypoint-marker {
  height: $poi-marker;
  width: $poi-marker;

  border-radius: 50%;

  box-shadow: 0px 4px 8px rgba(0, 21, 26, 0.5);

  & > div.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid rgba(255, 255, 255, 1);
    border-radius: 0px 50% 50% 50%;
    height: $poi-marker;
    width: $poi-marker;
    transform: rotate(225deg);

    & > .inner {
      transform: rotate(-225deg);
      height: $poi-marker - $grow;
      width: $poi-marker - $grow;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;
      border: solid 1px transparent;

      & > svg {
        color: var(--colour-white) !important;
      }
      & > span {
        font-weight: 600;
        letter-spacing: -0.04em;
        color: var(--colour-white) !important;
        font-size: 1rem;
        line-height: 1rem;
      }
    }
  }

  -webkit-user-select: none;
  user-select: none;

  vertical-align: middle;
  text-align: center;

  &:hover {
    height: $poi-marker + $grow;
    width: $poi-marker + $grow;
    & > div.icon {
      height: $poi-marker + $grow;
      width: $poi-marker + $grow;

      & > .inner {
        height: $poi-marker;
        width: $poi-marker;
      }
    }
  }

  &.selected {
    & > div.icon {
      background: var(--colour-white) !important;
      & > .inner {
        border: solid 1px var(--colour-interactions-a);

        & > svg {
          color: var(--colour-interactions-a) !important;
        }
        & > span {
          color: var(--colour-interactions-a) !important;
        }
      }
    }
  }

  .count {
    position: absolute;
    top: -$poi-marker * 0.35;
    right: -$poi-marker * 0.35;
    background-color: $colour-grey-1;
    color: $colour-white;
    width: $poi-marker * 0.7;
    height: $poi-marker * 0.7;
    border-radius: 50%;
    border: solid 3px $colour-white;

    font-size: 0.75rem;
    line-height: 1rem;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -0.01em;

    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.weather-marker {
  height: $poi-marker;
  width: $poi-marker;

  border-radius: 50%;

  box-shadow: 0px 4px 8px rgba(0, 21, 26, 0.5);

  & > div.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 3px solid rgba(255, 255, 255, 1);
    border-radius: 0px 50% 50% 50%;
    height: $poi-marker;
    width: $poi-marker;
    transform: rotate(225deg);
    background-color: rgba(255, 255, 255, 1);

    & > .inner {
      background-image: url('https://bluedotstatic.blob.core.windows.net/integration/bom.png');
      background-size: cover;
      transform: rotate(-225deg);
      height: $poi-marker - $grow;
      width: $poi-marker - $grow;

      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;
      border: solid 1px transparent;

      & > svg {
        color: var(--colour-white) !important;
      }
      & > span {
        font-weight: 600;
        letter-spacing: -0.04em;
        color: var(--colour-white) !important;
        font-size: 1rem;
        line-height: 1rem;
      }
    }
  }

  -webkit-user-select: none;
  user-select: none;

  vertical-align: middle;
  text-align: center;

  &:hover {
    height: $poi-marker + $grow;
    width: $poi-marker + $grow;
    & > div.icon {
      height: $poi-marker + $grow;
      width: $poi-marker + $grow;

      & > .inner {
        height: $poi-marker;
        width: $poi-marker;
      }
    }
  }

  &.selected {
    & > div.icon {
      background: var(--colour-white) !important;
      & > .inner {
        border: solid 1px var(--colour-interactions-a);

        & > svg {
          color: var(--colour-interactions-a) !important;
        }
        & > span {
          color: var(--colour-interactions-a) !important;
        }
      }
    }
  }
}

.search-marker {
  height: $poi-marker;
  width: $poi-marker;

  border-radius: 50%;

  box-shadow: 0px 4px 8px rgba(0, 21, 26, 0.5);

  & > div.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid rgba(186, 41, 24, 1);
    border-radius: 0px 50% 50% 50%;
    height: $poi-marker;
    width: $poi-marker;
    transform: rotate(225deg);
    background: linear-gradient(
      325deg,
      rgba(255, 160, 91, 1),
      rgba(255, 61, 96, 1)
    );

    & > .inner {
      transform: rotate(-225deg);
      height: 15px;
      width: 15px;
      background: rgba(186, 41, 24, 1);
      display: flex;
      align-items: center;
      justify-content: center;

      border-radius: 50%;
    }
  }

  -webkit-user-select: none;
  user-select: none;

  vertical-align: middle;
  text-align: center;
}

.cluster-marker {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  max-width: $cluster-marker;
  justify-content: center;
  align-items: center;
  z-index: 0;

  .marker-wrapper {
    height: $cluster-marker;
    width: $cluster-marker;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -20px;

    &[data-count='0'] {
      display: none;
    }
  }

  .inner-marker {
    height: $cluster-marker;
    width: $cluster-marker;

    border-radius: 50%;

    box-shadow: 0px 4px 8px rgba(0, 21, 26, 0.5);

    & > div.icon {
      display: flex;
      align-items: center;
      justify-content: center;
      border: $border solid rgba(255, 255, 255, 1);
      border-radius: 50%;
      height: $cluster-marker;
      width: $cluster-marker;

      & > .inner {
        height: $cluster-marker - $grow;
        width: $cluster-marker - $grow;

        display: flex;
        align-items: center;
        justify-content: center;

        border-radius: 50%;
        border: solid 1px transparent;
        font-weight: bold;
        flex-direction: column;
        & > svg {
          color: var(--colour-grey-1) !important;
          padding-bottom: 3px;
        }

        & > img {
          height: $poi-marker / 2;
        }
      }
    }
    position: relative;

    &::after {
      background-color: transparent;
      color: $colour-grey-1;
      width: $poi-marker * 0.7;
      height: $poi-marker * 0.7;
      font-size: 0.75rem;
      line-height: 1rem;
      font-style: normal;
      font-weight: 600;
      letter-spacing: -0.01em;
    }

    &:hover {
      z-index: 1;
      height: $cluster-marker + $grow;
      width: $cluster-marker + $grow;

      & > div.icon {
        height: $cluster-marker + $grow;
        width: $cluster-marker + $grow;
        border: solid rgba(59, 159, 194, 1) 3px;

        & > .inner {
          height: $cluster-marker;
          width: $cluster-marker;
          color: rgba(59, 159, 194, 1);

          & > svg {
            color: rgba(59, 159, 194, 1) !important;
            margin-right: 5px;
          }
        }
      }
    }
  }

  // &:hover {
  //   & > div.icon {
  //     height: $poi-marker + $grow;
  //     width: $poi-marker + $grow;

  //     & > .inner {
  //       height: $poi-marker;
  //       width: $poi-marker;
  //     }
  //   }
  // }

  -webkit-user-select: none;
  user-select: none;

  vertical-align: middle;
  text-align: center;

  &.selected {
    .inner-marker {
      & > div.icon {
        background: var(--colour-white) !important;
        & > .inner {
          border: solid 1px var(--colour-interactions-a);

          & > svg {
            color: var(--colour-interactions-a) !important;
          }
        }
      }
    }
  }
}

.slider {
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: #d3d3d3;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 25px;
  height: 25px;
  background: #04aa6d;
  cursor: pointer;
}

@keyframes teams-editor-in {
  from {
    left: -100vw;
  }
}

@keyframes teams-editor-out {
  to {
    left: -100vw;
  }
}

.teams-editor {
  animation: teams-editor-in 0.5s ease-in-out;

  --team-card-width: 25rem;
  --team-card-height: 25rem;

  z-index: 7;
  background-color: $colour-grey-1;
  opacity: 0.9;
  position: absolute;
  top: 0;
  width: calc(100vw - #{$sidebar-width} - #{$nav-width});
  height: vh(100);
  box-shadow: 8px 0 48px 0px rgba(0, 0, 0, 0.3);
  left: $nav-width;

  padding: calc(#{$map-padding} + #{$toolbar-size} + #{$map-padding}) #{$map-padding}
    #{$map-padding} #{$map-padding};

  &.call {
    top: calc(#{$banner-height} + #{$map-padding});
  }

  &.close-editor {
    animation: teams-editor-out 0.5s ease-in-out;
  }

  & > .remove {
    -webkit-user-select: none;
    user-select: none;
    border: dashed rgba($colour-grey-3, 0.8) 3px;
    border-radius: 8px;
    padding: 1rem;
    color: rgba($colour-grey-3, 0.8);
    margin: 0 20px 20px 20px;
    position: absolute;

    top: $map-padding;
    right: $map-padding;

    display: flex;
    & > * + * {
      margin-left: 10px;
    }
    align-items: center;

    &.highlight {
      border: dashed rgba($colour-white, 1) 3px;
      color: rgba($colour-white, 1);
    }
    &.dim {
      border: dashed rgba($colour-grey-3, 0.1) 3px;
      color: rgba($colour-grey-3, 0.1);
    }
  }

  .ScrollbarsCustom-Content {
    display: flex;
    margin-top: -8px;
    margin-left: -8px;
    & > * {
      margin-top: 8px;
      margin-left: 8px;
    }

    flex-wrap: wrap;
  }

  .team {
    width: var(--team-card-width);
    max-width: var(--team-card-width);
    min-height: var(--team-card-height);
    color: white;
    flex-shrink: 0;
    flex-grow: 0;

    background: rgba(0, 0, 0, 0.3);
    border-radius: 24px;
    padding: 24px;

    & > * + * {
      margin-left: 20px;
    }
    display: flex;
    flex-direction: column;

    &.new {
      cursor: pointer;

      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }

    &.unassigned {
      background: transparent;
      border: dashed rgba(255, 255, 255, 0.3) 3px;
    }

    .heading {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        display: flex;
        align-items: center;
        max-width: calc(100% - 48px);

        & > * + * {
          margin-left: 24px;
        }

        & > span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .dot-button {
        background-color: transparent;
        color: $colour-white;
        border: none;
        font-size: 1.5rem;
      }
    }

    & > .body {
      display: flex;
      flex-direction: row;

      margin-left: -1rem;

      & > * {
        margin-top: 1rem;
        margin-left: 1rem;
      }

      flex-wrap: wrap;

      &.new {
        height: 100%;
        font-size: 1.5em;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        & > * + * {
          margin-top: 24px;
        }
      }

      & > .assigned-user,
      & > .unit-panel-container,
      & > .clone {
        width: calc(12.5rem - 34px);
        max-width: calc(12.5rem - 34px);
      }
    }
  }

  & > .info {
    position: absolute;
    bottom: 30px;
    right: 30px;
    color: $colour-white;
    padding: 6px;
    text-align: left;
    width: 40px;
    font-weight: 600;
    cursor: pointer;
  }
}

.integrations-count {
  background: $colour-grey-2;
  color: $colour-white;
  border-radius: 50px;
  width: 35px;
  height: 35px;
  margin: 0 10px;
  cursor: pointer;
  span {
    margin: 4px;
    border: solid 2px $colour-white;
    border-radius: 50px;
    width: 27px;
    height: 27px;
    display: inline-block;
    line-height: 22px;
    font-weight: 600;
    text-align: center;
  }
}
