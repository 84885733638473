/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty 
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any 
 * licence may have from the copyright owner to use the Software, you must not copy, 
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to 
 * derive the source code of or modify the Software, nor create any derivative works of 
 * the Software.
 */

@import '../../modules';

// Define grid areas
.edit-styles {
  overflow-x: hidden;
  &-container {
    overflow-x: hidden;
    width: 100%;
    height: vh(100);

    display: grid;
    grid-template-columns: $nav-width 1fr;
    grid-template-areas: 'sidebar org-middle';

    min-width: 780px;
  }

  &-middle {
    grid-area: org-middle;
    display: grid;
    grid-template-rows: 90px 1fr;
    grid-template-areas: 'topbar' 'org-content';
    overflow-x: hidden;
  }
}

.has-notification {
  .edit-styles {
    &-container {
      grid-template-rows: vh(100, -#{$banner-height});
    }
  }
}

.edit-styles-content {
  overflow-y: hidden;
  grid-area: org-content;
  // padding-left: 70px;
  // padding-right: 70px;
  // padding-top: 50px;
  padding-bottom: 100px;

  &-row2 {
    overflow-y: auto;
    // Hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
    height: 100%;
  }

  &-count-title {
    font-size: 1.25rem;
    font-weight: 500;
    color: $colour-white;
    letter-spacing: 1px;
    margin-left: 10px;
  }

  &-row1 {
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
  }

  button.danger {
    height: 40px;
    &:hover {
      background-color: $colour-negative-status-c;
    }
  }
}

#edit-styles-edit-panel {
  div {
    flex-grow: 1;
  }
  div.dot-select select {
    margin-top: 0 !important;
  }

  .frsb {
    display: flex;
    align-items: center;
  }
}
