/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty 
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any 
 * licence may have from the copyright owner to use the Software, you must not copy, 
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to 
 * derive the source code of or modify the Software, nor create any derivative works of 
 * the Software.
 */

@import '../../modules';

/*

 Set up to allow different border radius' throughout the application

*/

.mapboxgl-canary {
  border-radius: inherit;
}

.mapboxgl-canvas-container {
  border-radius: inherit;
}

.map-container {
  border-radius: inherit;
}

// Mapbox map
.mapboxgl-canvas-container .mapboxgl-canvas {
  // border-top-right-radius: 15px;
  // border-top-left-radius: 15px;
  // border-radius: 15px;
  border-radius: inherit;
  outline: none;
}

#3035a44a6-d4e9-46cd-ad05-b3cdec7c44d3
  > div.mapboxgl-control-container
  > div.mapboxgl-ctrl-bottom-left
  > div
  > a {
  z-index: 0;
}

#3035a44a6-d4e9-46cd-ad05-b3cdec7c44d3
  .mapboxgl-control-container
  .mapboxgl-ctrl-bottom-left {
  z-index: 0;
}

.mapboxgl-ctrl-bottom-left {
  z-index: 0;
}

.card {
  background-color: $colour-black;
  .content {
    background-color: $colour-white;
  }
}
