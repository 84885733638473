/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules';

.call {
  height: calc((var(--vh, 1vh) * 100) - #{$banner-height} - var(--map-padding));
}

.mission-info-container {
  position: absolute;
  top: $map-padding;
  left: var(--map-padding);

  display: flex;
  & > * + * {
    margin-left: 13px;
  }
  &.dark-mode {
    .left {
      .mission-title {
        transition: all 0.2s ease-in-out;
        color: $colour-white;
      }
      .map-mission-button {
        color: $colour-white;
      }
    }
  }
}

.integration-slider-container {
  position: absolute;
  top: 110px;
  left: calc(#{$nav-width} + var(--map-padding));

  display: flex;
  & > * + * {
    margin-left: 13px;
  }
}

.integration-slider {
  @extend .sidebar-animation;
  z-index: 6;
  -webkit-user-select: none;
  user-select: none;

  display: flex;
  flex-direction: column !important;

  /*align-items: center;*/
  justify-content: space-evenly;

  background: $colour-white;
  color: $colour-grey-1;
  border-radius: 8px;

  padding: 1rem;

  box-shadow: 0px 4px 12px rgba(0, 84, 101, 0.24);

  width: 348px;

  h5 {
    margin-bottom: 0.5rem;
  }

  .integration-slider-title {
    font-size: 1.25rem;
    font-weight: 500;
    margin-bottom: 0.625rem;
  }

  .session {
    margin-top: 20px;
  }

  .parameter {
    color: #777;
    display: flex;
    flex-direction: row;
  }

  .parameter div {
    width: 120px;
  }

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 12px;
    width: 100%;
  }

  .colors {
    background: linear-gradient(
      to right,
      #732c2b,
      #732c2b,
      #843931,
      #954636,
      #976b48,
      #9a8b6b,
      #6e8c7e,
      #6d828d,
      #5b7384,
      #4b637b,
      #3a5078,
      #393a70,
      #1f1f3c
    );
    margin-bottom: 5px;
    border-radius: 8px;
  }
}

.mission-info {
  @extend .sidebar-animation;
  z-index: 6;
  -webkit-user-select: none;
  user-select: none;

  display: flex;
  flex-direction: row;
  /*align-items: center;*/
  justify-content: space-evenly;

  background: $colour-white;
  color: $colour-grey-1;
  border-radius: 8px;
  height: var(--toolbar-size);

  box-shadow: 0px 4px 12px rgba(0, 84, 101, 0.24);

  .left {
    img {
      width: 16px;
      height: 16px;
      margin-left: 20px;
      margin-right: 10px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    font-style: normal;
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 28px;
    /* or 127% */
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;

    & > button,
    & > .menu-button > button {
      background-color: transparent;
      border: none;
      font-size: 1rem;
      color: $colour-grey-1;
    }

    & > button:first-of-type {
      cursor: default;
    }

    .mission-title {
      max-width: vw(20);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-style: normal;
      font-weight: 500;
      font-size: 1.25em;
      line-height: 1;
      transition: all 0.2s ease-in-out;
      margin-right: 10px;
    }
    .map-mission-button {
      height: 100%;
      width: 52.5px;
      border-left: solid 1px rgba(0, 84, 101, 0.24);
      border-right: solid 1px rgba(0, 84, 101, 0.24);
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      &.active {
        background: linear-gradient(
          to top,
          $colour-self,
          $colour-interactions-a
        );
        color: $colour-white;
      }
    }
  }

  .right {
    border-left: solid 1px $colour-grey-2;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;

    padding: 5px 10px;

    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 24px;
    /* identical to box height, or 150% */
    display: flex;
    align-items: center;

    text-transform: uppercase;

    & > * + * {
      margin-left: 10px;
    }

    span.multi {
      text-transform: none;
    }
  }
}

.has-notification {
  .mission-info-container {
    top: $map-padding + $banner-height;
  }
  .mission-info {
    top: $banner-height;
  }
}

.dark-mode {
  .mission-info {
    background: rgba(11, 23, 26, 0.8);
    color: $colour-white;

    .left {
      & > .menu-button > button {
        color: $colour-white;
      }
      button {
        color: $colour-white;
      }
      img {
        color: $colour-white;
      }
    }
    .modal-content {
      .box,
      label {
        background-color: rgba(11, 23, 26, 0.8);
        color: $colour-white;
      }
      textarea::placeholder {
        color: rgba(11, 23, 26, 0.8);
      }
      .exit {
        color: $colour-white;
      }
    }
  }
}

.toolbar {
  .mission-info {
    background: rgba(0, 0, 0, 0.75);
    color: $colour-white;
  }
}
