/**
 * © Copyright 2023. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */
@import '../../modules';

.form {
  padding-top: 20px;
  padding-bottom: 20px;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
    background-color: rgba(237, 243, 245, 1);
    border: solid 1px rgba(214, 217, 232, 1);
    border-radius: 12px;
    padding-left: 5px;
    padding-right: 5px;
    align-items: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
    margin-left: 20px;
    margin-right: 20px;
    &.important {
      background-color: #c2445a;
      .avatar {
        background-color: rgba(237, 243, 245, 1) !important;
        color: #c2445a !important;
      }
      .form-title {
        color: white;
      }
      .last-updated {
        color: white;
      }
      .chevron {
        color: white;
      }
    }
    .left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      height: 40px;
      .avatar {
        background-color: #307f9b;
        width: 40px;
        border-radius: 50%;
        color: white;
        font-weight: 600;
        font-size: 18px;
        line-height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .form-title {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        line-height: 16px;
        margin-left: 10px;
      }
    }
    .right {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      height: 50px;
      .last-updated {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 400;
        font-size: 12px;
      }
      .chevron {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 20px;
        padding: 20px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  .form-content {
    padding-left: 20px;
    padding-right: 20px;
    .panel {
      border: solid 1px rgba(214, 217, 232, 1);
      border-radius: 12px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      margin-bottom: 10px;
      .panel-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 40px;
        background-color: rgba(237, 243, 245, 1);
        border: solid 1px rgba(214, 217, 232, 1);
        border-radius: 12px;
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 20px;
        align-items: center;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        .left {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          height: 50px;
          .panel-title {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 600;
            font-size: 14px;
            margin-left: 10px;
          }
          .panel-tip {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            font-size: 10px;
            margin-left: 10px;
            font-style: italic;
            color: #6a6a6a;
          }
        }
        .right {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          height: 50px;
          .last-updated {
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 400;
            font-size: 12px;
          }
          .chevron {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            padding: 20px;
            margin-left: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-left: 20px;
    padding-right: 20px;
    .button-panel {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 100px;
      background-color: rgba(255, 255, 255, 1);
      border: solid 1px rgba(214, 217, 232, 1);
      border-radius: 12px;
      padding: 50px;
      align-items: center;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      > button {
        height: 50px !important;
      }
      > button:nth-of-type(1) {
        margin-right: 20px;
      }
      > button:nth-of-type(2) {
        margin-right: 20px;
      }
    }
  }
}
