/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty 
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any 
 * licence may have from the copyright owner to use the Software, you must not copy, 
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to 
 * derive the source code of or modify the Software, nor create any derivative works of 
 * the Software.
 */

@import '../../modules';

.upload-crop {
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: solid 1px #c7cce5;
  padding-bottom: 24px;

  h4 {
    font-family: $font-family;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.04em;
    text-align: left;
    color: $colour-grey-1;
  }

  label {
    cursor: pointer;
    text-decoration: underline;
    color: #3990b6;

    input {
      display: none;
    }
  }

  figure.image {
    width: 72px !important;
    height: 72px !important;

    .avatar {
      width: 72px;
      height: 72px;
    }
  }

  .current-photo {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dot-button.link {
    color: $colour-negative-status-a;
  }
}
