/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules';

.page {
  display: grid;
  grid-template-columns: $nav-width vw(100, -#{$nav-width});
  grid-template-rows: vh(100);
  grid-template-areas: 'sidebar create-mission-content';

  width: vw(100);
  height: 100%;

  background: $colour-white;

  .content {
    grid-area: create-mission-content;
    display: flex;
    flex-direction: column;

    .multi-select {
      max-width: 85%;
      margin-left: 7.5%;
      margin-bottom: 2.5%;
      box-sizing: border-box;
      height: 88px;
      border-radius: 12px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      background: linear-gradient(75deg, #3b9fc2 0%, #2c7690 52.85%);
      border: 1px solid #2c7690;
      color: white;
      .col-1 {
        min-width: 75px;
        height: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 25px;
        display: flex;
        align-items: center;
        &:hover {
          cursor: default;
        }
        .checkmark {
          border: 1px solid $colour-grey-3;
          &:hover {
            cursor: default;
          }
        }
      }
      .col-2 {
        min-width: 100px;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 5px;
        justify-content: center;
        overflow-x: hidden;
        font-weight: 600;
        cursor: default;
        .inner {
          min-width: 200px;
          height: 50px;
          display: flex;
          align-items: center;
          color: $colour-interactions-a;
          justify-content: center;
          color: white;
          flex-wrap: nowrap;
          border-radius: 10px;
          &:hover {
            background: white;
            color: #2c7690;
            cursor: pointer;
          }
        }
      }
    }

    .container {
      padding-top: 150px;
      padding-bottom: 150px;
      max-width: 75%;
      margin: 0 auto;

      .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        margin: 0 100px 42px 100px;

        position: relative;

        &.search-header {
          display: block;
          p {
            font-weight: 600;
            font-size: 22px;
            line-height: 28px;
            margin-top: 13px;
            color: $colour-grey-2;
          }
          margin-bottom: 32px;
        }

        h1 {
          font-size: 1em;
          margin: 0;
        }

        button {
          &.create {
            background: linear-gradient(268.41deg, #3b9fc2 0%, #2c7690 52.85%);
            /* Blue Dot Button Shadow */

            box-shadow: 0px 1px 1px #235e74,
              0px 12px 24px rgba(35, 94, 116, 0.34);
            border-radius: 4px;
            font-weight: 600;
            font-size: 18px;
            line-height: 40px;
            color: $colour-white;
            padding: 8px 20px;
            margin-left: 24px !important;
          }
        }
        .task-bar {
          align-items: baseline !important;
          justify-content: flex-start !important;
        }
        .task-menu-button {
          position: relative;
          height: 60px;
          display: flex;
          align-items: center;
          margin-right: 12px;

          button {
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 28px;
            text-align: center;
            letter-spacing: -0.04em;
            color: #505366;
            text-decoration: none;
            background: none;
            border: none;
            white-space: nowrap;
            height: 100%;
          }

          &.active {
            button {
              color: #2c7691;
            }

            border-bottom: solid 5px #2c7691;
            margin-bottom: -5px;
          }
        }

        font-style: normal;
        font-weight: 600;
        font-size: 3.75em;
        line-height: 60px;
        letter-spacing: -0.03em;

        /* Grey 1 */
        color: $colour-grey-1;

        svg {
          position: absolute;
          left: -70px;
        }

        & > div {
          font-style: normal;
          font-weight: normal;
          font-size: 1rem;
          line-height: 24px;

          display: flex;
          align-items: center;
          justify-content: flex-end;

          & > * + * {
            margin-left: 10px;
          }

          & > div {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            & > * + * {
              margin-top: 5px;
            }
          }
        }
      }

      .sort-bar {
        box-sizing: border-box;
        height: 60px;
        background: #edf3f5;
        border: 1px solid #d3d7e8;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        .col-1 {
          min-width: 75px;
          display: flex;
          justify-content: center;
          padding-bottom: 25px;
        }
        .col-2 {
          width: 100%;
          cursor: default;
          .inner {
            display: flex;
            align-items: center;
            color: $colour-interactions-a;
            justify-content: center;
            border-radius: 10px;
            padding: 3px;
            &.active {
              background: $colour-interactions-a;
              color: white;
            }
          }

          .text {
            padding-right: 10px;
            font-weight: 600;
          }
          .chevrons {
            display: flex;
            flex-direction: column;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .col-3 {
          min-width: 75px;
          display: flex;
          justify-content: center;
          button {
            color: $colour-interactions-a;
            border: 1px solid $colour-grey-3;
            border-radius: 3px;
            height: 25px;
          }
        }
      }

      .divider {
        border-bottom: 1px solid #c2445a;
        width: 80%;
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
      }

      .important-form-row {
        box-sizing: border-box;
        height: 88px;
        background: #c2445a;
        border: 1px solid #c2445a;
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        &.selected {
          background: linear-gradient(75deg, #3b9fc2 0%, #2c7690 52.85%);
          border: none;
        }
        .col-1 {
          min-width: 75px;
          height: 100%;
          display: flex;
          justify-content: center;
          padding-bottom: 25px;
          border-right: 1px solid $colour-grey-3;
          display: flex;
          align-items: center;
          .checkmark {
            border: 1px solid $colour-grey-3;
          }
        }
        .col-2 {
          min-width: 100px;
          width: 100%;
          height: 100%;
          color: white;
          font-weight: 700;
          border-right: 1px solid $colour-grey-3;
          display: flex;
          align-items: center;
          padding: 5px;
          justify-content: center;
          overflow-x: hidden;
          cursor: default;
          .acronym-container {
            width: auto;
            height: auto;
            padding: 5px;
            border-radius: 25px;
            color: #c2445a;
            background-color: white;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            font-weight: 600;
          }
        }
        .col-3 {
          min-width: 75px;
          display: flex;
          justify-content: center;
          button {
            background: transparent;
            border: 1px solid $colour-grey-3;
            border-radius: 3px;
            height: 25px;
            svg {
              color: white;
            }
          }
          .dropdown {
            position: absolute;
            right: -160px;
            display: flex;
            flex-direction: column;
            z-index: 5;
            cursor: pointer;
            min-width: 185px;
            .item-container {
              padding: 15px;
              display: flex;
              flex-direction: row;
              background: #191e1f !important;
              color: white;
              border: none;
              align-items: center;
              height: 50px;
              border-radius: 0px;
              .item-icon {
                height: 15px;
                width: 25px;
                padding-right: 10px;
              }
            }
          }
        }
      }

      .form-row {
        box-sizing: border-box;
        height: 88px;
        background: white;
        border: 1px solid #d3d7e8;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        border-radius: 12px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        &.unread {
          border: 1px solid #2c7691;
          font-weight: 600;
          background: rgba(44, 118, 145, 0.2);
        }
        &.selected {
          background: linear-gradient(75deg, #3b9fc2 0%, #2c7690 52.85%);
          color: white;
          border: none;
          .col-3 {
            button {
              svg {
                color: white;
              }
            }
          }
        }
        .col-1 {
          min-width: 75px;
          height: 100%;
          display: flex;
          justify-content: center;
          padding-bottom: 25px;
          border-right: 1px solid $colour-grey-3;
          display: flex;
          align-items: center;
          .checkmark {
            border: 1px solid $colour-grey-3;
          }
        }
        .col-2 {
          min-width: 100px;
          width: 100%;
          height: 100%;
          border-right: 1px solid $colour-grey-3;
          display: flex;
          align-items: center;
          padding: 5px;
          justify-content: center;
          overflow-x: hidden;
          cursor: default;
          .acronym-container {
            width: auto;
            height: auto;
            padding: 5px;
            border-radius: 25px;
            color: white;
            background-color: #307f9b;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 22px;
            font-weight: 600;
          }
          .personnel-tag {
          }
        }
        .col-3 {
          min-width: 75px;
          display: flex;
          justify-content: center;
          button {
            border: 1px solid $colour-grey-3;
            border-radius: 3px;
            height: 25px;
            background: transparent;
          }
          .dropdown {
            position: absolute;
            right: -160px;
            display: flex;
            flex-direction: column;
            z-index: 5;
            cursor: pointer;
            min-width: 185px;
            .item-container {
              padding: 15px;
              display: flex;
              flex-direction: row;
              background: #191e1f !important;
              color: white;
              border: none;
              align-items: center;
              height: 50px;
              border-radius: 0px;
              .item-icon {
                height: 15px;
                width: 25px;
                padding-right: 10px;
              }
            }
          }
        }
      }

      .form-preview {
        height: auto;
        .form-panel {
          margin-left: 20px;
          margin-right: 20px;
          margin-bottom: 10px;
          border: 1px solid #d6d9ea;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
          border-radius: 12px;
          .panel-header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            height: 50px;
            background: #edf3f5;
            border: 1px solid #d0d4e7;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            padding-left: 15px;
            padding-right: 15px;
            align-items: center;
            font-weight: 600;
          }
          .panel-content {
            .row {
              display: flex;
              flex-direction: row;
              justify-content: center;
              margin-top: 20px;
              margin-bottom: 20px;
              .label-left {
                display: flex;
                align-items: center;
                .label {
                  font-weight: 600;
                  margin-left: 20px;
                }
                .value {
                  background: #dee2ed;
                  border: 1px solid #d0d4e7;
                  border-radius: 7px;
                  padding: 15px;
                  margin: 5px;
                }
              }
              .ref {
                font-weight: 600;
                font-size: 24px;
                align-items: center;
                display: flex;
                margin: 20px;
                min-width: 20px;
              }
              .label-top {
                display: flex;
                flex-direction: column;
                .label {
                  font-weight: 500;
                  font-size: 12px;
                  margin-left: 5px;
                }
                .value {
                  background: #dee2ed;
                  border: 1px solid #d0d4e7;
                  border-radius: 7px;
                  padding: 15px;
                  margin: 5px;
                }
              }
            }
          }
        }
      }

      .section-bar {
        background: #edf3f5;
        border-radius: 6px;
        font-weight: 600;
        font-size: 16px;
        line-height: 48px;
        letter-spacing: -0.02em;
        color: $colour-grey-1;
        text-indent: 20px;
        &.tasks-bar {
          position: relative;
          margin-bottom: 20px;
          span.sort-by {
            text-indent: 0px;
            position: absolute;
            right: 20px;
            font-weight: 400;
            font-size: 16px;
            color: $colour-grey-1;
            div,
            button {
              display: inline-block;
            }
            .sort-option {
              text-decoration: underline;
              font-weight: 400;
              font-size: 16px;
              color: $colour-grey-1;
            }
          }
        }
      }

      .sub-header {
        border-top: 1px solid $colour-grey-4;
        border-bottom: 1px solid $colour-grey-4;
        padding: 24px 0;
        margin: 0 0 18px 0;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 24px;
        color: $colour-grey-1;

        .link {
          font-style: normal;
          font-weight: normal;
          font-size: 1rem;
          line-height: 24px;
          color: $colour-grey-1;
        }

        div {
          display: flex;
          flex-direction: row;
          align-items: center;
          & > * + * {
            margin-left: 5px;
          }
        }
      }

      .info-bar {
        display: flex;
        justify-content: space-between;
        padding: 0 0 24px 0;

        .left {
        }
        .right {
        }
      }

      .body {
        .menu-button.link {
          button {
            width: unset;
          }
        }

        .no-results {
          text-align: center;
          span {
            font-style: normal;
            font-weight: 500;
            font-size: 28px;
            line-height: 32px;
            width: 100%;
            display: block;
            text-align: center;
          }
          button {
            border: 1px solid $colour-interactions-a;
            border-radius: 4px;
            min-width: 200px;
            display: inline-block;
            font-weight: 600;
            font-size: 18px;
            line-height: 40px;
            margin-top: 20px;
            text-align: center;
            text-transform: uppercase;
            color: $colour-interactions-a;
          }
        }

        .button.new {
          font-style: normal;
          font-weight: 600;
          font-size: 2.25rem;
          line-height: 40px;
          letter-spacing: -0.03em;
          color: $colour-grey-1;

          padding: 0;
        }

        &.task-results {
          margin: 0 60px 0 60px;
        }

        .clone {
          height: 189px;
        }

        .dot-button.new {
          margin-top: 30px;
        }

        section.expandable {
          $inset: 72px;
          $margin-top: 58px;
          $gap: 36px;

          padding-top: $margin-top;
          margin-left: $inset;
          margin-right: $inset;

          .button.new {
            font-style: normal;
            font-weight: 600;
            font-size: 1.375rem;
            line-height: 28px;
            letter-spacing: -0.04em;
          }

          & > .section-header {
            display: flex;
            flex-direction: row;

            justify-content: space-between;
            align-items: center;

            & > * + * {
              margin-left: $gap;
            }

            font-size: 2.25rem;
            padding-right: $inset;

            &.noinset {
              padding-left: $inset;
            }

            & > .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              height: $inset;
              width: $inset;

              &.action-icon {
                max-width: 36px !important;
                max-height: 36px !important;
                margin-left: 5px !important;
                cursor: pointer;
                &.actions {
                  background: rgba(59, 159, 194, 0.2);
                  border-radius: 6px;
                  button {
                    width: 36px;
                    height: 36px;
                  }
                }
              }

              .outer {
                position: absolute;
                height: $inset;
                width: $inset;
                border-radius: 50%;

                background: $colour-white;
                box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1);
              }

              .inner {
                position: absolute;
                height: 60px;
                width: 60px;

                color: $colour-grey-1;

                border-radius: 50%;

                font-style: normal;
                font-weight: 700;
                font-size: 1.75rem;
                line-height: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
                letter-spacing: -0.04em;
              }
            }

            & > span {
              flex-grow: 1;
              font-style: normal;
              font-weight: 600;
              font-size: 2.25rem;
              line-height: 40px;
              letter-spacing: -0.03em;
              color: $colour-grey-1;

              span.notes {
                display: block;
                line-height: 24px;

                & > span {
                  display: inline-block;
                }
              }
              .section-notes {
                font-weight: 600;
                font-size: 16px;
                color: $colour-grey-2;
                & > span {
                  margin-right: 20px;
                }
                .icon-users {
                  width: 20px;
                }
                .icon-tasks {
                  width: 16px;
                }
                img {
                  margin-right: 10px;
                  filter: invert(33%) sepia(7%) saturate(1171%)
                    hue-rotate(195deg) brightness(93%) contrast(91%);
                }
              }
            }

            .toolbar {
              display: none;
              $button-size: 60px;

              position: absolute;
              right: -$inset;

              background-color: $colour-white;
              border-radius: 6px;
              box-shadow: 0px 4px 12px rgba(0, 84, 101, 0.24);

              .dot-button.toolbar-button {
                background-color: transparent;
                border: none;

                height: $button-size;
                width: $button-size;
              }

              & > * + * {
                border-top: 1px solid $colour-grey-4 !important;
              }
            }
          }

          & > .section-body {
            margin-left: $inset + $gap;
            margin-right: $inset + $gap;

            h3 {
              font-style: normal;
              font-weight: 600;
              font-size: 1.375rem;
              line-height: 28px;
              letter-spacing: -0.04em;
              margin: 30px 0 12px 0;
            }

            & > p {
              & > * + * {
                margin-top: 20px;
              }
            }
          }

          &.history {
            & > .section-header {
              & > span {
                border: 2px solid #2c7691;
                border-radius: 12px;
                padding: 5px;
              }
            }

            & > .section-body {
              & > p {
                border: 2px solid #2c7691;
                border-radius: 12px;
                padding: 5px;
              }
            }
          }

          &:hover {
            .section-header {
              & > .icon {
                .outer {
                  background: $colour-interactions-a;
                  opacity: 0.1;
                  box-shadow: none;
                }
                .inner {
                  background: linear-gradient(
                    268.41deg,
                    #3b9fc2 0%,
                    #2c7690 52.85%
                  );
                  box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1);

                  color: $colour-white;
                }
              }

              .toolbar {
                display: block;
              }
            }
          }

          .border {
            margin-top: $margin-top;
            margin-left: $inset;
            margin-right: $inset;
            border-bottom: 1px solid $colour-grey-4;
          }
        }

        .task-list {
          display: flex;
          position: relative;
          img {
            padding-right: 15px;
            height: 20px;
            width: 40px;
          }
          button.task-create {
            position: absolute;
            top: 20px;
            right: 10px;
            border: 1px solid $colour-interactions-a;
            border-radius: 4px;
            width: 127px;
            line-height: 37px;
            color: $colour-interactions-a;
            font-weight: 600;
            font-size: 14px;
          }
        }

        div.tasks {
          padding: 1rem 0;
          & > * + * {
            margin-top: 10px;
          }
          .pop-out-sidebar {
            margin-top: 0px;
          }
        }

        section.task {
          background: $tasks-bg;
          border-radius: 12px;
          padding: 24px;
          box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1);

          &.history {
            border: 2px solid #2c7691;
          }

          &.task-container {
            position: relative;
          }

          .columns {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;

            & > * + * {
              flex-grow: 0;
            }

            .column {
              display: flex;
              flex-direction: column;
            }
          }

          .task-actions-bar {
            position: absolute;
            right: 24px;
            top: 24px;
            width: 36px;
            height: 36px;
            border: 1px solid $colour-grey-3;
            border-radius: 4px;
            .menu-button,
            button {
              display: block;
              width: 100%;
              height: 100%;
            }
          }

          .section-name {
            color: $colour-interactions-a;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
            img {
              padding-right: 8.5px;
              filter: invert(39%) sepia(51%) saturate(524%) hue-rotate(150deg)
                brightness(93%) contrast(95%);
            }
          }
          .section-header {
            display: flex;
            flex-direction: row;

            justify-content: space-between;
            align-items: center;

            margin-bottom: 12px !important;

            span {
              font-style: normal;
              font-weight: 600;
              font-size: 1rem;
              line-height: 24px !important;
              letter-spacing: -0.02em;
              color: $colour-grey-1;
            }
            &.task-section {
              justify-content: left;
              align-items: self-start;
              .task-name {
                span {
                  font-size: 18px;
                  line-height: 18px !important;
                  text-transform: capitalize;
                }
                padding-left: 11px;
              }
            }
            .checkmark {
              width: 22px;
              height: 22px;
            }
          }

          .task-footer-box {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 6px 12px 6px 6px;
            background: #edf3f5;
            border-radius: 6px;
            img.icon {
              margin-right: 10px;
            }
          }

          .section-body {
            padding: 0 !important;
            margin-left: 0;

            margin-bottom: 12px;
            p {
              margin-bottom: 12px;
              font-style: normal;
              font-weight: normal;
              font-size: 1rem;
              line-height: 24px;
            }
          }

          .section-foot {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left !important;
            border-top: none !important;
            padding-top: 0 !important;

            & > * + * {
              margin-left: 15px !important;
            }

            .units {
              flex-grow: 0;

              display: flex;
              flex-direction: row;
              align-items: center;

              & > * + * {
                margin-left: -4px !important;
              }

              .avatar {
                box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1) !important;
                border: 2px solid $colour-white !important;
                color: $colour-white !important;

                border-radius: 50% !important;

                font-style: normal;
                display: flex;
                align-items: center;
                text-align: center;
                letter-spacing: -0.01em !important;
                width: 21px;
                height: 21px;
                justify-content: center;
                &.dark {
                  background: $colour-black;
                  z-index: 1;
                  span {
                    font-size: 12px;
                    font-weight: 600;
                  }
                }
              }
              .dot-avatar .avatar-img {
                box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1) !important;
                border: 2px solid $colour-white !important;
                width: 22px !important;
                height: 22px !important;
              }
            }

            .assigned {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 1rem;
              display: flex;
              align-items: center;
              letter-spacing: -0.01em;
              margin-right: 10px;
            }

            .time {
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 20px;
              display: flex;
              align-items: center;
              letter-spacing: -0.01em;

              & > * + * {
                margin-left: 10px;
              }

              text-transform: uppercase;
            }
          }
        }

        section.new {
          background: #ffffff;
          box-shadow: 0px 4px 12px rgba(0, 84, 101, 0.24);
          border-radius: 12px;
          padding: 36px;

          &.sidebarTask {
            background: #ffffff;
            box-shadow: none;
            border-radius: 0px;
            padding: 0px;
            border: none;
          }

          .button.new {
            font-style: normal;
            font-weight: 600;
            font-size: 1.375rem;
            line-height: 28px;
            letter-spacing: -0.04em;
          }

          .section-header {
            display: flex;
            flex-direction: row;

            justify-content: space-between;
            align-items: center;

            font-size: 2.25rem;

            span {
              font-style: normal;
              font-weight: 600;
              line-height: 40px;
              letter-spacing: -0.03em;
            }
          }

          .section-body {
            padding: 36px 0px;
            display: flex;
            flex-direction: column;
            & > * + * {
              margin-top: 20px;
            }

            .row {
              display: flex;
              flex-direction: row;
              & > * + * {
                margin-left: 10px;
              }
              justify-content: flex-start;
              align-items: center;
            }
          }

          .section-foot {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            & > * + * {
              margin-left: 20px;
            }

            padding-top: 36px;
            border-top: 1px solid #c7cce6;
          }

          &.task {
            .section-header {
              font-size: 1.75rem;
            }
            .section-foot {
              padding-top: 0;
              border-top: none;
            }
          }
        }
      }
    }
  }

  .content-columns {
    $col-width: 30rem;

    display: flex;
    flex-direction: row;
    background-color: $chat-bg;

    section {
      flex-grow: 0;
      flex-shrink: 0;

      &.chat-list {
        flex-basis: $col-width;

        min-width: $col-width;
        background-color: $chat-bg;

        .header {
          display: flex;
          flex-direction: column;
          & > * + * {
            margin-top: 20px;
          }
          margin-top: 5rem;

          h1 {
            margin: 0;

            display: flex;
            flex-direction: row;
            & > * + * {
              margin-left: 20px;
            }

            justify-content: flex-start;
            align-items: center;

            font-style: normal;
            font-weight: 600;
            font-size: 3rem;
            line-height: 60px;
            letter-spacing: -0.03em;
            color: $colour-grey-1;

            svg {
              width: 36px;
              height: 36px;
            }

            padding: 10px 0;
          }
          padding: 20px;

          div {
            display: flex;
            flex-direction: row;
            width: 100%;
            align-items: center;
            & > * + * {
              margin-left: 20px;
            }

            .search {
              height: 60px;
              border: none;

              input {
                font-size: 1rem;
              }

              background-color: $colour-white;
              filter: drop-shadow(rgba(0, 84, 101, 0.25) 0px 6px 8px);

              input {
                color: $colour-grey-1;
              }

              svg {
                margin-right: 20px;
              }
            }

            .search-button {
              padding: 0;
              margin-right: 0;
              margin-top: 0;
              margin-bottom: 0;
              width: 36px;
              height: 36px;
            }
          }
        }

        .list {
          padding: 0;
          height: vh(100, -21.5rem) !important;

          .list-item {
            cursor: pointer;
            border-radius: 0;
            display: flex;
            flex-direction: row;
            & > * + * {
              margin-left: 12px;
            }
            justify-content: space-between;

            background: $chat-bg;

            padding: 12px 15px 12px 0;

            &:hover {
              background: #f2f4fa;
            }
            &.active {
              background: #f2f4fa;
            }

            img.mission-broadcast {
              border: solid 6px $colour-grey-6;
              border-radius: 50%;
            }

            .chat {
              flex-direction: column;
              & > * + * {
                margin-top: 0;
              }
              flex-grow: 1;
              max-width: 181px;

              font-size: 1rem;
              font-style: normal;
              font-weight: 600;
              line-height: 24px;
              letter-spacing: -0.02em;
              text-align: left;

              display: flex;

              span {
                display: block;

                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: -0.02em;
                text-align: left;
                opacity: 0.6;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                text-transform: none;

                &:first-child {
                  font-weight: 600;
                }
              }

              .typing {
                background-color: transparent;

                & > img {
                  height: 10px;
                  width: 38px;
                }
              }
            }
            .time {
              min-width: 130px;
              width: 130px;
              justify-content: flex-end;

              font-size: 0.75rem;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: -0.01em;
            }

            .activity {
              width: 12px;
              flex-shrink: 0;
            }
          }
        }
      }
    }

    .chat-groups .ScrollbarsCustom-Content {
      border-left: solid 1px $colour-grey-4;
      display: flex;
      flex-direction: row;

      & > .chat-group {
        flex-shrink: 0;
        flex-grow: 0;

        width: $col-width;
        border-right: solid 1px $colour-grey-4;
        .header {
          display: flex;
          flex-direction: column;
          & > * + * {
            margin-top: 10px;
          }
          width: 100%;
          padding: 20px;
          background-color: $colour-white;

          .top {
            width: 100%;
            display: flex;
            flex-direction: row;
            & > * + * {
              margin-left: 10px;
            }
            justify-content: space-between;
            align-items: center;

            //styleName: Body - 16px;
            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;

            color: $colour-black;

            opacity: 0.4;

            .button {
              margin: 0;
              padding: 0;
              height: 36px;
              width: 36px;
            }
          }
          .bottom {
            width: 100%;
            display: flex;
            flex-direction: row;
            & > * + * {
              margin-left: 10px;
            }
            align-items: center;

            img.mission-broadcast {
              border: solid 6px #e5e8f4;
              border-radius: 50%;
              width: 3.75rem;
              height: 3.75rem;
            }

            .menu-button {
              width: 36px;
              height: 36px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            h2 {
              margin-right: 0;
              margin-top: 0;
              margin-bottom: 0;
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              & > * + * {
                margin-top: 0;
              }
              font-size: 1.75rem;
              font-style: normal;
              font-weight: 500;
              line-height: 32px;
              letter-spacing: -0.04em;
              text-align: left;

              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;

              span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;

                font-size: 1rem;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
              }
            }
          }
        }

        .tabs {
          box-shadow: 0px 8px 8px rgba(0, 84, 101, 0.1);
          z-index: 1;
        }

        .tab-content {
          padding-top: 0;
          display: flex;
          flex-direction: column;

          .chat {
            height: vh(100, -202px);

            .compose {
              background-color: $colour-white;

              .compose-container {
                max-width: calc(705px - 5rem);
                margin: 0 auto;
              }
            }
          }
        }
      }
    }
  }
}

.has-notification {
  .page {
    grid-template-rows: vh(100, -#{$banner-height});

    .content-columns {
      section {
        &.chat-list {
          .list {
            height: vh(100, -21.5rem - $banner-height) !important;
          }
        }

        &.chat-group {
          .tab-content {
            .chat {
              height: vh(100, -12.625rem - $banner-height);
            }
          }
        }
      }
    }
  }
}

.call-banner {
  top: calc(#{$banner-height} + var(--map-padding)) !important;
}

/* Add a black background color to the top navigation bar */
.topnav {
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 104px;
  background: #ffffff;
  z-index: 9;
  justify-content: space-between;
  box-shadow: 0px 4px 12px rgba(0, 84, 101, 0.24);

  .mission-info-container {
    position: relative;
    left: auto;
    margin-left: calc(#{$nav-width} + var(--map-padding));
    margin-right: 20px;
  }

  .menu-button {
    position: relative;
    height: 104px;
    display: flex;
    align-items: center;

    button {
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 28px;
      text-align: center;
      letter-spacing: -0.04em;
      color: #505366;
      text-decoration: none;
      white-space: nowrap;
    }

    &.active {
      button {
        color: #2c7691;
      }
      img {
        filter: invert(39%) sepia(51%) saturate(524%) hue-rotate(150deg)
          brightness(93%) contrast(95%);
      }
      border-bottom: solid 5px #2c7691;
      margin-bottom: -5px;
    }

    &.nav-mission {
      img {
        height: 19px;
        margin-right: 12px;
      }
    }

    &.nav-task {
      img {
        height: 10px;
        margin-right: 12px;
      }
    }

    &.nav-mission,
    &.nav-task {
      margin-right: 20px;
      cursor: pointer;
      img {
        width: 16px;
        margin-right: 12px;
      }
      button {
        height: 100%;
      }
    }
  }

  .nav-search {
    min-width: 22%;
    .search {
      width: 100%;
    }
  }

  .menu-history-button {
    right: 22px;
    display: flex;
    align-items: center;

    button {
      width: 60px !important;
      height: 60px;
      background: #ffffff;
      box-shadow: 0px 4px 12px rgba(0, 84, 101, 0.24);
      border-radius: 6px;
      color: #000;
    }
  }
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Style the "active" element to highlight the current page */
.topnav a.active {
  background-color: #2196f3;
  color: white;
}

.pop-out-sidebar {
  z-index: 15;
}

@media only screen and (max-width: 1050px) {
  .menu-history-button {
    visibility: hidden;
  }
}
