/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty 
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any 
 * licence may have from the copyright owner to use the Software, you must not copy, 
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to 
 * derive the source code of or modify the Software, nor create any derivative works of 
 * the Software.
 */

@import '../../../modules.scss';

.chat-participants-sidebar {
  .bottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    & > * + * {
      margin-left: 10px;
    }
    align-items: center;

    img.mission-broadcast {
      border: solid 6px #e5e8f4;
      border-radius: 50%;
    }

    padding: 0 20px;

    h2 {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      & > * + * {
        margin-top: 0;
      }
      font-size: 1.75rem;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
      letter-spacing: -0.04em;
      text-align: left;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
      }
    }
  }

  .has-shadow {
    box-shadow: 0px 4px 12px rgba(0, 84, 101, 0.24);
  }

  .has-border {
    padding: 20px 20px 0 20px;

    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    letter-spacing: -0.01em;

    /* Grey 1 */
    color: $colour-grey-1;

    text-transform: uppercase;
  }

  .list {
    padding: 0;
    margin-top: 0;

    .list-item {
      border-radius: 0;
      margin-left: -20px;
      margin-right: -20px;

      & > * + * {
        margin-left: 12px;
      }

      & > div.name {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 24px;
        letter-spacing: -0.02em;

        /* Grey 1 */
        color: $colour-grey-1;

        span {
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 24px;
          letter-spacing: -0.02em;

          /* Grey 1 */
          color: $colour-grey-1;
        }
      }

      .dot-button {
        background: transparent;
        border: none;
      }

      &:hover {
        background: $colour-grey-5;
      }
    }
  }
}
