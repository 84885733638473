/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

$eq-height: 31px;

.voice-call {
  background: $colour-white;
  border-radius: 6px;

  transition: right 0.5s ease-in-out;

  filter: drop-shadow(0px 4px 12px rgba(0, 84, 101, 0.24));
  border: none;

  padding: 11px 15px 11px 0;

  .toolbar {
    padding: 0 0 0 15px;

    font-style: normal;
    font-weight: bold;
    font-size: 0.75rem;
    line-height: 20px;
    letter-spacing: -0.01em;
    color: $colour-grey-2;
    min-width: 300px;

    display: flex;
    & > * + * {
      margin-left: 10px;
    }

    justify-content: space-between;

    .left {
      display: flex;
      align-items: center;
      & > * + * {
        margin-left: 15px;
      }
    }

    .right {
      display: flex;
      align-items: center;
      & > * + * {
        margin-left: 15px;
      }
      .status {
        display: inline-block;
        background-color: $colour-negative-status-a;
        color: white;
        text-transform: uppercase;
        padding: 0 0.5rem;
        border-radius: 4px;

        &.connected {
          background-color: $colour-positive-status-a;
        }
        &.ringing {
          background-color: $colour-interactions-a;
        }
        &.outgoing {
          background-color: $colour-interactions-a;
        }
        &.incoming {
          background-color: $colour-interactions-a;
        }
        &.muted {
          background-color: $colour-grey-2;
        }
      }
      .dot-button {
        background-color: transparent;
        border: none;
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: center;

    .time {
      padding: 0.5rem;
      margin-left: 0.5rem;
      min-width: 75px;
    }

    .dot-button {
      border: none;
      background-color: transparent;
      margin-left: 0.5rem;
      min-width: 30px;
    }

    .equalizer {
      position: relative;
      width: 200px;
      height: $eq-height * 2;
      display: flex;
      justify-content: center;
      align-items: center;

      .horizontal {
        width: 100%;
        border: 1px solid $colour-interactions-b;
        background: $colour-interactions-b;
      }

      .vertical-lines {
        position: absolute;
        width: 100%;
        height: $eq-height * 2;
        display: flex;
        align-items: center;
      }

      .vertical {
        width: 5px;
        height: 0;
        border-radius: 5px;
        background-color: $colour-interactions-a;

        animation-direction: alternate-reverse;
        margin: 1px;
      }

      @keyframes equalizer {
        from {
          height: $eq-height;
        }

        to {
          height: 5px;
        }
      }
    }
  }
}

.call-wrapper {
  &.making-call {
    right: 0px;
    left: 0px;
    z-index: 10;
    background: #000000;
    top: 0px;
    min-height: 60px;
    max-height: 60px;
    padding: 0px 15%;
    width: 100%;
    .voice-call {
      background: $colour-black !important;
      border: none;
      border-left: 1px solid rgba(255, 255, 255, 0.5);
      border-right: 1px solid rgba(255, 255, 255, 0.5);
      border-radius: 0;
      transition: none;
      filter: none;
      padding: 5px 15px 16px 0;
      max-height: 60px;
      .left {
        color: $colour-white;
        font-size: 14px;
        .time {
          text-transform: uppercase;
          margin-right: 10px;
          min-width: 85px;
        }
        .call-button {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          padding: 3px;
          text-align: center;
          border: 2px solid;
          .child {
            width: 100%;
            height: 100%;
          }
          &.transparent {
            border-color: transparent;
          }
          &.first {
            border-color: rgba(255, 255, 255, 0.2);
          }
          &.second {
            border-color: rgba(255, 255, 255, 0.4);
          }
          &.third {
            border-color: rgba(255, 255, 255, 0.8);
          }
          &.dialing {
            background-color: $colour-white;
            color: $colour-black;
          }
          &.talking {
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2),
                rgba(0, 0, 0, 0.2)
              ),
              $colour-grey-1;
            color: $colour-white;
          }
          &.end {
            background: rgba(255, 255, 255, 0.08);
            color: $colour-white;
            border-color: rgba(255, 255, 255, 0.08);
            padding: 5px;
            img {
              width: 15px;
            }
          }
        }
        .avatar {
          outline: 3px solid $colour-white !important;
        }
      }
      .right {
        .circle-button {
          outline: 3px solid $colour-white !important;
          width: 34px;
          height: 34px;
          color: $colour-white;
          border-radius: 50px;
          margin-right: 10px;
          &.mute-button {
            width: 40px;
            height: 40px;
            background: rgba(255, 255, 255, 0.16);
            outline: none !important;
          }
        }
        .border-right {
          margin-right: 10px;
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          .circle-button {
            margin-right: 20px;
          }
        }
        .end-call {
          background: $colour-negative-status-a;
          border-radius: 4px;
          color: $colour-white;
          padding: 10px;
          margin-right: 10px;
          img {
            width: 20px;
            margin-left: 10px;
          }
          &.ended {
            background: rgba(255, 255, 255, 0.08);
          }
        }
      }
      .right-border {
        border-right: 1px solid rgba(255, 255, 255, 0.5);
        padding-right: 24px;
        line-height: 34px;
      }
    }
  }
  &.incoming-call {
    right: 22px;
    z-index: 10;
    top: 82px;
    width: 300px;
    height: 108px;
    .voice-call {
      width: 100%;
      display: flex;
      background: $colour-black;
      padding: 11px 0 0;
      margin: 0px;
      .parent-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: left;
        padding: 0 0 0;
        width: 100%;
        border: none;
        div:first-child {
          max-width: 75px;
        }
        div.button-container {
          flex: 0 1 100%;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: left;
          margin: 15px 0 0;
          padding: 0px;
          button {
            width: 50%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 10px 12px;
            color: $colour-white;
            text-transform: uppercase;
            border: none;
            border-bottom-left-radius: 6px;
            gap: 0;
            svg {
              padding-left: 10px;
              width: 14px;
              height: 14px;
            }
          }
        }
      }
      .caller-icon {
        margin-left: 12px;
        position: relative;
        width: 50px;
        height: 50px;
        .call-button {
          border-radius: 50%;
          border: 2px solid;
          width: 100%;
          height: 100%;
          padding: 11px;
          text-align: center;
          border-color: rgba(255, 255, 255, 0.8);
          background-color: $colour-white;
          color: $colour-black;
          svg {
            width: 24px;
            height: 24px;
          }
        }
        .dot-avatar {
          .avatar,
          img {
            outline: 3px solid $colour-white !important;
            position: absolute;
            top: -17px;
            left: 30px;
            border: none !important;
          }
        }
      }
      .call-status,
      .user-name {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: $colour-white;
        width: 100%;
        display: flex;
        padding-left: 10px;
      }
    }
  }
}
