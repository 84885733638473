/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules';

.group-render {
  display: flex;
  flex-direction: row;
  align-items: center;

  padding-bottom: 10px;
  padding-top: 10px;
  padding-left: 20px;
  padding-right: 10px;

  border-right: solid 4px transparent;
  margin-right: 15px;

  &:hover {
    background-color: $colour-interactions-a;
  }

  &.selected {
    border-right: solid 4px $colour-interactions-a;
  }

  & > button {
    padding: 0;
  }

  &-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 90%;
    @extend .button-default;
    background-color: transparent;
    justify-content: space-between !important;
  }

  &-letterbox {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
    border-radius: 12px;
    background-color: cornflowerblue;
    color: $colour-white;
    font-size: 1.25rem;
    font-weight: 600;
  }

  &-info {
    flex-grow: 1;
    margin-left: 8px;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    &-title {
      font-weight: 550;
      color: $colour-white;
      font-size: 0.9375rem;
      text-align: left;
    }
    &-members {
      color: $colour-white;
      font-size: 0.75rem;
    }
  }
  &-arrow {
    @extend .button-default;
    background-color: transparent;
    height: 22px;
    width: 22px;
    margin-left: auto;
    color: rgb(208, 208, 208);
  }

  &-dropdown {
    &-menu {
      height: 100%;
    }

    button {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: transparent;
      color: $colour-white;
      font-weight: 600;
      font-size: 0.75rem;
      border: none;
      height: 30px;
    }
  }
}
