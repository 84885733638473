/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules.scss';

.app-sidebar {
  -webkit-user-select: none;
  user-select: none;

  display: flex;
  flex-direction: column;

  justify-content: space-between;
  align-items: center;

  min-width: $nav-width;
  width: $nav-width;

  grid-area: sidebar;

  padding-top: 24px;

  background: $colour-grey-1;

  z-index: 10;

  & > div {
    display: flex;
    flex-direction: column;

    justify-content: flex-start;
    align-items: center;

    & > * + * {
      margin-top: 24px;
    }
  }

  button {
    background-color: transparent;
    border: none;
  }

  .dot-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > * + * {
      margin-top: 10px;
      margin-left: 0;
    }
    color: $colour-white;

    width: $nav-width;
    border-radius: 0;
    border-left: solid 4px transparent;
    border-right: solid 4px transparent;
    .svg {
      height: 25px;
      width: 25px;
      filter: invert(100%) sepia(0%) saturate(2%) hue-rotate(276deg)
        brightness(103%) contrast(101%);
      transition: filter 0.2s ease-in-out;
    }

    &.active {
      color: $colour-interactions-c;
      border-right: solid 4px $colour-interactions-c;
      .svg {
        filter: invert(76%) sepia(47%) saturate(1423%) hue-rotate(165deg)
          brightness(91%) contrast(89%);
      }
    }

    span {
      font-style: normal;
      font-weight: 600;
      font-size: var(--nav-size-text);
      line-height: 1rem;
      letter-spacing: -0.01em;
    }

    &:not(:disabled):hover {
      color: $colour-interactions-c;
      .svg {
        filter: invert(76%) sepia(47%) saturate(1423%) hue-rotate(165deg)
          brightness(91%) contrast(89%);
        transition: filter 0.2s ease-in-out;
      }
    }

    .badge {
      right: 12px;
    }

    & > img {
      height: var(--nav-size-img);
      width: var(--nav-size-img);
    }

    & > svg {
      height: var(--nav-size-svg);
      width: var(--nav-size-svg);
    }
  }

  .middle {
    width: 100%;
    flex-grow: 1;
    justify-content: flex-start;
    & > * + * {
      margin-top: 24px;
    }
    padding-top: 24px;
  }

  .bottom {
    width: 100%;
    padding-bottom: $map-padding;
  }
}
