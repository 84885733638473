/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

.sidebar-open {
  .pop-out-bottombar {
    width: calc(100% - (5.25rem + var(--sidebar-width)));
  }
  .weather-label {
    right: calc(
      var(--map-padding) * 6 + var(--toolbar-size) + 250px +
        var(--sidebar-width)
    );
  }
  .weather-scale {
    right: calc(
      var(--map-padding) * 6 + var(--toolbar-size) + var(--sidebar-width)
    );
  }
}

.half-screen-sidebar-open {
  .pop-out-bottombar {
    width: calc(100% - (5.25rem + var(--half-screen-sidebar-width)));
  }
  .weather-label {
    right: calc(
      var(--map-padding) * 6 + var(--toolbar-size) + 250px +
        var(--half-screen-sidebar-width)
    );
  }
  .weather-scale {
    right: calc(
      var(--map-padding) * 6 + var(--toolbar-size) +
        var(--half-screen-sidebar-width)
    );
  }
}

.dark-mode {
  .pop-out-bottombar {
    background-color: #191e1f;
    color: $colour-white;
    .weatherHeaderSummary .location {
      background-color: #191e1f;
    }
    .layerButton {
      padding: 16px;
      border-radius: 4px;
      color: $colour-white;
    }
    .layerButton.active {
      background-color: $colour-black;
      color: $colour-white;
      border: $colour-white solid 1px;
    }
    tr:nth-child(3) {
      color: #191e1f;
    }
    .light {
      color: $colour-white !important;
    }
    td.start {
      color: $colour-white !important;
    }
  }
}

.pop-out-bottombar {
  left: 5.25rem;
  padding: 5px;
  width: calc(100% - 5.25rem);
  position: fixed;
  z-index: 4;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: $colour-white;

  -webkit-user-select: none;
  user-select: none;

  .dot-button.back {
    background: transparent;
    border: none;
    color: $colour-interactions-a;
    margin-left: 20px;
    margin-top: 10px;
  }

  &.is-open {
    box-shadow: -8px 0 48px 0px rgba(0, 0, 0, 0.3);

    input,
    textarea,
    .location-info {
      -webkit-user-select: auto;
      user-select: auto;
    }
  }

  border-radius: 12px 12px 0 0;

  height: $bottombar-height;
  @extend .bottombar-animation;

  & > div {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;
  }

  div.top {
    width: 100%;
    padding: 20px 20px 10px 20px;

    &.has-border {
      border-bottom: solid 1px $colour-grey-4;
      padding: 20px 20px 20px 20px;
      margin-bottom: 10px;
    }

    & + .top {
      padding-top: 10px;
    }

    .dot-button.back {
      margin-left: 0;
      margin-bottom: 10px;
    }
  }

  div.content {
    flex-grow: 1;
    width: 100%;
    padding: 10px 20px 10px 20px;
    margin: 0;

    .ScrollbarsCustom-Content {
      padding: 16px 24px !important;
    }

    form {
      .controls {
        display: flex;
        flex-direction: column;
        & > * + * {
          margin-top: 10px;
        }
      }
    }

    &.tab-content {
      padding: 0;
    }
  }

  div.foot {
    width: 100%;
    padding: 24px;

    display: flex;
    flex-direction: column;
    & > * + * {
      margin-top: 10px;
    }

    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.4);
  }

  .heading {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .text {
      text-transform: none;
      font-size: 2.25rem !important;
      font-weight: 600 !important;
      letter-spacing: -1px;
      color: $colour-grey-1;
    }
    .exit {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      @extend .button-default;
      background-color: transparent;
      color: rgba($colour-black, 0.516);

      svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  .sub-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-top: $map-padding;

    &.time {
      margin-top: 0;
      svg {
        color: $colour-grey-3;
      }

      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 24px;
      color: $colour-grey-1;

      justify-content: flex-start;
      & > * + * {
        margin-left: 20px;
      }
    }

    .text {
      display: flex;
      flex-direction: column;

      font-style: normal;
      font-weight: 600;
      font-size: 1.75rem;
      line-height: 32px;
      letter-spacing: -0.04em;
      color: $colour-grey-1;

      span {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 24px;
        color: $colour-black;
      }
    }

    .icons {
      display: flex;
      flex-direction: row;
    }
  }

  .settings-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;

    img {
      width: 40px;
      border-radius: 12px;
    }
    .text {
      margin-left: 10px;
      font-size: 0.8125rem;
    }
    .text-1 {
      font-weight: 600;
    }
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;

    h2 {
      font-size: 1.875rem;
      color: rgb(74, 74, 74);
      font-weight: 650;
    }

    .exit {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-left: auto;
      @extend .button-default;
      background-color: transparent;
      width: 25px;
      height: 25px;
      color: rgba($colour-black, 0.516);
    }
  }

  h3 {
    font-weight: 600;
    font-size: 1.375rem;
    line-height: 28px;
    letter-spacing: -0.04em;
    color: $colour-grey-1;

    display: flex;
    justify-content: space-between;
    align-items: center;

    height: 48px;

    span {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 20px;
    }
  }

  h4 {
    /* Heading - 22px */
    font-style: normal;
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 28px;
    /* or 127% */
    display: flex;
    align-items: center;
    letter-spacing: -0.04em;

    /* Grey 1 */
    color: $colour-grey-1;
  }

  h5 {
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    /* identical to box height, or 133% */
    letter-spacing: -0.01em;

    /* Grey 1 */
    color: $colour-grey-1;

    text-transform: uppercase;
  }

  .subheading {
    margin-top: 15px;
    font-size: 1.5rem;
    color: #4a4a4a;
    font-weight: 500;
  }

  .information-block {
  }

  .field {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 40px;
    margin-bottom: 0;

    .text {
      font-size: 1rem;
      color: $colour-grey-1;
    }
    .tickbox {
      margin-left: auto;
      color: red;
    }
  }

  .subheading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    span {
      display: inline-block;

      text-transform: uppercase;

      font-style: normal;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1rem;
      /* identical to box height, or 133% */
      letter-spacing: -0.01em;

      /* Grey 1 */
      color: $colour-grey-1;
    }
  }

  .blocks {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    flex-shrink: 1;

    // Hide scrollbar
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;

    padding-top: 20px;

    & > * + * {
      margin-top: 10px;
    }

    h4 {
      /* Heading - 22px */
      font-style: normal;
      font-weight: 500;
      font-size: 1.375rem;
      line-height: 28px;
      /* or 127% */
      display: flex;
      align-items: center;
      letter-spacing: -0.04em;

      /* Grey 1 */
      color: $colour-grey-1;
    }

    h5 {
      font-style: normal;
      font-weight: 600;
      font-size: 0.75rem;
      line-height: 1rem;
      /* identical to box height, or 133% */
      letter-spacing: -0.01em;

      /* Grey 1 */
      color: $colour-grey-1;

      text-transform: uppercase;
    }

    .item-container {
      padding: 10px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      &.has-hover {
        cursor: pointer;
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }

      &.has-border {
        padding-bottom: 10px;
        border-bottom: 1px solid $colour-grey-4;
        margin-bottom: 10px;
      }

      &.disabled {
        opacity: 0.3;
      }

      h5 {
        margin-bottom: 20px;
      }

      & > * + * {
        margin-left: 10px;
      }

      .bar {
        width: 4px;
        margin-left: -16px;
        height: 48px;
        align-self: center;
      }

      .check {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 24px;
      }

      .switch {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .img {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .text {
        flex-grow: 1;
        flex-shrink: 1;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 0;

        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 24px;
        letter-spacing: -0.02em;

        max-width: 100%;

        /* Grey 1 */
        color: $colour-grey-1;
        overflow: hidden;

        span {
          flex-shrink: 1;
          display: block;
          margin-top: 0;
          font-style: normal;
          font-weight: normal;
          font-size: 1rem;
          line-height: 24px;
          /* Grey 1 */
          color: $colour-grey-1;

          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;

          strong {
            font-weight: 600;
          }
        }
      }

      button {
        @extend .button-default;
        background-color: transparent;
        border: none;
      }
    }

    &.allies {
      & > * + * {
        margin-top: 0px;
      }
      margin-left: -10px;
      margin-right: -20px;

      .item-container {
        padding: 10px;
        border-radius: 8px 0px 0px 8px;

        &.selected {
          background-color: $colour-grey-4;
        }
      }
    }
  }

  .ScrollbarsCustom-Content > .blocks {
    padding-top: 0px;
  }

  .note {
    display: flex;
    justify-content: center;
    align-items: center;
    & > * + * {
      margin-left: 10px;
    }
  }
}

// .has-notification {
//   .pop-out-bottombar {
//     top: $banner-height;
//     height: vh(100, -$banner-height);
//   }
// }

.mission-integrations {
  .has-battery {
    .heading {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .exit {
        margin-left: 0;
      }
    }
    .text {
      font-weight: 500 !important;
      font-size: 1.125rem !important;
      line-height: 1.375rem;
      letter-spacing: -0;
    }
  }

  .content {
    height: vh(100, -125px) !important;

    &.has-border {
      border-bottom: solid 1px $colour-grey-4;
    }

    .blocks {
      &.has-border {
        border-bottom: solid 1px $colour-grey-4;
      }
    }

    .groups {
      p {
        span {
          width: 120px;
          display: inline-block;
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      & > * + * {
        margin-top: 10px;
      }

      margin: 0;
      padding: 0;

      .list-item {
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        align-items: center;

        & > * + * {
          margin-left: 1rem;
        }

        .text {
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 24px;
          /* identical to box height, or 150% */
          letter-spacing: -0.02em;

          /* Grey 1 */
          color: $colour-grey-1;

          display: flex;
          flex-direction: column;

          flex-grow: 1;

          margin-top: 0;
          margin-right: 0;
          margin-bottom: 0;

          span {
            display: block;
            font-style: normal;
            font-weight: normal;
            font-size: 1rem;
            line-height: 24px;
          }
        }

        box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1);
        border-radius: 6px;

        &.no-border {
          border: none;
          box-shadow: none;
        }
      }
    }
  }
}

.has-notification {
  .mission-integrations {
    .content {
      height: vh(100, -7.8125rem - $banner-height) !important;
    }
  }
}
