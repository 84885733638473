/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

.upload-image {
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-top: 24px;
  padding-bottom: 24px;

  h4 {
    font-family: $font-family;
    font-size: 1.375rem;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.04em;
    text-align: left;
    color: $colour-grey-1;
  }

  label {
    cursor: pointer;
    text-decoration: underline;
    color: #3990b6;

    input {
      display: none;
    }
  }

  .photo {
    background-color: $colour-grey-5;
    box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1);
    border-radius: 12px;
    overflow: hidden;

    display: flex;
    align-items: center;
    justify-content: center;

    min-height: 72px;
    min-width: 72px;

    cursor: pointer;

    .warning {
      display: none;
    }

    img {
      max-width: 150px;
      max-height: 72px;
    }

    &.invalid {
      position: relative;
      min-height: 72px;
      min-width: 72px;

      .warning {
        left: calc(50% - 12px);
        top: 24px;
        position: absolute;
        display: block;
        height: 24px;
        width: 24px;
        background-color: $colour-white;
        color: $colour-black;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
      }

      &:after {
        position: absolute;
        content: '';
        background-color: rgba($colour-negative-status-a, 0.3);
        height: 100%;
        width: 100%;
        border: solid 3px $colour-negative-status-a;
        border-radius: 12px;
        z-index: 0;
      }

      img,
      video {
        position: relative;
      }
    }
  }
}

.dark-mode {
  .upload-image {
    .photo {
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
}
