/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules';

.user-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $colour-white;
  border-radius: 8px;
  padding: 0.5rem;

  & > * + * {
    margin-left: 1rem;
  }

  &.selectable {
    cursor: pointer;

    &:hover {
      background-color: $colour-grey-6;
    }
  }

  .selected {
    background: $colour-grey-1;
    opacity: 0.8;
    box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1);
    border-radius: 100px;
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $colour-white;
  }

  img {
    height: 3rem;
    width: 3rem;
    border-radius: 15px;
  }

  &-name {
    color: $global-blue;
    font-size: 1.25rem;
    line-height: 26px;
    font-weight: 500;
    flex-basis: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    overflow: hidden;
    white-space: nowrap;

    span {
      font-size: 0.875rem;
      line-height: 20px;
      color: $colour-grey-1;
    }
  }

  &-text {
    font-size: 0.875rem;
    flex-basis: 100px;
  }

  &-icon {
    display: flex;
    font-size: 0.875rem;
    flex-basis: 150px;
    & > * + * {
      margin-left: 6px;
    }
    align-items: center;
  }

  &-email {
    font-size: 0.875rem;
    flex-grow: 1;
    flex-basis: 200px;

    @media (max-width: $mobile-max-width) {
      display: none;
    }
  }

  &-button {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin-right: 10px;
    @extend .button-default;
    background-color: transparent;
    flex-basis: 105px;
    font-weight: 500;
    border-radius: 4px;
    text-decoration: underline;
    font-size: 0.875rem;
    height: 2rem;

    & > * + * {
      margin-left: 2px !important;
    }

    &:hover {
      background-color: $colour-interactions-c;
    }
  }

  &-delete {
    &.dot-button {
      color: $colour-negative-status-a;
      &:hover {
        background-color: $colour-negative-status-c;
      }
    }
  }

  &-edit {
    &.dot-button {
      color: $global-blue;
      &:hover {
        color: $colour-white;
      }
    }
  }
}
