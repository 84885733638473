/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

$colour-positive-status-a: #257b38;
$colour-positive-status-b: #d2f7e1;
$colour-urgent-status-a: #ffd43a;
$colour-urgent-status-b: #ffea4d;
$colour-negative-status-a: #c2445b;
$colour-negative-status-b: #f76f6f;
$colour-negative-status-c: #f9d7dd;

$colour-critical: #5c2330;

$DEVICE_LARGE_MIN_HEIGHT: 1000px;
$DEVICE_LARGE_MIN_WIDTH: 1366px;

$colour-grey-1: #191e1f;
$colour-grey-2: #505366;
$colour-grey-3: #b8bdd4;
$colour-grey-4: #c7cce6;
$colour-grey-5: #f2f4fa;
$colour-grey-6: #e5e8f4;
$colour-grey-7: #a2abad;
$colour-grey-8: #85898c;
$colour-white: #ffffff;
$colour-black: #000000;

$colour-interactions-a: #2c7691;
$colour-interactions-b: #235e73;
$colour-interactions-c: #56b8db;

$colour-self: #3b9fc2;

$global-blue: #01b5c5;
$global-grey: rgb(44, 61, 63);
$button-blue: $colour-interactions-a;
$sidebar-bg: #0b171a;
$bottombar-bg: #0b171a;
$dark-border: $colour-grey-2;
$icon-border: #0c0d0e;
$card-status: $colour-grey-1;

$light-border: $colour-grey-4;
$input-bg-dark: rgb(0, 0, 0);
$input-bg-light: rgb(255, 255, 255);
$unit-breadcrumb: #2b3334;

$orders-bg: #f3f7f7;
$tasks-bg: #ffffff;
$chat-bg: #fcfdfe;

$font-family: 'Barlow', sans-serif;
$code-font-family: source-code-pro, Menlo, Monaco, Consolas, monospace;

$gradient-interactions: linear-gradient(268.41deg, #3b9fc2 0%, #2c7690 52.85%);

$mobile-max-width: 1280px;
$ipad-port-max-width: $mobile-max-width;
:root {
  --sidebar-width: 26.25rem;
}
:root {
  --half-screen-sidebar-width: 50rem;
}
:root {
  --bottombar-height: 26.25rem;
}
:root {
  --bottombar-minimized-height: 5rem;
}

$nav-width: 5.25rem;
$sidebar-width: var(--sidebar-width);
$half-screen-sidebar-width: var(--half-screen-sidebar-width);
$bottombar-height: var(--bottombar-height);
$bottombar-minimized-height: var(--bottombar-minimized-height);
$banner-height: 2.25rem;
$admin-bar-height: 6rem;
$param-bar-width: 18.75rem;

:root {
  --colour-positive-status-a: #{$colour-positive-status-a};
  --colour-positive-status-b: #{$colour-positive-status-b};
  --colour-urgent-status-a: #{$colour-urgent-status-a};
  --colour-urgent-status-b: #{$colour-urgent-status-b};
  --colour-negative-status-a: #{$colour-negative-status-a};
  --colour-negative-status-b: #{$colour-negative-status-b};

  --colour-critical: #{$colour-critical};

  --colour-grey-1: #{$colour-grey-1};
  --colour-grey-2: #{$colour-grey-2};
  --colour-grey-3: #{$colour-grey-3};
  --colour-grey-4: #{$colour-grey-4};
  --colour-grey-5: #{$colour-grey-5};
  --colour-grey-6: #{$colour-grey-6};
  --colour-grey-7: #{$colour-grey-7};
  --colour-grey-8: #{$colour-grey-8};
  --colour-white: #{$colour-white};
  --colour-black: #{$colour-black};

  --colour-interactions-a: #{$colour-interactions-a};
  --colour-interactions-b: #{$colour-interactions-b};
  --colour-interactions-c: #{$colour-interactions-c};

  --global-blue: #{$global-blue};
  --global-grey: #{$global-grey};
  --button-blue: #{$button-blue};
  --sidebar-bg: #{$sidebar-bg};
  --bottombar-bg: #{$bottombar-bg};
  --dark-border: #{$dark-border};
  --icon-border: #{$icon-border};
  --card-status: #{$card-status};

  --light-border: #{$light-border};
  --input-bg-dark: #{$input-bg-dark};
  --input-bg-light: #{$input-bg-light};
  --unit-breadcrumb: #{$unit-breadcrumb};

  --orders-bg: #{$orders-bg};
  --tasks-bg: #{$tasks-bg};
  --chat-bg: #{$chat-bg};

  --colour-self: #{$colour-self};
  --nav-width: #{$nav-width};
}

.font-positive-status-a {
  color: $colour-positive-status-a;
}
.font-positive-status-b {
  color: $colour-positive-status-b;
}
.font-urgent-status-a {
  color: $colour-urgent-status-a;
}
.font-urgent-status-b {
  color: $colour-urgent-status-b;
}
.font-negative-status-a {
  color: $colour-negative-status-a;
}
.font-negative-status-b {
  color: $colour-negative-status-b;
}

.font-grey-1 {
  color: $colour-grey-1;
}
.font-grey-2 {
  color: $colour-grey-2;
}
.font-grey-3 {
  color: $colour-grey-3;
}
.font-grey-4 {
  color: $colour-grey-4;
}
.font-grey-5 {
  color: $colour-grey-5;
}
.font-white {
  color: $colour-white;
}

.font-interactions-a {
  color: $colour-interactions-a;
}
.font-interactions-b {
  color: $colour-interactions-b;
}
.font-interactions-c {
  color: $colour-interactions-c;
}

.bg-positive-status-a {
  background-color: $colour-positive-status-a;
}
.bg-positive-status-b {
  background-color: $colour-positive-status-b;
}
.bg-urgent-status-a {
  background-color: $colour-urgent-status-a;
}
.bg-urgent-status-b {
  background-color: $colour-urgent-status-b;
}
.bg-negative-status-a {
  background-color: $colour-negative-status-a;
}
.bg-negative-status-b {
  background-color: $colour-negative-status-b;
}

.bg-grey-1 {
  background-color: $colour-grey-1;
}
.bg-grey-2 {
  background-color: $colour-grey-2;
}
.bg-grey-3 {
  background-color: $colour-grey-3;
}
.bg-grey-4 {
  background-color: $colour-grey-4;
}
.bg-grey-5 {
  background-color: $colour-grey-5;
}
.bg-white {
  background-color: $colour-white;
}

.bg-interactions-a {
  background-color: $colour-interactions-a;
}
.bg-interactions-b {
  background-color: $colour-interactions-b;
}
.bg-interactions-c {
  background-color: $colour-interactions-c;
}

/* Button global styles */
.button-default {
  outline: none;
  border: none;
  cursor: pointer;
}

.global-blue-gradient {
  background: #2c7690;
  background: -moz-linear-gradient(
    268.41deg,
    $colour-interactions-c 0%,
    #2c7690 52.85%
  );
  background: -webkit-linear-gradient(
    268.41deg,
    $colour-interactions-c 0%,
    #2c7690 52.85%
  );
  background: linear-gradient(
    268.41deg,
    $colour-interactions-c 0%,
    #2c7690 52.85%
  );
}

.active-blue-gradient {
  background: $colour-interactions-b;
  background: -moz-linear-gradient(
    268.41deg,
    #2c7690 0%,
    $colour-interactions-b 52.85%
  );
  background: -webkit-linear-gradient(
    268.41deg,
    #2c7690 0%,
    $colour-interactions-b 52.85%
  );
  background: linear-gradient(
    268.41deg,
    #2c7690 0%,
    $colour-interactions-b 52.85%
  );
}

.corner-radial-gradient {
  background: $colour-interactions-b;
  background: -moz-radial-gradient(
    53.96% 67.9% at 9.64% 0%,
    rgba($colour-black, 0.5) 0%,
    rgba($colour-black, 0) 100%
  );
  background: -webkit-radial-gradient(
    53.96% 67.9% at 9.64% 0%,
    rgba($colour-black, 0.5) 0%,
    rgba($colour-black, 0) 100%
  );
  background: radial-gradient(
    53.96% 67.9% at 9.64% 0%,
    rgba($colour-black, 0.5) 0%,
    rgba($colour-black, 0) 100%
  );
}

/* Animations */

.sidebar-animation {
  transition: all 0.6s cubic-bezier(0.5, 0, 0, 1);
}

.bottombar-animation {
  transition: all 0.6s cubic-bezier(0.5, 0, 0, 1);
}

.scale-animation {
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}

.button-animation {
  -webkit-transition: background 0.2s ease-in-out,
    background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -moz-transition: background 0.2s ease-in-out,
    background-color 0.2s ease-in-out, border-color 0.2s ease-in-out,
    color 0.2s ease-in-out, opacity 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, color 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
  transition: background 0.2s ease-in-out, background-color 0.2s ease-in-out,
    border-color 0.2s ease-in-out, color 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
}

.fade-animation {
  -webkit-transition: opacity 0.15s ease-in-out, display 0.15s ease-in-out,
    visibility 0.15s ease-in-out;
  -moz-transition: opacity 0.15s ease-in-out, display 0.15s ease-in-out,
    visibility 0.15s ease-in-out;
  -o-transition: opacity 0.15s ease-in-out, display 0.15s ease-in-out,
    visibility 0.15s ease-in-out;
  transition: opacity 0.15s ease-in-out, display 0.15s ease-in-out,
    visibility 0.15s ease-in-out;
}

.fade-in {
  -webkit-transition: opacity 0.5s linear;
  -moz-transition: opacity 0.5s linear;
  -o-transition: opacity 0.5s linear;
  transition: opacity 0.5s linear;
}

@keyframes fade-in-out {
  from {
    opacity: 0.25;
  }
  to {
    opacity: 1;
  }
}
.fade-in-out {
  animation: fade-in-out 1s infinite cubic-bezier(0.36, 0.11, 0.89, 0.32);
  animation-direction: alternate;
}

@function vh($quantity, $offset: 0px) {
  @return calc((var(--vh, 1vh) * #{$quantity}) + #{$offset});
}

@function vw($quantity, $offset: 0px) {
  @return calc((var(--vw, 1vw) * #{$quantity}) + #{$offset});
}

:root {
  --toolbar-size: 3.75rem;
  --team-size: 1.875rem;
  --map-padding: 1.875rem;
  --nav-size-img: 2.625rem;
  --nav-size-svg: 1.5rem;
  --nav-size-text: 0.75rem;
}

$toolbar-size: var(--toolbar-size);
$team-size: var(--team-size);
$map-padding: 1.875rem;
