/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules.scss';

.task-sidebar {
  .content {
    .list {
      display: flex;
      flex-direction: column;
      & > * + * {
        margin-top: 0px;
      }

      margin: 0;
      padding: 0;

      .list-item {
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        align-items: center;

        & > * + * {
          margin-left: 1rem;
        }

        .text {
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 24px;
          /* identical to box height, or 150% */
          letter-spacing: -0.02em;

          /* Grey 1 */
          color: $colour-grey-1;

          display: flex;
          flex-direction: column;

          flex-grow: 1;

          margin: 0;

          span {
            display: block;
            font-style: normal;
            font-weight: normal;
            font-size: 1rem;
            line-height: 24px;
          }
        }

        box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1);
        border-radius: 6px;

        &.no-border {
          border: none;
          box-shadow: none;
        }
      }
    }
    .dot-input {
      input:is([type='time']),
      input:is([type='date']) {
        min-width: 144px;
      }
    }
  }
  .section-body > * {
    margin-top: 20px;
  }
  .section-footer {
    display: flex;
    justify-content: space-between;
    min-height: 63px;
    margin: 0px 20px 10px;

    button {
      width: calc(50% - 15px);
      height: 3.5rem !important;

      &:first-child {
        margin-right: 30px;
      }
    }
  }
}
