/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

.menu-button {
  -webkit-user-select: none;
  user-select: none;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  & > * + * {
    margin-left: 1em;
  }

  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  height: 100%;
  justify-content: center;

  &.nav-mission {
    img {
      margin-left: 10px;
    }
    button {
      margin-right: 10px;
    }
  }

  &.nav-task {
    img {
      margin-left: 10px;
    }
    button {
      margin-right: 10px;
    }
  }

  span {
    /* White */
    color: $colour-white;
  }

  button {
    background: transparent;
    border: none;
    text-decoration: underline;
    height: 100%;
    width: 52.5px;
    /* White */
    color: $colour-white;
  }

  &.light {
    span {
      color: $colour-grey-1;
    }
    button {
      color: $colour-grey-1;
    }
  }

  &.link {
    display: inline-block;

    span {
      color: $colour-grey-1;
    }
    button {
      display: inline-block;
      color: #56b8db;
    }
  }
}

#menu-button-portal {
  .menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 4px;

    min-width: 15rem;

    z-index: 15;
    background-color: $colour-grey-1 !important;

    button {
      padding: 0.75rem 1rem;

      background: transparent;
      border: none;

      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      text-decoration: none;
      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 24px;
      /* identical to box height, or 150% */

      display: flex;
      align-items: center;
      letter-spacing: -0.02em;

      /* White */
      color: $colour-white;
      border-radius: 4px;
      &.left-align {
        justify-content: left;
      }

      &:hover {
        background-color: $colour-grey-2 !important;
      }
    }
  }
  .menu-icon {
    min-width: 21px;
  }
}
