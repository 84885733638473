/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules';

.mission-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .tabs {
    box-shadow: 0px 8px 8px rgba(0, 84, 101, 0.1);
    z-index: 1;
  }

  .privacy-organisation-default {
    padding-bottom: 20px;
    box-shadow: 0px 8px 8px rgba(0, 84, 101, 0.1);
  }

  .expandable {
    margin-left: 0;
    margin-right: 0;
  }

  hr {
    background-color: $colour-grey-4;
    width: 100%;
  }

  .critical {
    color: $colour-white;
    background-color: $colour-negative-status-a;
    margin-top: -10px;
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .text {
      transition: all 0.3s ease-in-out;
    }
    .exit {
      margin: 0;
      color: $colour-black;
    }

    .status {
      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
      & > * + * {
        margin-left: 20px;
      }

      .title {
        padding: 0;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        & > * + * {
          margin-left: 20px;
        }

        span {
          font-style: normal;
          font-weight: normal;
          font-size: 1rem;
          line-height: 24px;
          color: $colour-black;
          text-transform: none;
        }
      }

      .battery {
        width: 96px;
      }
    }

    &.underline {
      border-bottom: 1px solid $colour-grey-4;
    }
  }

  form {
    min-height: unset;

    margin-bottom: 24px;
  }

  .top {
    & > button {
      z-index: 2;
    }

    &:not(.has-back) {
      & > .search {
        margin-top: 24px;
      }
    }

    &.personnel-info {
      display: flex;
      flex-direction: column;
      padding-top: 15px !important;

      & > * + * {
        margin-top: 20px;
      }

      div {
        display: flex;
        & > * + * {
          margin-left: 10px;
        }

        div {
          display: flex;
          flex-direction: column;

          & > * + * {
            margin-top: 0;
          }

          font-style: normal;
          font-weight: 600;
          font-size: 1.75rem;
          line-height: 32px;
          letter-spacing: -0.04em;

          /* Grey 1 */
          color: $colour-grey-1;

          span {
            svg {
              margin-right: 10px;
            }
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0em;

            color: $colour-black;

            display: flex;
            & > * + * {
              margin-left: 6px;
            }
            align-items: center;
          }
        }
      }
      .call-button {
        flex-grow: 1;
        width: calc(50% - 6px);
        font-size: 14px;
        svg {
          padding-left: 10px;
        }
      }
    }
  }

  .blocks {
    & > * + * {
      margin-top: 16px !important;
    }

    .tags {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: center;

      & > * + * {
        margin-left: 10px;
      }

      margin-top: 10px;

      h5 {
        align-self: center;
        text-transform: none;
        font-weight: bold;
        font-size: 1rem;
      }

      .tag {
        position: relative;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        height: 24px;

        padding: 0;

        padding-right: 10px;

        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 1rem;

        & > * + * {
          margin-left: 10px;
        }

        .unit-icon.svg-inline--fa {
          height: 10px;
        }
        span {
          display: inline-block;
        }

        .button {
          padding: 0;
          height: 8px;
        }

        .avatar {
          position: absolute;
          top: -2px;

          box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1) !important;

          border: 2px solid $colour-white !important;
          color: $colour-white !important;

          border-radius: 50% !important;

          font-style: normal;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: -0.01em !important;
        }

        height: 24px;
        border-radius: 12px;
        background-color: $colour-white;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.25);
      }
    }

    .block-panel {
      border-bottom: solid 1px #c7cce5;

      &:last-child {
        border-bottom: none;
      }

      padding: 18px 0 25px 0;

      .panel-head {
        font-size: 1.375rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: -0.88px;

        margin-bottom: 18px;
        img:not(.avatar-img) {
          transition: all 0.3s ease-in-out;
        }
      }

      .panel-sub-head {
        font-size: 1.2rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: -0.88px;

        margin-bottom: 18px;
        img:not(.avatar-img) {
          transition: all 0.3s ease-in-out;
        }
      }

      .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .horizontal-slider {
        width: 100%;
        max-width: 500px;
        margin: auto;
      }
      .thumb {
        cursor: pointer;
        position: absolute;
        z-index: 100;
        background: grey;
        border: 5px solid #3774ff;
        border-radius: 100%;
        display: block;
        box-shadow: 0 0 2px 0;
      }
      .thumb.active {
        background-color: grey;
      }
      .track {
        position: relative;
        background: grey;
      }
      .track.track-0 {
        background: #83a9ff;
      }
      .horizontal-slider .track {
        top: 22px;
        height: 4px;
      }
      .horizontal-slider .thumb {
        top: 19px;
        width: 10px;
        outline: none;
        height: 10px;
        line-height: 38px;
      }
    }
  }

  .content {
    .ScrollbarsCustom-Content {
      padding-left: 20px !important;
      padding-right: 20px !important;
    }

    .list {
      display: flex;
      flex-direction: column;
      & > * + * {
        margin-top: 10px;
      }

      margin: 0;
      padding: 0;

      .list-item {
        display: flex;
        flex-direction: row;

        justify-content: space-between;
        align-items: center;

        & > * + * {
          margin-left: 1rem;
        }

        .text {
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 24px;
          /* identical to box height, or 150% */
          letter-spacing: -0.02em;

          /* Grey 1 */
          color: $colour-grey-1;

          display: flex;
          flex-direction: column;

          flex-grow: 1;

          margin-right: 0;
          margin-top: 0;
          margin-bottom: 0;

          span {
            display: block;
            font-style: normal;
            font-weight: normal;
            font-size: 1rem;
            line-height: 24px;
          }
        }

        box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1);
        border-radius: 6px;

        &.no-border {
          border: none;
          box-shadow: none;
        }
      }

      &.location-info {
        .list-item {
          background-color: transparent;
        }
      }
    }

    .information-block {
      display: flex;
      flex-direction: column;
      & > * + * {
        margin-top: 16px;
      }

      .subheading {
        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: -0.01em;
        color: $colour-grey-1;
        text-transform: uppercase;
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 1rem;
        line-height: 24px;
        color: $colour-black;
        margin: 0;
      }

      img.map {
        filter: drop-shadow(0px 4px 8px rgba(0, 84, 101, 0.1));
        border-radius: 12px;
      }

      button.link {
        color: $colour-interactions-a;
      }
    }
  }

  &.personnel {
    .tab-content {
      .ScrollbarsCustom-Content {
        padding: 0 !important;
      }

      .chat {
        height: vh(100, -292px);

        .messages {
          .ScrollbarsCustom-Content {
            padding: 10px 15px 10px 10px !important;
          }
        }

        .files {
          padding-top: 1em;
        }

        .compose {
          margin-bottom: -15px;
        }
      }
    }
  }

  &.broadcast {
    .dot-button.secondary {
      width: calc(100% - 30px);
      margin: 0 15px;
    }

    .tab-content {
      .ScrollbarsCustom-Content {
        padding: 0 !important;
      }

      .chat {
        height: vh(100, -215px);

        .messages {
          .ScrollbarsCustom-Content {
            padding: 10px 15px 10px 10px !important;
          }
        }

        .files {
          padding-top: 1em;
        }

        .compose {
          margin-bottom: -15px;
        }
      }
    }

    .header {
      display: flex;
      flex-direction: column;
      & > * + * {
        margin-top: 10px;
      }
      width: 100%;
      padding: 20px;
      background-color: $colour-white;

      .top {
        width: 100%;
        display: flex;
        flex-direction: row;
        & > * + * {
          margin-left: 10px;
        }
        justify-content: space-between;
        align-items: center;

        //styleName: Body - 16px;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

        color: $colour-black;

        padding: 0;

        .button {
          margin: 0;
          padding: 0;
          height: 36px;
          width: 36px;
        }
        .exit {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-left: auto;
          @extend .button-default;
          background-color: transparent;
          color: rgba($colour-black, 0.516);

          svg {
            height: 24px;
            width: 24px;
          }
        }
      }
      .bottom {
        width: 100%;
        display: flex;
        flex-direction: row;
        & > * + * {
          margin-left: 10px;
        }
        align-items: center;

        img.mission-broadcast {
          border: solid 6px #e5e8f4;
          border-radius: 50%;
        }

        h2 {
          flex-grow: 1;
          display: flex;
          flex-direction: column;
          & > * + * {
            margin-top: 0;
          }
          font-size: 1.75rem;
          font-style: normal;
          font-weight: 500;
          line-height: 32px;
          letter-spacing: -0.04em;
          text-align: left;

          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          align-self: center;

          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            font-size: 1rem;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
          }
        }
      }
    }
  }

  .remove {
    -webkit-user-select: none;
    user-select: none;
    border: dashed rgba($colour-grey-3, 0.8) 3px;
    border-radius: 8px;
    padding: 1rem;
    color: rgba($colour-grey-3, 0.8);
    margin: 0 20px 20px 20px;

    display: flex;
    & > * + * {
      margin-left: 10px;
    }
    align-items: center;

    &.highlight {
      border: dashed rgba($colour-grey-1, 1) 3px;
      color: rgba($colour-grey-1, 1);
    }
    &.dim {
      border: dashed rgba($colour-grey-3, 0.1) 3px;
      color: rgba($colour-grey-3, 0.1);
    }
  }
}

.user-content {
  height: calc(100% - 290px) !important;
  overflow-x: hidden;
}

.mission-allies {
  .item-container {
    margin: 0 -13px;
  }
}

.has-notification {
  .mission-sidebar {
    &.personnel {
      .tab-content {
        .chat {
          height: vh(100, -335px);
        }
      }

      &.has-personnel-notification {
        .tab-content {
          .chat {
            height: vh(100, -406px);
          }
        }
      }
    }

    &.broadcast {
      .tab-content {
        .chat {
          height: vh(100, -17.75rem);
        }
      }
    }
  }
}

.dark-mode {
  .top.personnel-info {
    .call-button {
      background: transparent !important;
      border-color: #fff !important;
      color: #fff !important;
    }
  }

  .panel-sub-head,
  .panel-head {
    img:not(.avatar-img) {
      transition: all 0.3s ease-in-out;
      filter: invert(100%) sepia(0%) saturate(7490%) hue-rotate(288deg)
        brightness(111%) contrast(103%);
    }
  }
  .text {
    transition: all 0.3s ease-in-out;
  }
}
