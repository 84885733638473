/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import 'modules';

$panel-width: 456px;

@keyframes notifications-panel-animation-in {
  from {
    left: #{$nav-width} - $panel-width;
  }
}

@keyframes notifications-panel-animation-out {
  to {
    left: #{$nav-width} - $panel-width;
  }
}

div.notifications-panel {
  animation: notifications-panel-animation-in 0.6s cubic-bezier(0.5, 0, 0, 1);

  &.pending-remove {
    animation: notifications-panel-animation-out 0.6s cubic-bezier(0.5, 0, 0, 1);
  }

  .exit {
    svg {
      height: 24px;
      width: 24px;
    }
  }

  z-index: 8;
  -webkit-user-select: none;
  user-select: none;

  position: fixed;
  top: 0;
  left: $nav-width;
  height: vh(100);
  width: $panel-width;

  background-color: $colour-grey-1;
  border-radius: 0 12px 12px 0;

  border-left: solid 1px $colour-grey-2;

  .toolbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding: 25px;

    color: $colour-white;
    .button {
      color: $colour-white;
      padding: 0;
      height: 1rem;
      color: $colour-white;
    }

    .mission-info {
      position: unset;
      margin: 0;
      opacity: 1;
      box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1);
      .mission-title {
        color: $colour-white;
      }
    }

    .menu-button {
      & > button {
        text-decoration: none;
        font-style: normal;
        font-weight: 500;
        font-size: 1.375rem;
        line-height: 28px;
        /* or 127% */
        display: flex;
        align-items: center;
        letter-spacing: -0.04em;

        /* White */
        color: $colour-white;
      }

      .menu {
        opacity: 1;
        background-color: $colour-black !important;
      }
    }
  }

  .header {
    display: flex;
    flex-direction: row;

    font-size: 2.25rem;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: -0.03em;
    text-align: left;
    padding: 12px 24px;
    & > * + * {
      margin-left: 15px;
    }

    color: $colour-white;
    & > *:first-child {
      opacity: 0.4;
    }
    & > span {
      flex-grow: 1;
    }
    .button {
      font-size: 2.25rem;
      padding: 0;
      height: 1rem;
      color: $colour-white;
    }
  }

  & > .search-panel {
    background-color: transparent;
    padding: 25px;

    .search {
      width: 100%;
    }
  }

  .filters {
    padding: 0px 24px 24px 24px;
    display: flex;
    flex-direction: row;
    & > * + * {
      margin-left: 15px;
    }
    justify-content: space-between;
    align-items: center;

    .menu-button {
      & > button {
        text-decoration: none;
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.02em;
        text-align: left;
      }

      .menu {
        background-color: $colour-black !important;
      }
    }

    border-bottom: solid 1px $colour-grey-2;
  }

  .status {
    display: flex;
    flex-direction: row;
    & > * + * {
      margin-left: 15px;
    }
    justify-content: space-between;
    align-items: center;
    padding: 25px;

    & > div {
      display: flex;
      flex-direction: row;
      & > * + * {
        margin-left: 15px;
      }
      justify-content: flex-start;

      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 24px;

      color: $colour-white;

      span.new {
        display: flex;
        justify-content: center;
        align-items: center;
        background: linear-gradient(268.41deg, #3b9fc2 0%, #2c7690 52.85%);
        border-radius: 35px;
        padding: 0 10px;
        font-family: Barlow;
        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: -0.01em;

        text-transform: uppercase;

        color: $colour-white;
      }
    }

    .dot-button.link {
      font-style: normal;
      font-weight: normal;
      font-size: 1rem;
      line-height: 24px;
      /* identical to box height, or 150% */
      display: flex;
      align-items: center;
      text-align: center;
      letter-spacing: -0.02em;
      text-decoration-line: underline;

      /* White */
      color: $colour-white;
    }
  }

  .notifications-scroll {
    height: calc(100% - 400px) !important;

    .notifications {
      padding: 0;

      & > div {
        display: flex;
        flex-direction: column;
        & > * + * {
          margin-top: 1px;
        }
      }

      .date {
        margin: 0 25px;
        padding: 16px 0;

        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;
        letter-spacing: -0.01em;

        color: rgba($colour-white, 1);
        text-transform: uppercase;
        border-top: dashed 1px $colour-grey-2;
      }

      .notification {
        cursor: pointer;

        -webkit-transition: background-color 0.15s ease-in-out;
        -moz-transition: background-color 0.15s ease-in-out;
        -o-transition: background-color 0.15s ease-in-out;
        transition: background-color 0.15s ease-in-out;

        &:hover {
          background-color: rgba($colour-white, 0.03);
        }

        &.advisory {
          &:hover {
            background-color: rgba($colour-white, 0.03);
          }
        }

        &.caution {
          &:hover {
            background-color: $colour-urgent-status-a;
          }
        }

        &.warning {
          &:hover {
            background-color: $colour-negative-status-a;
          }
        }

        &.critical {
          &:hover {
            background-color: $colour-negative-status-a;
          }
        }
      }
    }
  }
}

.notification {
  background-color: rgba($colour-white, 0.08);
  margin: 0;
  margin-bottom: 0 !important;
  padding: 0;
  border-radius: 0;
  position: relative;

  &.read {
    .unread-icon {
      opacity: 0;
    }
    background-color: transparent;
  }

  &.caution {
    background-color: $colour-urgent-status-a;
  }

  &.warning {
    background-color: $colour-negative-status-a;
  }

  &.critical {
    background-color: $colour-critical;
  }

  .notification-row {
    padding: 10px 24px 10px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & > * + * {
      margin-left: 15px;
    }

    div.notification-icon {
      position: relative;

      .dot-avatar {
        position: absolute;
        bottom: 0px;
        right: 0px;

        height: 20px !important;
        width: 20px !important;
        .avatar-img {
          height: 20px !important;
          width: 20px !important;
          border-radius: 50% !important;
        }
      }
    }

    div.notification-content {
      flex-grow: 1;
      color: rgba($colour-white, 1);

      display: flex;
      flex-direction: column;

      align-items: flex-start;
      justify-content: center;

      text-align: left;

      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 22px;

      letter-spacing: -0.02em;

      & > div {
        display: flex;
        flex-direction: row;

        align-items: center;

        & > * + * {
          margin-left: 5px;
        }

        img {
          height: 18px;
          width: 18px;
        }
        p {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 200px;
        }

        margin-bottom: 4px;
      }

      strong {
        font-weight: 700;
      }

      span {
        color: rgba($colour-white, 0.5);

        font-size: 0.75rem;
        font-style: normal;
        line-height: 20px;
        letter-spacing: -0.01em;
        text-align: left;
      }
    }

    div.when {
      font-style: normal;
      font-weight: normal;
      font-size: 0.75rem;
      line-height: 20px;
      /* identical to box height, or 167% */
      text-align: right;
      letter-spacing: -0.01em;

      /* White */
      color: rgba($colour-white, 0.5);
    }
  }
}

.notifications-modal {
  z-index: 20;
  position: absolute;
  top: 3rem;
  right: 3rem;
  pointer-events: none;
  -webkit-user-select: none;
  user-select: none;

  & > * + * {
    margin-top: 20px;
  }

  .caller-icon {
    position: relative;
    width: 50px;
    height: 50px;
    .call-button {
      border-radius: 50%;
      border: 2px solid;
      width: 100%;
      height: 100%;
      padding: 11px;
      text-align: center;
      border-color: rgba(255, 255, 255, 0.8);
      background-color: $colour-white;
      color: $colour-black;
      svg {
        width: 24px;
        height: 24px;
      }
    }
    .dot-avatar {
      .avatar,
      img {
        outline: 3px solid $colour-white !important;
        position: absolute;
        top: -17px;
        left: 30px;
        border: none !important;
      }
    }
  }

  .dismiss {
    pointer-events: visible;

    background-color: $colour-grey-1 !important;
    box-shadow: 0px 4px 12px rgba(0, 84, 101, 0.24);
    border-radius: 8px;
    color: $colour-white;

    /* Heading - 12px */
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    /* identical to box height, or 133% */
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: -0.01em;

    text-transform: uppercase;
    padding: 10px;
    border: none;
    width: 100%;
  }

  .notification {
    pointer-events: visible;

    -webkit-transition: right 0.15s ease-in-out;
    -moz-transition: right 0.15s ease-in-out;
    -o-transition: right 0.15s ease-in-out;
    transition: right 0.15s ease-in-out;

    background-color: $colour-grey-1 !important;
    box-shadow: 0px 4px 12px rgba(0, 84, 101, 0.24);
    border-radius: 8px;
    right: 0px;

    &.read {
      right: -800px;
    }

    .exit {
      position: absolute;
      top: -7px;
      right: -7px;

      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $colour-grey-1;
      border: 1px solid rgba($colour-white, 0.2);

      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: 20px;
        height: 20px;
        padding: 0;
        margin: 0;

        color: $colour-white;
        font-size: 0.75rem;
      }
    }

    div.action {
      border-top: solid 1px $colour-grey-2;
      /* ACTION */

      /* White */
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      button {
        color: $colour-white;
        text-transform: uppercase;
        width: 100%;
        height: 36px;

        font-style: normal;
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 1rem;
        /* identical to box height, or 133% */
        letter-spacing: -0.01em;
      }
    }
  }
}

.notifications-banner {
  height: $banner-height;

  display: flex;
  align-items: center;
  justify-content: center;

  background: $colour-grey-6;
  color: $colour-black;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  text-transform: uppercase;

  cursor: pointer;

  & > * + * {
    margin-left: 10px;
  }

  &.advisory {
    background-color: $colour-positive-status-b;
  }

  &.caution {
    background-color: $colour-urgent-status-a;
  }

  &.warning {
    background-color: $colour-negative-status-a;
    color: $colour-white;
  }

  &.critical {
    background: $colour-negative-status-a;
    color: $colour-white;
  }

  &.important {
    background: $colour-urgent-status-a;
    color: $colour-black;
  }

  &.offline {
    background: $colour-grey-6;
    color: $colour-black;
  }

  &.tracked {
    background: $colour-grey-1;
    color: $colour-white;
  }
}

.has-notification {
  .notifications-modal {
    top: calc(var(--map-padding) + #{$banner-height});
  }
  div.notifications-panel {
    top: $banner-height;
    height: vh(100, -$banner-height);
  }
}
