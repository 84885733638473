/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules.scss';

.dot-checkbox {
  display: block;

  position: relative;
  padding-left: 24px;
  cursor: pointer;

  -webkit-user-select: none;
  user-select: none;
}

.dot-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 24px;
  width: 24px;
  background-color: $colour-white;
  border: solid 2px $colour-grey-4;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.dot-checkbox:hover input ~ .checkmark {
  border: solid 2px $colour-grey-4;
  background-color: $colour-interactions-a;
}

.dot-checkbox input:checked ~ .checkmark {
  border: solid 2px $colour-interactions-a;
  background-color: $colour-white;
}

.checkmark:after {
  content: '';
  display: none;
}

.dot-checkbox input:checked ~ .checkmark:after {
  display: block;
}

.dot-checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid $colour-interactions-a;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
