/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

$header-height: 126px;
$footer-height: 126px;
$content-width: 1032px;
$website-mobile-max-width: 1032px + 16px + 16px;

.content-page {
  background-image: url('/assets/content-bg.png');
  background-position: center;
  height: vh(100);
  width: 100%;

  .shape {
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    position: absolute;
    left: 0;
    & > img {
      position: relative;
      top: -36px;
    }
  }

  .mobile-menu {
    display: none;
  }

  .btn-Color {
    //color: $colour-white;
    color: #fff;
    margin-right: 25px;
  }

  .content-menu {
    position: relative;
    width: 100%;
    padding: 32px;

    display: flex;
    &.mobile {
      display: none;
      padding: 16px;
    }

    justify-content: space-between;
    align-items: center;

    .left,
    .right {
      display: flex;
    }

    .left {
      & > * + * {
        margin-left: 24px;
      }

      a,
      .dot-button {
        padding: 4px 12px 4px 12px;
      }
    }
    .right {
      & > * + * {
        margin-left: 11px;
      }

      a,
      .dot-button {
        padding: 4px 33px 4px 33px;
      }
    }

    .logo {
      cursor: pointer;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-size: 1.125rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.22;
      letter-spacing: normal;
      text-align: center;
      color: $colour-white;
      width: 200px;
      max-width: 200px;
      img {
        width: 200px;
        max-width: 200px;
        max-height: 80px;
      }
    }

    a,
    .dot-button {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 1.125rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.22;
      letter-spacing: normal;
      text-align: center;
      color: $colour-white;
      background: transparent;
      border: none;
      text-transform: uppercase;

      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    a.secondary {
      background: transparent !important;
      border: solid 1px $colour-white !important;
      color: $colour-white !important;
      border-radius: 4px;
    }
  }

  .content-foot {
    position: relative;
    width: $content-width;
    margin: 0 auto;
    padding: 32px;

    display: flex;

    justify-content: space-between;
    align-items: center;

    & > * + * {
      margin-left: 11px;
    }

    .logo {
      cursor: pointer;
      display: flex;
      align-items: center;
      text-transform: uppercase;
      font-size: 1.125rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.22;
      letter-spacing: normal;
      text-align: center;
      color: $colour-white;
      width: 200px;
      max-width: 200px;
      img {
        width: 200px;
        max-width: 200px;
        max-height: 50px;
      }
    }

    .dot-button {
      background-color: transparent;
      border: none;

      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      text-align: left;
      color: #fff;

      padding: 4px 33px 4px 33px;

      &:hover {
        text-decoration: underline;
      }
    }
    span {
      opacity: 0.5;
      font-size: 1rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #fff;
    }
  }

  & > .ScrollbarsCustom {
    height: vh(100) !important;
  }

  section {
    width: $content-width;
    margin: 0 auto;

    font-size: 1.125rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #b4d0da;

    padding-left: 251px;

    h1 {
      font-size: 4rem;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: -2.56px;
      text-align: left;
      color: #fff;

      margin-left: -251px;
      margin-top: 75px;
      margin-bottom: 96px;
    }

    h2 {
      font-size: 1.125rem;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: #b4d0da;

      margin-top: 16px;
      margin-bottom: 24px;
    }

    p {
      margin-bottom: 24px;

      & > a {
        color: #b4d0da;
        &:hover {
          font-weight: bold;
        }
      }
    }
  }

  &.home {
    & > .ScrollbarsCustom {
      height: vh(100) !important;
    }

    section {
      padding: 0;
      h1 {
        margin-left: 0;
      }
    }

    a.enquire {
      background: $colour-white;
      border-radius: 4px;
      border: none;
      font-style: normal;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 40px;
      /* identical to box height, or 222% */

      text-decoration: none;

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      color: #2c7690;

      width: 331px;
      height: 60px;

      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1),
        0px 42px 42px rgba(0, 0, 0, 0.1),
        0px 12.6618px 12.6618px rgba(0, 0, 0, 0.1),
        0px 5.25905px 5.25905px rgba(0, 0, 0, 0.05);

      margin-left: auto;
      margin-right: auto;
      margin-bottom: 76px;
      margin-top: 60px;

      &:hover {
        background: linear-gradient(268.41deg, #3b9fc2 0%, #3b9fc2 52.85%);
      }
    }
    .details-section {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      .left,
      .right {
        flex: 45%;
        h3 {
          color: $colour-white;
          font-weight: 600;
          font-size: 22px;
          line-height: 28px;
          letter-spacing: -0.02em;
        }
      }
      .left {
        padding-right: 40px;
      }
      .details {
        padding-bottom: 20px;
      }
    }

    .details-box-section {
      width: 1032px;
      margin: 0 auto;
      .section {
        display: flex;
        .box {
          flex: 240px;
          text-align: center;
          margin: 0 0 24px 24px;
          padding: 20px;
          min-width: 240px;
          background: #202c38;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1),
            0px 42px 42px rgba(0, 0, 0, 0.1),
            0px 12.6618px 12.6618px rgba(0, 0, 0, 0.1),
            0px 5.25905px 5.25905px rgba(0, 0, 0, 0.05);
          border-radius: 4px;
          h3 {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: -0.02em;
            color: #56b8db;
            margin-top: 20px;
          }
          .contents {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #b4d0da;
            margin-top: 10px;
          }
          .dotted-border {
            margin-top: 28px;
            display: inline-block;
            width: 99px;
            height: 99px;
            border: 1px dashed #56b8db;
            border-radius: 50%;
            padding: 8px;
            .bordered {
              width: 81px;
              height: 81px;
              background: rgba($color: #56b8db, $alpha: 0.1);
              border-radius: 50%;
              padding: 10px;
              .circle-container {
                width: 60px;
                height: 60px;
                background: #56b8db;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }

    .mobile-app {
      width: 100%;

      background: rgba(11, 23, 26, 0.8);
      /* Smooth Shadow */

      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1),
        0px 42px 42px rgba(0, 0, 0, 0.1),
        0px 12.6618px 12.6618px rgba(0, 0, 0, 0.1),
        0px 5.25905px 5.25905px rgba(0, 0, 0, 0.05);
      border-radius: 8px;

      display: flex;
      align-items: center;

      padding: 16px;

      font-style: normal;
      font-weight: bold;
      font-size: 1.125rem;
      line-height: 28px;

      color: #ffffff;

      justify-content: space-between;

      .buttons {
        display: flex;

        & > * + * {
          margin-left: 16px;
        }

        img {
          height: 50px;
        }
      }
    }

    .body {
      width: $content-width;
      margin: 0 auto;

      display: flex;
      & > * + * {
        margin-left: 57px;
      }

      .left {
        font-style: normal;
        font-weight: 500;
        font-size: 2rem;
        font-stretch: normal;
        line-height: 1.5;
        letter-spacing: -1.28px;

        color: #56b8db;
      }

      .right {
        opacity: 0.7;
        font-size: 1.125rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: left;

        color: #b4d0da;

        p {
          margin-bottom: 24px;
        }
      }
    }

    h2 {
      font-style: normal;
      font-weight: normal;
      font-size: 3rem;
      line-height: 58px;
      /* identical to box height */

      letter-spacing: -0.04em;

      /* White */

      color: #ffffff;

      margin: 95px auto 79px auto;
      text-align: center;
    }

    .grid {
      width: $content-width;
      margin: 0 auto;
      display: flex;

      margin-bottom: 24px;

      & > * + * {
        margin-left: 24px;
      }
      & > div {
        height: 316px;
        width: 240px;

        background: #202c38;
        /* Smooth Shadow */

        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1),
          0px 42px 42px rgba(0, 0, 0, 0.1),
          0px 12.6618px 12.6618px rgba(0, 0, 0, 0.1),
          0px 5.25905px 5.25905px rgba(0, 0, 0, 0.05);
        border-radius: 4px;

        padding: 20px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        & > * + * {
          margin-top: 16px;
        }

        p {
          font-style: normal;
          font-weight: normal;
          font-size: 1rem;
          line-height: 24px;
          /* or 150% */

          text-align: center;

          color: #b4d0da;
        }

        p.strong {
          font-style: normal;
          font-weight: 600;
          font-size: 1rem;
          line-height: 24px;
          /* identical to box height, or 150% */

          letter-spacing: -0.02em;

          color: #56b8db;
        }
      }
    }

    .hero {
      background-image: url('/assets/content-hero.png');
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: cover;
      height: 1000px;
      margin-bottom: 18.75rem;

      section {
        h1 {
          margin-top: 75px;
          margin-bottom: 32px;

          font-style: normal;
          font-weight: normal;
          font-size: 4rem;
          line-height: 77px;
          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: -0.04em;

          color: #ffffff;

          text-shadow: 0px 0px 21.4286px #217f8f;
        }

        & > img {
          margin-left: -70px;
          width: calc(100% + 140px);
          max-width: $content-width;
        }
      }
    }

    .footer {
      position: relative;
      width: 100%;
      .footer-bg {
        position: absolute;
        top: 0;
        width: 100%;
        height: 0px;
        content: '';
        background-image: url('/assets/content-foot.png');
        mix-blend-mode: overlay;
        background-position: center top;
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 1;
      }

      .foot-container {
        width: vw(100);

        .foot-body {
          z-index: 2;
          position: absolute;
          left: calc(#{vw(100, -#{$content-width})} / 2);
          width: $content-width;
          margin: 0 auto;
          margin-top: 180px;
          display: flex;

          flex-direction: column;
          align-items: center;
          justify-content: flex-start;

          h3 {
            /* Empower your team today */

            font-style: normal;
            font-weight: normal;
            font-size: 3.5rem;
            line-height: 3.75rem;
            /* identical to box height */
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -0.04em;

            /* White */
            color: #ffffff;
            margin-top: 32px;
            margin-bottom: 20px;
          }
          p {
            font-style: normal;
            font-weight: normal;
            font-size: 1.125rem;
            line-height: 28px;
            /* identical to box height, or 156% */
            text-align: center;

            color: #b4d0da;

            opacity: 0.7;
          }

          .enquire {
            margin-top: 42px;
            margin-bottom: 119px;
          }

          .mobile-app {
            margin-bottom: 73px - 32px;
          }
        }
      }
    }
  }
}

.to-top {
  position: fixed;
  bottom: 35px;
  right: 35px;
  cursor: pointer;
}

@media (max-width: $website-mobile-max-width) {
  .to-top {
    display: none;
  }

  .content-page {
    background: #10171d;

    .mobile-menu {
      display: flex;
    }

    .content-menu.desktop {
      display: none;
    }
    .content-menu.mobile {
      display: flex;
      z-index: 4;
    }
    .mobile-menu {
      z-index: 3;

      position: fixed;
      top: 0;
      left: 0;
      height: vh(100);
      width: 100%;
      background: #10171d;

      display: flex;
      flex-direction: column;

      padding: 24px 24px 96px 24px;

      justify-content: center;

      div {
        display: flex;
        flex-direction: column;
      }

      a,
      .dot-button {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 1.125rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 2.22;
        letter-spacing: normal;
        text-align: center;
        color: $colour-white;
        background: transparent;
        border: none;
        text-transform: uppercase;

        padding: 4px 33px 4px 33px;
        text-decoration: none;
      }

      a.secondary {
        background: transparent !important;
        border: solid 1px $colour-white !important;
        color: $colour-white !important;
        border-radius: 4px;
      }
    }
    section {
      width: vw(100, -32px);
      padding: 0;

      h1 {
        margin-left: unset;
        margin-bottom: 20px;
        font-size: 2.5rem;
        line-height: 48px;
      }
    }

    &.home {
      .hero {
        background-size: cover;
        height: 700px;
        margin-bottom: 350px;

        section {
          h1 {
            font-weight: 400;
            font-size: 40px;
            line-height: 48px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: -0.04em;

            text-shadow: 0px 0px 21.4286px #217f8f;

            margin-top: 35px;
          }

          & > img {
            margin-top: calc(100px - 10vw);
            margin-left: 16px;
            width: vw(100, -32px);
          }
        }
      }

      a.enquire {
        width: 100%;
        margin-bottom: 45px;
        margin-top: 50px;
      }

      .body {
        width: vw(100, -32px);
        margin: 0 18px;

        flex-direction: column;
        & > * + * {
          margin-left: 0;
          margin-top: 16px;
        }

        .left {
          font-weight: 500;
          font-size: 28px;
          line-height: 36px;
          letter-spacing: -0.04em;
          padding: 0px;
          margin-bottom: 28px;
        }

        .right {
          font-style: normal;
          font-size: 22px;
          line-height: 28px;
          letter-spacing: -0.02em;
        }
      }

      .mobile-app {
        flex-direction: column;
        text-align: center;

        .buttons {
          margin-top: 16px;
          justify-content: center;
          & > * + * {
            margin-left: 0;
          }
          & > * + * {
            margin-top: 16px;
          }
          flex-wrap: wrap;
        }
      }

      h2 {
        margin: 63px auto 57px auto;
      }

      .grid {
        width: vw(100, -32px);
        flex-direction: column;
        margin-bottom: 8px;
        & > * + * {
          margin-left: 0;
          margin-top: 8px;
        }
        div {
          width: vw(100, -32px);
          margin-left: auto;
          margin-right: auto;
        }
      }

      .footer {
        width: vw(100);

        .foot-container {
          width: vw(100);

          .foot-body {
            left: 16px;
            width: vw(100, -32px);
            margin-top: 30px;

            h3 {
              font-size: 2.5rem;
              line-height: 48px;
              letter-spacing: -0.04em;
              margin: 32px 32px 20px;
            }
            p {
              margin: 0 32px;
            }

            .enquire {
              margin-top: 24px;
              margin-bottom: 42px;
            }

            .mobile-app {
              margin-bottom: 52px - 32px;
            }
          }
        }
      }

      .details-section {
        display: block;
      }

      .details-box-section {
        width: 100%;
        margin: 0 auto;
        .section {
          display: block;
          .box {
            margin: 0 24px 24px 24px;
          }
        }
      }
    }

    .content-foot {
      width: vw(100, -32px);
      flex-direction: column;
      & > * + * {
        margin-top: 24px;
      }
    }
  }
}

@media (max-width: 700px) {
  .content-page {
    &.home {
      .hero {
        margin-bottom: 150px;
      }
    }
  }
}
