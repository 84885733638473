/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty 
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any 
 * licence may have from the copyright owner to use the Software, you must not copy, 
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to 
 * derive the source code of or modify the Software, nor create any derivative works of 
 * the Software.
 */

@import '../../../modules';

// Define grid areas
.org {
  overflow-x: hidden;
  &-container {
    overflow-x: hidden;
    width: 100%;
    height: vh(100);

    display: grid;
    grid-template-columns: $nav-width 1fr;
    grid-template-areas: 'sidebar org-middle';

    min-width: 780px;
  }

  &-middle {
    grid-area: org-middle;
    display: grid;
    grid-template-rows: 90px 1fr;
    grid-template-areas: 'topbar' 'org-content';
    overflow-x: hidden;
  }
}

.has-notification {
  .org {
    &-container {
      grid-template-rows: vh(100, -#{$banner-height});
    }
  }
}

.org-content {
  overflow-y: hidden;
  grid-area: org-content;
  // padding-left: 70px;
  // padding-right: 70px;
  // padding-top: 50px;
  padding-bottom: 100px;

  &-row2 {
    overflow-y: auto;
    height: 100%;
  }

  &-count-title {
    font-size: 1.25rem;
    font-weight: 500;
    color: $colour-white;
    letter-spacing: 1px;
    margin-left: 10px;
  }

  &-row1 {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
  }
  // Padding required to allow hover scale animation
  &-row2 {
    margin-top: 30px;

    .ScrollbarsCustom-Content {
      padding-left: 20px !important;
      padding-right: 20px !important;
      height: 100%;
    }
  }
}

.no-orgs {
  background: rgba(11, 23, 26, 0.9);
  backdrop-filter: blur(8px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 16px;
  padding: 36px;
  margin: 20px;

  display: flex;
  justify-content: center;
  align-items: center;

  .dot-button {
    background-color: transparent;
    border: none;
    color: $colour-white;
  }
}
