/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules';

.dark-mode {
  .chat {
    .attachment {
      .button {
        color: #191e1f;
      }
    }
    .files {
      .file {
        border-top: none;
        border-bottom: none;
        button.exit {
          background-color: white !important;
          color: black !important;
        }
        .text {
          color: white;
          .filename {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 170px;
          }
          span {
            color: white;
          }
        }
        .right {
          color: white;
        }
      }
    }
  }
}

.chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .new-messages-button {
    position: absolute;
    right: 5px;
    bottom: 125px;

    border: solid 2px $colour-interactions-a;
    border-radius: 50%;
    width: 32px;
    height: 32px;
    background: $colour-white;
    color: $colour-interactions-a;

    &:hover {
      border-color: $colour-interactions-c;
      color: $colour-interactions-c;
      background: $colour-grey-5;
    }
  }

  .attachment {
    background: $colour-white;
    border: 1px solid $colour-grey-4;
    box-sizing: border-box;
    border-radius: 6px;
    padding: 10px;

    display: flex;
    flex-direction: row;

    align-items: center;
    & > * + * {
      margin-left: 10px;
    }

    .button {
      height: 48px;

      div.icon {
        height: 48px;
        width: 48px;
        min-width: 48px;
        background: $colour-grey-5;
        border-radius: 3px;

        display: flex;
        justify-content: center;
        align-items: center;
        text-align: left;

        svg {
          height: 36px;
          width: 36px;
        }
      }

      div.text {
        display: flex;
        flex-direction: column;

        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: $colour-grey-1;
        text-align: left;

        .filename {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 225px;
        }

        span {
          font-style: normal;
          font-weight: normal;
          font-size: 0.75rem;
          line-height: 20px;
          letter-spacing: -0.01em;
          color: $colour-grey-1;
        }
      }
    }

    img {
      width: 100%;
    }
  }

  .date {
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
    letter-spacing: -0.01em;
    color: $colour-grey-2;
    text-transform: uppercase;

    margin-top: 16px;
  }

  .files {
    .file {
      border-top: solid 1px $colour-grey-5;
      border-bottom: solid 1px $colour-grey-5;
      padding: 15px;

      display: flex;
      justify-content: space-between;
      align-items: center;
      & > * + * {
        margin-left: 10px;
      }

      & > svg {
        height: 24px;
        width: 24px;
      }

      .left {
        .censored-text {
          font-size: 0.375rem;
          top: 50%;
        }

        .img {
          height: 50px;
          width: 50px;
          background-color: black;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
        }
        div.icon {
          svg {
            height: 36px;
            width: 36px;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
          }
        }
      }

      div.text {
        display: flex;
        flex-direction: column;

        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 24px;
        letter-spacing: -0.02em;
        color: $colour-grey-1;
        text-align: left;

        .filename {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          max-width: 170px;
        }

        span {
          font-style: normal;
          font-weight: normal;
          font-size: 0.75rem;
          line-height: 20px;
          letter-spacing: -0.01em;
          color: $colour-grey-1;
        }
      }

      .right {
        font-style: normal;
        font-weight: normal;
        font-size: 0.75rem;
        line-height: 20px;
        letter-spacing: -0.01em;
        color: $colour-grey-1;

        display: flex;
        & > * + * {
          margin-left: 10px;
        }
        align-items: center;
        text-align: right;
      }
    }
  }

  .messages {
    background-color: $colour-white;
    box-shadow: inset 0px -4px 8px -4px rgba(0, 84, 101, 0.24);

    flex-grow: 1;

    display: flex;
    flex-direction: column;
    & > * + * {
      margin-top: 10px;
    }

    .ScrollbarsCustom-Content {
      flex-direction: column !important;

      & > div {
        display: flex;
        flex-direction: column;
        & > * + * {
          margin-top: 16px;
        }
      }
      padding: 10px 20px !important;
    }

    .message {
      background-color: transparent;

      display: flex;
      flex-direction: row;
      & > * + * {
        margin-left: 15px;
      }

      justify-content: space-between;
      align-items: flex-end;

      .dot-avatar {
        .avatar {
          border-radius: 50%;
          box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1);
        }
        .avatar-img {
          border-radius: 50%;
          box-shadow: 0px 4px 8px rgba(0, 84, 101, 0.1);
        }
      }

      .bubble {
        flex-grow: 1;
        background: #ffffff;
        border: 1px solid $colour-grey-4;
        box-sizing: border-box;
        border-radius: 12px;
        padding: 10px 12px 12px 12px;

        display: flex;
        flex-direction: column;
        & > * + * {
          margin-top: 5px;
        }

        &.you {
          background: $colour-grey-5;
          border: none;
        }

        .sender {
          font-size: 0.75rem;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: -0.01em;
          text-align: left;

          display: flex;
          & > * + * {
            margin-left: 5px;
          }
        }

        .bubble-message {
          overflow-wrap: break-word !important;
          word-wrap: break-word !important;
          hyphens: auto !important;
          max-width: 318px;
        }
      }

      &.failed {
        .bubble {
          border: 1px solid $colour-negative-status-a;
        }
      }

      .notification {
        width: 100%;
        background-color: transparent;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
        & > * + * {
          margin-left: 8px;
        }
        font-size: 0.8em;
      }

      .critical-notification {
        width: 100%;

        .critical-message {
          background: rgba($colour-negative-status-c, 0.4);
          border-radius: 12px;
          width: 100%;
          padding: 24px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          div.notification-icon {
            position: relative;

            .dot-avatar {
              position: absolute;
              bottom: 0px;
              right: 0px;

              height: 20px !important;
              width: 20px !important;
              .avatar-img {
                height: 20px !important;
                width: 20px !important;
                border-radius: 50% !important;
              }
            }
          }

          span.time {
            font-style: normal;
            font-weight: normal;
            font-size: 0.75rem;
            line-height: 20px;
            letter-spacing: -0.01em;

            color: $colour-grey-2;
          }
        }

        .message-player {
          display: flex;
          justify-content: center;
          align-items: flex-end;
        }
      }
    }

    .typing {
      padding-top: 15px;

      background-color: transparent;

      & > img {
        height: 10px;
        width: 38px;
      }
    }

    .read-receipts {
      padding-top: 12px;
      display: flex;
      flex-direction: row !important;
      align-items: center;
      justify-content: flex-end;
      & > * + * {
        margin-left: 6px !important;
        margin-top: 0 !important;
      }
    }
  }

  .compose {
    flex-grow: 1;

    .compose-help {
      display: flex;
      justify-content: space-between;

      & > div {
        font-size: 0.625rem;
        display: flex;
        & > * + * {
          margin-left: 10px;
        }
        align-items: center;
        justify-content: flex-end;
      }
    }

    .compose-container {
      width: 100%;
      margin: 0;
      flex-grow: 1;

      position: relative;

      flex-shrink: 0;
      padding: 24px 20px 8px;

      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;

      display: flex;
      flex-direction: column;

      $button-width: 36px;

      & > * + * {
        margin-top: 5px;
      }

      .buttons {
        position: absolute;
        top: 0px;
        left: calc(100% - #{$button-width * 3});

        display: flex;
        .button {
          margin: 0;
          padding: 0;
          height: $button-width;
          width: $button-width;
        }
      }

      .grow-textarea {
        width: 100%;
        height: 100%;
        border: none;
        min-height: 57px;
        max-width: 418px;

        &:active {
          border: none;
        }
        &:focus {
          border: none;
          outline: none !important;
        }
        &:focus-visible {
          border: none;
          outline: none !important;
        }
      }

      .files {
        display: flex;
        flex-direction: row;

        & > * + * {
          margin-left: 12px;
        }

        .file {
          position: relative;
          .warning {
            display: none;
          }

          img,
          video {
            height: 72px;
            width: 72px;
            filter: drop-shadow(0px 4px 8px rgba(0, 84, 101, 0.1));
            border-radius: 6px;
            overflow: hidden;
          }

          div.upload {
            display: flex;
            align-items: center;
            height: 36px;
            width: 100%;
            filter: drop-shadow(0px 4px 8px rgba(0, 84, 101, 0.1));
            border-radius: 6px;
            overflow: hidden;
            .left {
              margin-right: 1em;
              svg {
                height: 36px;
                width: 36px;
              }
            }
            .right {
              text-align: left;
              .text {
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                max-width: 225px;
              }
            }
          }

          &.invalid {
            position: relative;
            height: 72px;
            width: 72px;

            .warning {
              left: 24px;
              top: 24px;
              position: absolute;
              display: block;
              height: 24px;
              width: 24px;
              background-color: $colour-white;
              color: $colour-black;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 1;
            }

            &:after {
              position: absolute;
              content: '';
              background-color: rgba($colour-negative-status-a, 0.3);
              height: 72px;
              width: 72px;
              border: solid 3px $colour-negative-status-a;
              border-radius: 6px;
              z-index: 0;
            }

            img,
            video {
              position: absolute;
            }
          }

          .exit {
            background-color: $colour-black;
            color: $colour-white;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            position: absolute;
            right: -5px;
            top: -5px;
            z-index: 1;
          }
        }
      }
    }
  }
}
