/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../../modules';

$header-height: 200px;

.org-edit {
  width: 100%;
  height: 100%;

  & > * + * {
    margin-top: 10px;
  }

  & > .pop-out-sidebar {
    margin-top: 0 !important;
  }

  &-panel {
    background-color: $colour-white;
    border-radius: 12px;
    margin-bottom: 10px;
    padding-bottom: 20px;
    overflow: hidden;

    .header {
      position: relative;
      height: $header-height;

      .banner {
        display: block;
        background: linear-gradient(90.47deg, #3293a4 0%, #216882 100%);
        content: '';
        height: $header-height / 2;
        width: 100%;
        overflow: hidden;

        svg {
          position: absolute;
          width: 100%;
          height: $header-height / 2;
        }
      }

      .dot-avatar {
        position: absolute;
        left: 20px;
        top: calc(#{$header-height / 2} - (125px / 2));
      }

      .dot-button {
        position: absolute;
        bottom: 10px;
        right: 20px;
      }
    }

    .content {
      padding: 0 20px;

      h2 {
        font-weight: 600;
        font-size: 3rem;
        line-height: 3.25rem;
        letter-spacing: -0.03em;
        color: $colour-grey-1;

        margin: 0 0 1rem 0;
      }

      h3 {
        font-weight: 600;
        font-size: 1.375rem;
        line-height: 1.625rem;
        letter-spacing: -0.04em;
        color: $colour-grey-1;

        margin-bottom: 1rem;
      }

      p {
        font-size: 1rem;
        line-height: 1.25rem;
      }

      hr {
        margin: 20px -20px;
        border-top: 1px solid $colour-grey-4;
      }

      .location {
        & > * + * {
          margin-left: 0.5rem;
        }
      }

      .row {
        display: flex;
        flex-direction: row;

        .col-60 {
          width: 60%;
        }

        & > * + * {
          margin-left: 0.5rem;
        }
      }

      .col {
        display: flex;
        flex-direction: column;
        & > * + * {
          margin-top: 0.5rem;
        }
      }
    }
  }

  &-integrations {
    display: flex;
    flex-direction: column;
    background-color: $colour-white;
    border-radius: 12px;
    margin-bottom: 10px;
    padding-bottom: 20px;
    overflow: hidden;

    .header {
      display: flex;
      flex-direction: row;

      justify-content: flex-start;
      align-items: center;
      padding: 20px;

      & > * + * {
        margin-left: 1rem;
      }

      h2 {
        font-weight: 600;
        font-size: 3rem;
        line-height: 3.25rem;
        letter-spacing: -0.03em;
        color: $colour-grey-1;
      }

      .icon {
        display: flex;
        flex-direction: row;

        height: 3.75rem;
        width: 3.75rem;
        background-color: #c0d6de;
        border-radius: 50%;

        justify-content: center;
        align-items: center;
      }
    }

    .content {
      padding: 0 20px;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      margin: -1rem 0 0 -1rem;

      & > * {
        margin-left: 1rem;
        margin-top: 1rem;
      }

      .integration {
        @extend .scale-animation;
        display: flex;
        flex-direction: column;

        justify-content: space-between;

        padding: 1rem;

        img.logo {
          height: 3.75rem;
        }

        &.new {
          align-items: center;
          justify-content: center;
        }
        border: 1px solid $colour-grey-5;
        background: $colour-white;
        min-height: 15rem;

        width: calc(25rem);

        border-radius: 12px;

        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1),
          0px 42px 42px rgba(0, 0, 0, 0.1),
          0px 12.6618px 12.6618px rgba(0, 0, 0, 0.1),
          0px 5.25905px 5.25905px rgba(0, 0, 0, 0.05);

        cursor: pointer;

        & > .header {
          padding: 0;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
        & > .body {
          & > .heading {
            font-size: 1.375em;
            line-height: 1.625em;
            font-weight: 600;
            letter-spacing: -0.04em;
            margin-bottom: 0.5rem;
          }
        }
      }
    }
  }
}

.details-panel {
  padding: 0 15px;
  overflow-y: auto;
  flex-grow: 1;

  // Hide scrollbar
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  h4 {
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;
  }

  h5 {
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1rem;
    letter-spacing: -0.01em;
    text-align: left;

    text-transform: uppercase;
    margin-top: 30px;
  }

  ul,
  p {
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
  }

  ul {
    li.disabled {
      color: $colour-grey-4;
    }
  }

  .details {
    border-top: 1px solid $light-border;
  }

  .row {
    border-top: 1px solid $light-border;
    border-bottom: 1px solid $light-border;

    padding: 10px 0;

    display: flex;
    flex-direction: row;

    & > * + * {
      margin-left: 10px;
    }
  }

  .columns {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .col {
      flex-grow: 1;
      flex-basis: calc((420px - 20px) / 2 - 10px);
      padding: 0 15px;
    }
  }
}

.Your-data-sharing-default {
  width: 276px;
  height: 24px;
  flex-grow: 0;
  margin: 10px 38px 13px 3px;
  font-family: Barlow;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.32px;
  text-align: left;
  color: $colour-black;
}

.privacy-panel {
  flex-grow: 0;
  margin: 13px 0;
  padding: 12px 0 0 0;
  border-radius: 8px;
  box-shadow: 0 4px 12px 0 rgba(0, 84, 101, 0.24);
  background-color: #fff;
  overflow: hidden;

  .heading {
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 12px 0;

    & > * + * {
      margin-left: 12px;
    }
    border-bottom: solid 1px #c7cce5;

    svg {
      height: 24px;
      width: 24px;

      margin: 12px;
    }

    & > div {
      height: 24px;
      flex-grow: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      & > span {
        height: 24px;
        flex-grow: 0;
        font-family: Barlow;
        font-size: 1rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.32px;
        text-align: left;
        color: #191e1f;
      }
    }
  }

  .foot {
    flex-grow: 0;
    margin: 51px 0 0;
    padding: 15px 23px 29px;
    background-color: $colour-grey-5;

    span {
      width: 269px;
      height: 24px;
      flex-grow: 0;
      margin: 0 59px 12px 0;
      font-size: 1rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.5;
      letter-spacing: -0.32px;
      text-align: left;
    }
  }
}

.privacy-content {
  padding: 20px 24px;

  .row {
    display: flex;
    flex-direction: row;
    border: none !important;
    padding: 0;

    & > .column {
      display: block;
      padding: 0;

      .identity {
        width: 123px;
        height: 24px;
        flex-grow: 0;
        margin: 0 18px 10px 0;
        font-size: 1rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: -0.32px;
        text-align: left;
      }

      .visible {
        width: 78px;
        height: 24px;
        flex-grow: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        & > * + * {
          margin-left: 4px;
        }
        margin: 10px 63px 11px 0;
        padding: 0 6px 0 2px;
        border-radius: 2px;
        background-color: $colour-grey-5;

        .text {
          width: 42px;
          height: 16px;
          flex-grow: 0;
          font-size: 0.75rem;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: -0.12px;
          text-align: left;
          text-transform: uppercase;
        }
      }

      & > ul {
        margin: 0;
        padding: 0 0 0 24px;
      }
    }
  }
}
