/**
 * © Copyright 2022. This software is protected by copyright, owned by Insitec MIS Pty 
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any 
 * licence may have from the copyright owner to use the Software, you must not copy, 
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to 
 * derive the source code of or modify the Software, nor create any derivative works of 
 * the Software.
 */

@import '../../modules';

.tabs {
  width: 100%;
  height: 60px;
  position: relative;

  button,
  .slider {
    background: $colour-white;

    display: inline-block;
    font-style: normal;
    font-weight: normal;
    text-align: center;
    background: $colour-white;
    color: $colour-grey-2;
    width: 150px;
    height: 100%;
    padding: 20px 0px;
  }

  button {
    border: none;
    white-space: nowrap;
  }

  button:hover {
    color: $colour-black;
    font-weight: 600;
    cursor: pointer;
  }

  button.is-active {
    color: $colour-black;
    font-weight: 600;
  }

  .slider {
    background-color: transparent;
    position: absolute;
    top: 0;
    border-bottom: 4px solid $colour-interactions-a;
    transition: transform 0.5s;
    pointer-events: none;
  }

  &.dark {
    background-color: transparent;

    button {
      background-color: transparent;
      font-weight: 500;
      color: $colour-grey-7;
    }

    button:hover {
      color: $colour-white;
      font-weight: 600;
      cursor: pointer;
    }

    button.is-active {
      color: $colour-white;
      font-weight: 600;
    }
  }
}
