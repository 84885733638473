/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */
@import '../../modules.scss';

.please-rotate {
  position: fixed;
  top: 0;
  left: $nav-width;
  width: calc(100% - #{$nav-width});
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;

  /* Only show if portrait */
  @media (orientation: landscape) {
    display: none;
  }
  @media only screen and (min-width: 1200px) {
    display: none;
  }

  .__container {
    color: white;

    display: flex;
    flex-direction: column;
    align-items: center;

    & > * + * {
      margin-top: 1rem;
    }
  }
}
