/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

.modal-cropper {
  width: 630px;

  .exit {
    left: calc(640px - 45px);
  }

  & > div {
    width: 640px;

    .rotate {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .dot-button {
        display: inline-block;
        width: 50%;
      }
      margin-top: 15px;
      & > * + * {
        margin-top: 0;
        margin-left: 15px;
      }
    }

    .dot-button.primary {
      margin-top: 15px;
    }
  }
}
