/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

.lightbox {
  position: fixed;
  top: 0;
  left: 0;

  height: vh(100);
  width: vw(100);

  z-index: 4;

  background-color: rgba($colour-grey-1, 0.8);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & > .dot-button {
    position: fixed;
    top: $map-padding;
    right: $map-padding;
    background: transparent;
    border: none;
    color: white;
    font-size: 1.5rem;
  }

  div.main {
    position: relative;
    margin-left: $nav-width;
    height: 80%;
    width: calc(80%);

    display: flex;

    justify-content: center;
    align-items: center;

    img {
      max-height: 80%;
      max-width: calc(80%);
    }

    .meta {
      position: absolute;
      bottom: calc(10% + 10px);

      background-color: black;
      padding: 8px;
      border-radius: 8px;
      color: white;
    }
  }

  div.thumbs {
    margin-left: $nav-width;
    display: flex;
    & > * + * {
      margin-left: 12px;
    }
    justify-content: center;
    align-items: center;
    overflow-x: auto;

    .thumb {
      border-radius: 6px;
      padding: 0;
      cursor: pointer;
      width: 100px;
      height: 100px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: black;
      border: 4px solid $colour-black;

      .censored-text {
        font-size: 0.75rem;
        div {
          text-align: center;
        }
      }

      &.selected {
        border: 4px solid $colour-white;
      }

      img {
        margin: 0;
        max-width: 100%;
        max-height: 100%;
        overflow: hidden;
      }
    }
  }

  div.scroll-left {
    position: absolute;
    top: vh(50, -14px);
    left: calc(#{$nav-width} + 40px);
    .dot-button {
      font-size: 1.75rem;
      color: $colour-white;
    }
  }
  div.scroll-right {
    position: absolute;
    top: vh(50, -14px);
    right: 40px;
    .dot-button {
      font-size: 1.75rem;
      color: $colour-white;
    }
  }

  &.has-sidebar {
    padding-right: var(--sidebar-width);

    div.scroll-right {
      right: calc(var(--sidebar-width) + 40px);
    }
  }
}

.has-notification {
  .lightbox {
    height: vh(100, -$banner-height);
  }
}
