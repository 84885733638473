/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../modules';

.modal-background {
  background-color: rgba(10, 10, 10, 0.86);

  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.modal {
  z-index: 10;
  -webkit-user-select: none;
  user-select: none;

  display: flex;

  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  align-items: center;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  position: fixed;
  z-index: 40;

  .modal-card,
  .modal-content {
    margin: 0 1.5rem;
    max-height: calc(100vh - 160px);
    overflow: auto;
    position: relative;
    width: calc(100% - 10rem);
    .box {
      background-color: $colour-white;
    }
  }
  @media screen and (min-width: $ipad-port-max-width + 1px) {
    .modal-card,
    .modal-content {
      margin: 0 1.5rem;
      max-height: calc(100vh - 40px);
      width: 640px;
    }
  }
  .modal-card {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 40px);
    overflow: hidden;
    -ms-overflow-y: visible;
  }
  .modal-card-foot,
  .modal-card-head {
    align-items: center;
    background-color: #f5f5f5;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: 20px;
    position: relative;
  }
  .modal-card-head {
    border-bottom: 1px solid #dbdbdb;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  .modal-card-title {
    color: #363636;
    flex-grow: 1;
    flex-shrink: 0;
    font-size: 1.5rem;
    line-height: 1;
  }
  .modal-card-foot {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top: 1px solid #dbdbdb;
  }
  .modal-card-foot .button:not(:last-child) {
    margin-right: 0.5em;
  }
  .modal-card-body {
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    padding: 20px;
  }

  .modal-close {
    background: 0 0;
    height: 40px;
    position: fixed;
    right: 20px;
    top: 20px;
    width: 40px;

    -webkit-touch-callout: none;
    -webkit--webkit-user-select: none;
    user-select: none;
    -moz--webkit-user-select: none;
    user-select: none;
    -ms--webkit-user-select: none;
    user-select: none;
    -webkit-user-select: none;
    user-select: none;

    -moz-appearance: none;
    -webkit-appearance: none;
    background-color: rgba(10, 10, 10, 0.2);
    border: none;
    border-radius: 290486px;
    cursor: pointer;
    pointer-events: auto;
    display: inline-block;
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 0;
    height: 20px;
    max-height: 20px;
    max-width: 20px;
    min-height: 20px;
    min-width: 20px;
    outline: 0;
    position: relative;
    vertical-align: top;
    width: 20px;

    &::after {
      background-color: #fff;
      content: '';
      display: block;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%) rotate(45deg);
      transform-origin: center center;
      height: 50%;
      width: 2px;
    }

    &::before {
      height: 2px;
      width: 50%;
    }

    &:focus {
      background-color: rgba(10, 10, 10, 0.3);
    }
    &:active {
      background-color: rgba(10, 10, 10, 0.4);
    }
  }
}

.modal-content {
  overflow: visible;
  position: relative;

  .exit {
    @extend .button-default;
    background-color: transparent;
    margin-left: auto;
    margin-right: 10px;
    width: 10px;

    position: absolute;
    top: 25px;
    right: 25px;
  }

  & > div {
    padding: 60px 30px;
    border-radius: 12px;

    & > div {
      display: flex;
      flex-direction: column;

      & > * + * {
        margin-top: 24px;
      }
    }
  }

  .prompt-modal {
    h2 {
      margin: 0;
      font-size: 1.75rem;
      font-weight: 550;
      text-align: center;
    }
    p {
      font-size: 1rem;
      line-height: 24px;
      text-align: center;
    }
  }

  .confirm-modal {
    justify-content: center;
    align-items: center;

    & > svg {
      align-self: center;
      margin-bottom: 20px;
    }

    h2 {
      font-size: 1.75rem;
      font-weight: 500;
      text-align: center;
      margin-bottom: 0 !important;
      strong {
        font-weight: bold;
      }
    }

    p {
      font-size: 1rem;
      text-align: center;
    }
  }

  .create-form-modal {
    h2 {
      font-size: 1.75rem;
      font-weight: 550;
    }
    hr {
      width: 640px;
      margin-left: -30px;
    }
    .buttons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      .dot-button {
        height: 50px !important;
        width: 150px;
        &:first-of-type {
          margin-right: 20px;
        }
      }
    }
    .form-container {
      border: 1px solid #191e1f;
      border-radius: 10px;
      padding: 20px;
    }
  }
}
