/**
 * © Copyright 2021. This software is protected by copyright, owned by Insitec MIS Pty
 * Ltd.  Except if and to the extent only expressly permitted at law and subject to any
 * licence may have from the copyright owner to use the Software, you must not copy,
 * decompile, reverse engineer, rent, lend, sell, redistribute, sublicense, attempt to
 * derive the source code of or modify the Software, nor create any derivative works of
 * the Software.
 */

@import '../../../modules';

$param-bar-width: 18.75rem;
$top-bar-height: 5rem;

.mission {
  display: grid;
  grid-template-columns: $nav-width vw(100, -#{$nav-width});
  grid-template-rows: vh(100);
  grid-template-areas: 'sidebar create-mission-content';

  width: vw(100);
  height: 100%;

  &-content {
    grid-area: create-mission-content;
    display: flex;
    flex-direction: column;
  }

  .dark-mode {
    .modal-content {
      .box {
        background-color: rgba(11, 23, 26, 0.8);
        color: $colour-white;
      }
      label {
        background-color: rgba(11, 23, 26, 1);
        color: $colour-white;
      }
      .exit {
        color: $colour-white;
      }
      input {
        background-color: transparent;
        color: $colour-white;
        border: 1px solid $colour-grey-5;
        opacity: 0.5;
        &:focus-within {
          opacity: 1;
        }
      }
      input::placeholder {
        color: $colour-white;
        opacity: 0.5;
      }
      textarea {
        background-color: transparent;
        color: $colour-white;
        border: 1px solid $colour-grey-5;
        opacity: 0.5;
        &:focus-within {
          opacity: 1;
        }
      }
      textarea::placeholder {
        color: $colour-white;
        opacity: 0.5;
      }
      .tags {
        color: black;
      }
    }
  }

  .bottom-row {
    position: absolute;
    right: $map-padding;
    bottom: $map-padding;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;

    opacity: 0;
    display: none;

    @extend .fade-animation;

    &.visible {
      display: block;
      opacity: 1;
    }

    & > * + * {
      margin-left: 10px;
    }

    .messages {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 12px 24px 12px 12px;

      & > * + * {
        margin-left: 10px;
      }

      width: 372px;
      height: 72px;

      background: #ffffff;
      box-shadow: 0px 0px 8px rgba($colour-black, 0.22),
        0px 0px 72px rgba(0, 43, 51, 0.4);
      border-radius: 4px;

      font-size: 1rem;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
    }
  }

  .help {
    z-index: 4;
    position: absolute;
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
    user-select: none;
    pointer-events: none;
    @extend .sidebar-animation;
    align-items: center;
    justify-content: center;
    width: 100%;
    right: 0;

    &.bottom {
      bottom: $map-padding * 2;
    }

    span {
      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;

      background: $colour-grey-1;
      opacity: 0.8;
      backdrop-filter: blur(8px);
      border-radius: 32px;

      font-style: normal;
      font-weight: 600;
      font-size: 1rem;
      line-height: 24px;

      text-align: center;
      letter-spacing: -0.02em;

      color: $colour-white;

      padding: 10px 60px;
    }
  }

  .extra-map-controls {
    @extend .sidebar-animation;
    z-index: 4;

    position: absolute;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    width: $toolbar-size;

    border: none;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);

    background-color: $colour-white;

    img:not(.avatar-img) {
      transition: all 0.2s ease-in-out;
    }

    .light-color {
      filter: invert(100%) sepia(0%) saturate(7490%) hue-rotate(288deg)
        brightness(111%) contrast(103%);
    }

    &.right {
      right: $map-padding;
      bottom: calc(var(--toolbar-size) * 3 + 60px + var(--map-padding));
    }

    &.left {
      left: $map-padding;

      &.center {
        bottom: vh(50, 0px - #{$toolbar-size});
      }

      &.top {
        top: vh(50, -110px);
      }

      &.toptop {
        bottom: vh(50, #{$toolbar-size} + #{$toolbar-size} + 18px);
      }

      &.bottom {
        top: vh(50, 6px);
      }
    }

    &.bottom-left {
      flex-direction: column;

      left: $map-padding;
      bottom: calc(var(--toolbar-size) + (var(--map-padding) + 12px));

      justify-content: flex-start;
      align-items: center;

      .dot-button {
        background-color: transparent;
      }

      & > * {
        border-top: solid 1px $colour-grey-4;
        &:first-child {
          border-top: none;
        }
      }

      .integration {
        height: $toolbar-size;
        width: $toolbar-size;

        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }

        display: flex;
        align-items: center;
        justify-content: center;

        .circle {
          border: solid 3px $colour-grey-6;
          height: 36px;
          width: 36px;
          border-radius: 50%;

          display: flex;
          align-items: center;
          justify-content: center;

          .dot-button {
            height: 26px;
            width: 26px;
          }
        }
      }
    }

    &.integration-panel {
      flex-direction: row;

      left: $map-padding;
      bottom: $map-padding;

      justify-content: flex-start;
      align-items: center;
      width: unset;

      .dot-button {
        background-color: transparent;
        border-top: none;
        &:first-of-type {
          border-bottom-left-radius: 8px;
          border-top-left-radius: 8px;
          &.active {
            border-bottom-left-radius: 8px;
          }
        }
        &:last-of-type {
          border-bottom-left-radius: 0px;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
          &.active {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
          }
          &.has-hidden-integrations {
            border-radius: 0px;
          }
        }
      }

      .has-integrations {
        border-right: solid 1px #c7cce6;
        border-top-right-radius: 0px;
      }
    }

    @keyframes search-button-animation-in {
      from {
        opacity: 0;
      }
    }

    @keyframes search-button-animation-out {
      to {
        opacity: 0;
      }
    }

    &.top-left-1 {
      position: unset;
      flex-direction: row;
      width: unset;

      .thread {
        animation: search-button-animation-in 0.2s ease-in-out;
        display: flex;
        flex-direction: column;
        align-items: center;
        opacity: 1;

        &.active {
          background: linear-gradient(
            to top,
            $colour-self,
            $colour-interactions-a
          );
          color: white;
        }

        & > button {
          height: $toolbar-size;
          width: $toolbar-size;
          & > * + * {
            margin-left: 0;
          }

          border: none;
          background-color: transparent;
          &:hover {
            background-color: rgba(0, 0, 0, 0.05);
          }

          .thread-icon {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            flex-shrink: 0;
            background-color: $colour-white;

            border: solid 3px $colour-interactions-a;
            border-radius: 50%;
            height: 2.5rem !important;
            width: 2.5rem !important;
            transition: all 0.2s ease-in-out;

            &.dark-mode {
              background-color: transparent !important;
            }
            & > .dot-avatar {
              height: calc(2.5rem - 10px) !important;
              width: calc(2.5rem - 10px) !important;
              font-size: 1.625rem;

              .avatar {
                height: calc(2.5rem - 10px) !important;
                width: calc(2.5rem - 10px) !important;
                font-size: calc(1.625rem / 2);
              }
            }
          }
        }
      }

      & > button {
        animation: search-button-animation-in 0.2s ease-in-out;
        flex-shrink: 0;
        opacity: 1;
        & > * + * {
          margin-left: 0;
        }
      }

      & > button:first-of-type {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }

    &.top-right-1 {
      animation: search-button-animation-in 0.2s ease-in-out;
      button {
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }

      &.pending-remove {
        animation: search-button-animation-out 0.2s ease-in-out;
      }
      &.hide {
        display: none;
      }

      .dot-button {
        height: $toolbar-size;
        width: $toolbar-size;
        border: none;
        background-color: transparent;
      }

      &.close {
        background-color: transparent;
        border: none;
        box-shadow: none;
      }

      opacity: 1;
      right: $map-padding;
      top: $map-padding;
    }

    &.top-right-2 {
      right: $map-padding;
      top: calc(var(--map-padding) + var(--toolbar-size) + 12px);
      height: unset;
      button {
        &:hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
    }

    @keyframes category-panel-in {
      from {
        opacity: 0;
      }
    }

    @keyframes category-panel-out {
      to {
        opacity: 0;
      }
    }

    &.roles-panel {
      animation: category-panel-in 0.5s linear;
      right: calc(var(--map-padding) + var(--toolbar-size) + 12px);
      top: calc(var(--map-padding) + var(--toolbar-size) + 12px);
      height: unset;
      opacity: 1;
      &.pending-close {
        animation: category-panel-out 0.5s linear;
      }
    }

    &.teams-panel {
      animation: category-panel-in 0.5s linear;
      right: calc(var(--map-padding) + var(--toolbar-size) + 12px);
      top: calc(
        var(--map-padding) + var(--toolbar-size) + var(--toolbar-size) + 12px
      );
      height: unset;
      opacity: 1;
      &.pending-close {
        animation: category-panel-out 0.5s linear;
      }
    }

    &.integrations-panel {
      animation: category-panel-in 0.5s linear;
      left: calc(var(--map-padding) + var(--toolbar-size) + 12px);
      top: vh(67, 6px);
      height: unset;
      opacity: 1;
      &.pending-close {
        animation: category-panel-out 0.5s linear;
      }
    }

    &.draw-panel {
      animation: category-panel-in 0.5s linear;
      left: calc(var(--map-padding) + var(--toolbar-size) + 12px);
      top: vh(50, -110px);
      height: unset;
      opacity: 1;
      &.pending-close {
        animation: category-panel-out 0.5s linear;
      }

      button {
        border-top: solid 1px #c7cce6;
        &:first-of-type {
          border-top: none;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        &:not(:disabled):not(.active):hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
      &.mission {
        top: 5.875rem;
        left: calc(var(--map-padding) + var(--toolbar-size) + 151px);
      }
    }

    &.poi-panel {
      animation: category-panel-in 0.5s linear;
      left: calc(var(--map-padding) + var(--toolbar-size) + 12px);
      top: vh(50, -57px);
      height: unset;
      opacity: 1;
      &.pending-close {
        animation: category-panel-out 0.5s linear;
      }

      button {
        border-top: solid 1px #c7cce6;
        &:first-of-type {
          border-top: none;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        &:not(:disabled):not(.active):hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
      &.mission {
        top: 5.875rem;
        left: calc(var(--map-padding) + var(--toolbar-size) + 151px);
      }
    }

    &.symbol-panel {
      animation: category-panel-in 0.5s linear;
      left: calc(var(--map-padding) + var(--toolbar-size) + 12px);
      top: vh(50, -5px);
      height: unset;
      opacity: 1;
      &.pending-close {
        animation: category-panel-out 0.5s linear;
      }

      button {
        border-top: solid 1px #c7cce6;
        &:first-of-type {
          border-top: none;
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }
        &:not(:disabled):not(.active):hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }
      &.mission {
        top: 5.875rem;
        left: calc(var(--map-padding) + var(--toolbar-size) + 151px);
      }
    }

    &.draw-toolbar {
      animation: category-panel-in 0.5s linear;
      opacity: 1;
      top: 13%;
      left: 50%;
      display: flex;
      flex-direction: row;
      transform: translate(-50%, -50%);
      &.pending-close {
        animation: category-panel-out 0.5s linear;
      }

      button {
        border-right: solid 1px #c7cce6;
        border-top: none;
        border-top-right-radius: unset !important;

        &:last-of-type {
          border-right: none;
        }

        &:not(:disabled):not(.active):hover {
          background-color: rgba(0, 0, 0, 0.05);
        }
      }

      span {
        border-right: solid 1px #c7cce6;
        font-size: 1rem;
        font-weight: 550;
        width: calc(var(--toolbar-size) * 3);
        padding: 16px 12px 12px 12px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    & > button {
      background-color: transparent;
      border: none;
      border-top: solid 1px #c7cce6;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: $toolbar-size;
      height: $toolbar-size;

      &:not(:disabled):not(.active):hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      &:not(:disabled):active {
        background-color: rgba(0, 0, 0, 0.05);
      }

      &:first-of-type {
        border-top: none;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-of-type {
        border-bottom: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &.active {
        background: linear-gradient(
          to top,
          $colour-self,
          $colour-interactions-a
        );
        color: white;
        &:hover {
          opacity: 0.6;
        }
      }
      &.inactive {
        background-color: $colour-grey-5;
        color: $colour-grey-8;
        img {
          opacity: 0.4;
        }
      }
    }

    & > .menu-button {
      &:first-of-type {
        border-top: none;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }

      &:last-of-type {
        border-bottom: none;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }

      &.active {
        background: linear-gradient(
          to top,
          $colour-self,
          $colour-interactions-a
        );
        color: white;
        & > button {
          color: white;
        }
        &:hover {
          opacity: 0.6;
        }
      }
      &.inactive {
        //background-color: $colour-grey-5;
        color: $colour-grey-8;
        & > button {
          color: $colour-grey-8;
        }
        img {
          opacity: 0.4;
        }
      }
    }

    & > .menu-button {
      background-color: transparent;
      border: none;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: $toolbar-size;
      height: $toolbar-size;

      &:not(:disabled):hover {
        background-color: rgba(0, 0, 0, 0.05);
      }
      &:not(:disabled):active {
        background-color: rgba(0, 0, 0, 0.05);
      }

      &:first-child {
        border-top: none;
      }

      .menu {
        left: 73px;
      }
    }
  }
}

.cursor-location {
  position: absolute;
  display: flex;
  flex-direction: row;
  bottom: $map-padding;
  z-index: 4;
  min-height: 20px;
  @extend .sidebar-animation;
  color: white;
  text-shadow: 0px 0px 2px #000, 0px 0.5px 1px #000, 0px -0.5px 1px #000,
    0.5px 0px 1px #000, -0.5px 0px 1px #000;
  justify-content: center;
  width: 100%;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.01em;
  padding-top: 2px;
  right: 0;
}

.zoom-level {
  position: absolute;
  top: calc(var(--map-padding) * 3.5);
  left: calc(var(--map-padding) * 2);
  display: flex;
  flex-direction: row;
  bottom: $map-padding;
  z-index: 4;
  height: 20px;
  @extend .sidebar-animation;
  color: white;
  text-shadow: 0px 0px 2px #000, 0px 0.5px 1px #000, 0px -0.5px 1px #000,
    0.5px 0px 1px #000, -0.5px 0px 1px #000;
  justify-content: center;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.01em;
  padding-top: 2px;
}

.weather-label {
  position: absolute;
  display: flex;
  flex-direction: row;
  right: calc(var(--map-padding) * 6 + var(--toolbar-size) + 250px);
  bottom: $map-padding;
  z-index: 4;
  min-height: 20px;
  @extend .sidebar-animation;
  color: white;
  text-shadow: 0px 0px 2px #000, 0px 0.5px 1px #000, 0px -0.5px 1px #000,
    0.5px 0px 1px #000, -0.5px 0px 1px #000;
  justify-content: flex-start;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  letter-spacing: -0.01em;
  padding-top: 2px;
}

.weather-scale {
  position: absolute;
  display: flex;
  flex-direction: row;
  right: calc(var(--map-padding) * 6 + var(--toolbar-size));
  bottom: $map-padding;
  z-index: 4;
  @extend .sidebar-animation;
  .numbers {
    position: absolute;
    min-width: 230px;
    display: flex;
    justify-content: space-between;
    padding-left: 10px;
    bottom: 0;
    color: white;
    font-weight: bold;
    text-shadow: 0px 0px 2px #000, 0px 0.5px 1px #000, 0px -0.5px 1px #000,
      0.5px 0px 1px #000, -0.5px 0px 1px #000;
    height: 19px;
  }
}

.mission-content-topbar {
  height: $top-bar-height;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid $dark-border;

  &-title {
    flex-grow: 0;
    flex-shrink: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    height: $top-bar-height;
    width: $param-bar-width;
    padding-left: 20px;
    font-size: 2.25rem;
    line-height: 40px;
    color: $colour-white;
    font-weight: 600;
    letter-spacing: -1px;

    border-right: 1px solid $dark-border;

    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;

    .phase {
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 600;
      line-height: 1rem;
      letter-spacing: -0.01em;
      text-align: left;
      text-transform: uppercase;

      & > * + * {
        margin-top: 10px;
      }
    }
  }

  ul {
    flex-grow: 1;
    flex-shrink: 0;
    margin: 0;
    padding: 0;
    display: flex;
    height: 100%;

    li {
      flex-grow: 1;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
      min-width: 200px;
      height: 100%;
      font-size: 1rem;
      font-weight: 550;
      color: $colour-white;
      cursor: pointer;
      border-right: 1px solid $dark-border;

      button {
        background-color: transparent;
        border: none;
        height: 100%;
        width: 100%;
        text-align: left;
        color: inherit;
        padding-left: 20px;
        border-bottom: 4px solid transparent;

        &:disabled {
          opacity: 0.3;
        }
      }

      &.active {
        button {
          border-bottom: 4px solid $colour-interactions-c;
        }
      }
    }
  }

  &-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    padding: 0 20px;

    & > * + * {
      margin-left: 10px;
    }

    &-icon {
      @extend .button-default;
      background-color: transparent;
      color: $colour-grey-3;

      &:hover {
        color: $colour-white;
      }

      &:disabled {
        opacity: 0.3;
      }

      &.trash {
        &:hover {
          color: $colour-negative-status-a;
        }
      }
    }

    &-activate {
      width: 264px;
    }
  }
}

.mission-content-middle {
  height: 100%;
  display: grid;
  grid-template-columns: $param-bar-width calc(
      100vw - #{$param-bar-width} - #{$nav-width}
    );
  grid-template-rows: vh(100, -#{$top-bar-height});
}

.has-notification {
  .mission {
    grid-template-rows: vh(100, -$banner-height);
  }
  .mission-content-middle {
    grid-template-rows: vh(100, -#{$top-bar-height + $banner-height});
  }
}

.mission-content-map {
  position: relative;

  &-top-row {
    position: absolute;
    z-index: 4;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    pointer-events: none;

    & > * + * {
      margin-left: 10px;
    }

    button {
      pointer-events: all;
    }

    &-right {
      @extend .sidebar-animation;
      margin-top: $map-padding;
      margin-right: 240px + 15px + 30px;
    }
  }
}

.bottombar-open {
  .extra-map-controls {
    &.right {
      bottom: calc(
        var(--bottombar-height) + var(--map-padding) + 12px +
          var(--toolbar-size) * 4
      );
    }
    &.bottom-left {
      bottom: calc(
        var(--map-padding) * 2 + var(--toolbar-size) + var(--bottombar-height)
      ) !important;
    }
    &.left {
      top: calc(var(--map-padding) * 2 + var(--toolbar-size)) !important;
    }
    &.integration-panel {
      bottom: calc(var(--bottombar-height) + 30px);
    }
    &.draw-panel {
      top: calc(var(--map-padding) * 2 + var(--toolbar-size)) !important;
    }
    &.poi-panel {
      top: calc(var(--map-padding) * 2 + var(--toolbar-size) * 2) !important;
    }
    &.symbol-panel {
      top: calc(var(--map-padding) * 2 + var(--toolbar-size) * 3) !important;
    }
  }
  .cursor-location {
    bottom: calc(var(--bottombar-height) + 30px);
  }
  .help {
    &.bottom {
      bottom: calc(var(--bottombar-height) + 60px);
    }
  }
  .weather-label {
    bottom: calc(var(--bottombar-height) + 30px);
  }
  .weather-scale {
    bottom: calc(var(--bottombar-height) + 30px);
  }
  .mapboxgl-ctrl-bottom-right {
    bottom: calc(var(--bottombar-height) + 30px);
  }
}

.bottombar-minimized {
  .extra-map-controls {
    &.right {
      bottom: calc(
        var(--bottombar-minimized-height) + var(--map-padding) + 12px +
          var(--toolbar-size) * 4
      );
    }
    &.bottom-left {
      bottom: calc(
        var(--map-padding) * 2 + var(--toolbar-size) +
          var(--bottombar-minimized-height)
      ) !important;
    }
    &.left {
      top: calc(var(--map-padding) * 2 + var(--toolbar-size)) !important;
    }
    &.integration-panel {
      bottom: calc(var(--bottombar-minimized-height) + 30px);
    }
    &.draw-panel {
      top: calc(var(--map-padding) * 2 + var(--toolbar-size)) !important;
    }
    &.poi-panel {
      top: calc(var(--map-padding) * 2 + var(--toolbar-size) * 2) !important;
    }
    &.symbol-panel {
      top: calc(var(--map-padding) * 2 + var(--toolbar-size) * 3) !important;
    }
  }
  .cursor-location {
    bottom: calc(var(--bottombar-minimized-height) + 30px);
  }
  .help {
    &.bottom {
      bottom: calc(var(--bottombar-minimized-height) + 60px) !important;
    }
  }
  .weather-label {
    bottom: calc(var(--bottombar-minimized-height) + 30px);
  }
  .weather-scale {
    bottom: calc(var(--bottombar-minimized-height) + 30px);
  }
  .mapboxgl-ctrl-bottom-right {
    bottom: calc(var(--bottombar-minimized-height) + 30px);
  }
}

.sidebar-open {
  .mission {
    .mission-content-map {
      &-top-row {
        &-right {
          margin-right: calc(var(--sidebar-width) + 285px);
        }
      }
    }

    .extra-map-controls {
      &.right {
        right: calc(var(--sidebar-width) + 30px);
      }
      &.top-right-1 {
        right: calc(var(--sidebar-width) + 30px);
      }
      &.top-right-2 {
        right: calc(var(--sidebar-width) + 30px);
      }
      &.roles-panel {
        right: calc(
          var(--sidebar-width) + var(--toolbar-size) + var(--map-padding) + 30px
        );
      }
      &.teams-panel {
        right: calc(
          var(--sidebar-width) + var(--toolbar-size) + var(--map-padding) + 30px
        );
      }
      &.draw-toolbar {
        left: calc(50% - (var(--sidebar-width) / 2));
      }
    }
  }
}

.half-screen-sidebar-open {
  .mission {
    .mission-content-map {
      &-top-row {
        &-right {
          margin-right: calc(var(--half-screen-sidebar-width) + 285px);
        }
      }
    }

    .extra-map-controls {
      &.right {
        right: calc(var(--half-screen-sidebar-width) + 30px);
      }
      &.top-right-1 {
        right: calc(var(--half-screen-sidebar-width) + 30px);
      }
      &.top-right-2 {
        right: calc(var(--half-screen-sidebar-width) + 30px);
      }
      &.roles-panel {
        right: calc(
          var(--half-screen-sidebar-width) + var(--toolbar-size) +
            var(--map-padding) + 30px
        );
      }
      &.teams-panel {
        right: calc(
          var(--half-screen-sidebar-width) + var(--toolbar-size) +
            var(--map-padding) + 30px
        );
      }
      &.draw-toolbar {
        left: calc(50% - (var(--half-screen-sidebar-width) / 2));
      }
    }
  }
}

.dark-mode {
  .extra-map-controls {
    img:not(.avatar-img) {
      transition: all 0.2s ease-in-out;
      filter: invert(100%) sepia(0%) saturate(7490%) hue-rotate(288deg)
        brightness(111%) contrast(103%);
    }
    background: rgba(11, 23, 26, 0.8);
    color: $colour-white;

    & > button {
      border-top: solid 1px rgba($colour-white, 0.2);
      color: $colour-white;
    }
    & > .menu-button {
      border-top: solid 1px rgba($colour-white, 0.2);
      button {
        color: $colour-white;
      }
    }

    &.bottom-left {
      & > * {
        border-top: solid 1px rgba($colour-white, 0.2);
        &:first-child {
          border-top: none;
        }
      }
    }

    &.top-right-1 {
      .dot-button {
        color: $colour-white;
      }
    }

    &.top-right-2 {
      .category-panel {
        background: rgba(11, 23, 26, 0.8);
        color: $colour-white;
        button {
          color: $colour-white;
        }

        .ally {
          button {
            background-color: rgba(11, 23, 26, 0.8);
          }
        }
      }

      .allies {
        .ally {
          button {
            color: $colour-white;
          }
          &:first-of-type {
            border-bottom: solid 1px rgba($colour-white, 0.2);
            border-radius: 8px 8px 0 0;
          }

          &:last-of-type {
            border-radius: 0 0 8px 8px;
          }

          &.active {
            background: linear-gradient(
              to top,
              $colour-self,
              $colour-interactions-a
            );
            color: $colour-white;
            &:hover {
              opacity: 0.6;
            }
          }
        }
      }
    }
  }

  .pop-out-sidebar {
    background-color: #191e1f;
    color: white;
    .text,
    button,
    .exit,
    h5 {
      color: white;
    }

    .heading {
      .status {
        .title {
          span {
            color: white;
          }
        }
      }
    }

    .top.personnel-info {
      div {
        div {
          color: white;
          span {
            color: white;
          }
        }
      }
    }

    h3 {
      color: white;
    }

    .blocks {
      h4,
      h5 {
        color: white;
      }
      .item-container {
        h5 {
          color: white;
        }
        .text {
          color: white;
          span {
            color: white;
          }
        }
      }
      .tag {
        background-color: #191e1f !important;
      }
      span {
        color: white;
      }
    }

    div.search {
      border: 1px solid $colour-grey-2;
      input {
        color: white;

        &::placeholder {
          color: $colour-grey-3;
          font-weight: 550;
        }
      }
    }

    .broadcast {
      .header {
        background-color: transparent;
        .top {
          color: white;
        }
      }
    }

    .tabs {
      button {
        background: transparent;
      }
    }

    .chat {
      .date {
        color: white;
      }
      .messages {
        background: transparent;
        .bubble {
          color: $colour-grey-1;
        }
      }
    }

    .content {
      h4 {
        color: white;
      }
      .controls {
        .dot-input,
        .text-area,
        .dot-select {
          label {
            color: white;
            background-color: #191e1f;
          }
          textarea,
          input,
          select {
            color: white;
            background-color: #191e1f;
            border: 1px solid white;
            opacity: 0.5;
            &:hover {
              opacity: 1;
            }
          }
        }
        .photo {
          color: #191e1f;
        }
      }
      .list {
        .list-item {
          background-color: #191e1f;
          .text {
            color: white;
          }
        }
      }
      .integrations-help {
        border: dashed 1px white !important;
      }
    }

    .form {
      > .header {
        background-color: #191e1f;
      }
      .form-content {
        .panel {
          .panel-header {
            background-color: #191e1f;
            .panel-tip {
              color: white;
            }
          }
          .details {
            .from {
              background: black;
            }
            .search-results {
              div {
                background: #191e1f;
              }
            }
            .personnel-tag {
              background: #191e1f;
              border: 1px solid white;
              button {
                background: #191e1f;
                color: white;
              }
            }
            input {
              background: #191e1f;
              color: white;
            }
            input:disabled {
              background: black;
            }
            textarea {
              background: #191e1f;
              color: white;
            }
            textarea:disabled {
              background: black;
            }
            .required {
              font-size: 10px;
              font-weight: 600;
              letter-spacing: 0.5px;
            }
            .tip {
              color: white;
            }
            .add-button {
              button {
                background: #191e1f;
              }
            }
            .remove-button {
              button {
                background: #191e1f;
              }
            }
          }
        }
      }
      .footer {
        .button-panel {
          background-color: #191e1f;
        }
      }
    }

    .loading-panel {
      background-color: #191e1f !important;
    }
  }
}
